import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React, { FunctionComponent } from "react";
import { Rings } from "react-loader-spinner";

export const Loaders: FunctionComponent<{
  classname?: string;
  style?: any;
}> = ({ classname, style }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.loader} ${classname}`} style={style}>
      <Rings color="#7026FF" height={100} width={100} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    position: "absolute",
    [theme.breakpoints.up("sm")]: {
      left: "47%",
      top: "42%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "37%",
      top: "37%",
    },
  },
}));
