// dev environment urls
export const devEnvironmentUrls = {
  apiUrl: "https://dev-apis.ubiquitousinfluence.app/graphql",
  xApiKey: "da2-ywcbjv5onngb5iascgbr54mwv4",
};

// staging environment urls
export const stagingEnvironmentUrls = {
  apiUrl: "https://staging-apis.ubiquitousinfluence.app/graphql",
  xApiKey: "da2-cufqxbi445hsvaxobmoppza4im",
};

// prod environment urls
export const prodEnvironmentUrls = {
  apiUrl: "https://apis.ubiquitousinfluence.app/graphql",
  xApiKey: "da2-nq3allrv55cg5nbdusruol5x3y",
};

export const Environment = {
  DEV: "DEV",
  STAGING: "STAGING",
  PROD: "PROD",
};
