import React from "react";
import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

interface Props {
  title: string;
  checked: boolean;
  onChange: any;
}

const styles = (theme: any) => {
  return {
    checkBox: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.MuiCheckbox-colorPrimary": {
        color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      },
    },
    formControlLabel: {
      marginBottom: 0,
      "& .MuiTypography-root": {
        fontFamily:
          "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
        fontSize: "1.23rem",
      },
    },
  };
};

const CheckboxWithTitle = ({ title, checked, onChange }: Props) => {
  const sx = styles(useTheme());

  return (
    <FormControlLabel
      sx={sx.formControlLabel}
      control={
        <Checkbox
          disableRipple
          sx={sx.checkBox}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          name="checked"
          color="primary"
        />
      }
      label={title}
    />
  );
};

export default CheckboxWithTitle;
