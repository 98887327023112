import React, { FunctionComponent } from "react";
import { ChartCardProps } from "@modules/Organisms/Charts/charts.types";

import BarChartCardPresentational from "./barChart.presentational";

const BarGraphChart: FunctionComponent<ChartCardProps> = ({
  classname,
  customstyle,
  data,
  xkey,
  xLabel,
  ykey,
  yLabel,
  secondYKey,
  secondYLabel,
  header,
  subHeader,
  dropdownOption,
  selectedOption,
  setDropdownOptions,
  filterKey,
}) => {
  return (
    <BarChartCardPresentational
      data={data}
      customstyle={customstyle}
      classname={classname}
      xkey={xkey}
      xLabel={xLabel}
      ykey={ykey}
      yLabel={yLabel}
      secondYKey={secondYKey}
      secondYLabel={secondYLabel}
      header={header}
      subHeader={subHeader}
      dropdownOption={dropdownOption}
      selectedOption={selectedOption}
      setDropdownOptions={setDropdownOptions}
      filterKey={filterKey}
    />
  );
};

export default BarGraphChart;
