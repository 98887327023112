import { FunctionComponent } from "react";
import React from "react";
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Avatar,
  Typography,
  useTheme,
} from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonPill } from "@modules/Atoms/Buttons";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { BrandData, HeadRowType } from "@Models/Brands";
import GenrePopover from "@modules/GenrePopover/GenrePopover";
import tiktok from "@assets/tiktok.png";
import Pagination from "@modules/Pagination/Pagination";
import RowHeader from "@modules/Organisms/Header/RowHeader";
import AutoCompleteCategory from "@modules/BrandTable/AutoCompleteCategory";
import { ROUTES } from "@src/constants";
import { AutoSuggestCreator } from "@modules/AutoSuggestBox/AutoSuggestCreator";
import user from "@assets/user.png";
import { categories } from "@modules/BrandTable/data";
import PaginationDropDownPresentational from "@modules/Organisms/PaginationDropDown/PaginationDropDown.Presentational";

export interface BrandDataTablePresentationalProps {
  tableData: BrandData[];
  brands: BrandData[];
  headRows: HeadRowType[];
  handleRequestSort3: (property: any) => void;
  order: "asc" | "desc" | undefined;
  orderBy: string;
  count: number;
  filterPageCount: number;
  page: number;
  setPage: any;
  pageFilter: number;
  setPageFilter: any;
  correctedCategories: Array<string>;
  selectCorrectedCategory: (_id: string, value: any) => void;

  value: string;
  setValue: (...args: any) => void;
  suggestions: Array<any>;
  setSuggestions: (...args: any) => void;
  loading?: boolean;
  array?: any;
  fetchSuggestion: (...args: any) => void;
  onSuggestionSelected: (...args: any) => void;
  onEnter: (...args: any) => void;
  placeholder?: string;
  open: boolean;
  tableLoader?: boolean;
  position: any;
  setLoaderPosition: any;
  tableRef: any;
  setClear: React.Dispatch<React.SetStateAction<Boolean>>;
  setPageRecord: React.Dispatch<React.SetStateAction<number>>;
  startRecordNumber: number;
  endRecordNumber: number;
  totalPage: number;
  csvDownload: () => void;
  totalFilterRecord: number;
  handleChange: (event: any, newValue: any) => void;
  options: number[];
  pageDropDown: number;
  setPageDropDown: React.Dispatch<React.SetStateAction<number>>;
  onKeyDown: (ev: any) => void;
  onOptionSelect: boolean;
}

const BrandDataTablePresentational: FunctionComponent<
  BrandDataTablePresentationalProps
> = ({
  tableData,
  brands,
  headRows,
  order,
  orderBy,
  handleRequestSort3,
  count,
  page,
  setPage,
  correctedCategories,
  selectCorrectedCategory,
  suggestions,
  setSuggestions,
  onSuggestionSelected,
  setValue,
  loading,
  array,
  value,
  fetchSuggestion,
  onEnter,
  placeholder,
  open,
  tableLoader,
  position,
  setLoaderPosition,
  filterPageCount,
  pageFilter,
  setPageFilter,
  tableRef,
  setClear,
  setPageRecord,
  startRecordNumber,
  endRecordNumber,
  totalPage,
  csvDownload,
  totalFilterRecord,
  handleChange,
  options,
  pageDropDown,
  setPageDropDown,
  onKeyDown,
  onOptionSelect,
}) => {
  const sx = styles(useTheme());
  const theme: Theme = useTheme();

  return (
    <Box sx={sx.parent} ref={tableRef}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Box mr={1}>
          <ButtonPill onClick={csvDownload}>Download</ButtonPill>
        </Box>
        <Box mr={1} style={{ marginLeft: "auto" }}>
          <AutoSuggestCreator
            value={value}
            setValue={setValue}
            loading={loading}
            fetchSuggestion={fetchSuggestion}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            onSuggestionSelected={onSuggestionSelected}
            onEnter={onEnter}
            placeholder={placeholder}
            setClear={setClear}
            onOptionSelect={onOptionSelect}
          />
        </Box>
        <AutoCompleteCategory
          customStyle={{ width: "30%" }}
          icon={<SearchIcon />}
          placeholder="Search by categories"
          correctedCategory={correctedCategories}
          id={"1"}
          onAdd={selectCorrectedCategory}
          options={categories}
        />
      </Box>
      <Box
        mt={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box sx={sx.boxStyle}>
          <Typography sx={sx.typography}>
            {brands.length === 0 ||
            loading ||
            !brands ||
            !tableData ||
            !totalFilterRecord
              ? "Total Brands: 0"
              : `Total Brands: ${value === "" ? totalPage : totalFilterRecord}`}
          </Typography>
          <Typography sx={sx.typography}>
            {brands.length === 0 ||
            loading ||
            !brands ||
            !tableData ||
            !totalFilterRecord
              ? "0-0 of 0 items"
              : `${startRecordNumber} - ${endRecordNumber} of ${
                  value === "" ? totalPage : totalFilterRecord
                } items`}
          </Typography>
        </Box>
      </Box>
      <>
        <Paper sx={sx.paper} elevation={1}>
          <Box sx={sx.tableWrapper}>
            {tableLoader || !brands || !tableData ? (
              <Backdrop
                sx={sx.backdropStyle}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "100vw",
                }}
                open={open}
              >
                <CircularProgress
                  color="inherit"
                  sx={sx.svgStyle}
                  style={position}
                />
              </Backdrop>
            ) : (
              ""
            )}
            <Box sx={sx.tableWrapper2}>
              <Table sx={sx.table}>
                <RowHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort3}
                  headRows={headRows}
                />
                <TableBody>
                  {brands.length === 0 && tableLoader === false ? (
                    <StyledTableRow>
                      <StyledTableBodyCell>
                        <Box maxWidth="400px" width="56vw">
                          Couldn't find any brand
                        </Box>
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  ) : (
                    brands.map((row: BrandData, i: number) => (
                      <StyledTableRow key={i}>
                        <StyledTableBodyCell
                          sx={sx.stickyColumn}
                          style={{
                            backgroundColor:
                              i % 2
                                ? getColorByTheme(
                                    theme,
                                    allColors.light3,
                                    allColors.dark2
                                  )
                                : getColorByTheme(
                                    theme,
                                    allColors.light1,
                                    allColors.dark3
                                  ),
                          }}
                        >
                          <Toolbar style={{ paddingLeft: "0" }}>
                            <Avatar
                              src={
                                row.tiktokProfileImage
                                  ? row.tiktokProfileImage.split("?")[0]
                                  : user
                              }
                              sx={sx.avtar}
                            />
                            <Link
                              sx={sx.colorHyperLink}
                              href={`${ROUTES.BRAND_DATA}/${row.tiktokUserName}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>{row.tiktokUserName}</span>
                            </Link>
                            <Link
                              href={`${row.tiktokProfileLink}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                <Avatar src={tiktok} sx={sx.link} />
                              </span>
                            </Link>
                          </Toolbar>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row.totalViews}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row.totalPosts}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <Link
                                sx={sx.colorHyperLink}
                                href={`${ROUTES.SPONSORED_CREATORS}/${row.creators[0]}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span>
                                  {row.creators ? row.creators[0] : ""}
                                </span>
                              </Link>
                            </Box>
                            {row.creators &&
                              row.creators[1] &&
                              row.creators[1]?.length !== 0 && (
                                <GenrePopover
                                  items={row.creators}
                                  url={ROUTES.SPONSORED_CREATORS}
                                />
                              )}
                          </Box>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              {row.correctedCategories &&
                              row.correctedCategories.length > 0
                                ? row.correctedCategories[0]
                                : ""}
                            </Box>
                            {row.correctedCategories &&
                              row.correctedCategories[1] &&
                              row.correctedCategories[1]?.length !== 0 && (
                                <GenrePopover items={row.correctedCategories} />
                              )}
                          </Box>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row.followers}
                        </StyledTableBodyCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

        <Box mt={6} display="flex" justifyContent="center" width="100%">
          <Pagination
            count={
              value === ""
                ? (count = 0 ? 1 : count)
                : filterPageCount === 0
                ? 1
                : filterPageCount
            }
            page={value === "" ? page : pageFilter}
            onChange={(e: any, page: number) => {
              if (value === "") {
                setPage(page);
                setPageRecord(page);
                setLoaderPosition(true);
                setPageDropDown(page);
              } else {
                setPageFilter(page);
                setPageRecord(page);
                setPageDropDown(page);
              }
            }}
          />
          <PaginationDropDownPresentational
            handleChange={handleChange}
            options={options}
            pageDropDown={pageDropDown}
            onKeyDown={onKeyDown}
          />
        </Box>
      </>
    </Box>
  );
};

export default BrandDataTablePresentational;

const styles = (theme: any) => {
  return {
    backdropStyle: {
      color: "#7026FF",
      backgroundColor: "rgb(240,230,0,0.5)",
      position: "absolute",
      backgroundPosition: "right top",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      backdropFilter: "blur(2px)",
    },
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 800,
    },
    typography: {
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      fontSize: "1.23rem",
    },
    boxStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      width: "30%",
      backgroundColor: "transparent",
    },
    svgStyle: {
      position: "absolute",
    },
    parent: { paddingTop: "3rem" },
    avtar: {
      marginRight: theme.spacing(2),
      width: "50px",
      height: "50px",
    },
    link: {
      marginLeft: theme.spacing(1),
      width: "25px",
      height: "25px",
    },
    paper: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      borderRadius: "21px",
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      width: "100%",
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: "auto",
      // It's 180 degree rotated that's why it's paddingTop instead of bottom
      paddingTop: theme.spacing(6),
      transform: "rotatex(180deg)",

      /* height */
      "&::-webkit-scrollbar": {
        height: "7px",
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        background: getColorByTheme(theme, allColors.light3, allColors.dark6),
        borderRadius: "50px",
        margin: theme.spacing(0, 2),
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: getColorByTheme(theme, allColors.light4, allColors.dark7),
        borderRadius: "50px",
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: getColorByTheme(theme, allColors.light5, allColors.dark8),
      },
    },
    tableWrapper2: {
      transform: "rotatex(180deg)",
      minHeight: "5vh",
    },
    colorHyperLink: {
      textDecoration: "none",
      color: "inherit",
      fontWeight: 700,
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
      "&:hover": {
        color: "inherit",
      },
      "&:hover::after": {
        width: "100%",
      },
    },
    tableSortLabel: {
      "& .MuiTableSortLabel-icon": {
        position: "absolute",
        left: "-30px",
      },
    },
  };
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  "&:nth-of-type(even)": {
    backgroundColor: getColorByTheme(theme, allColors.light3, allColors.dark2),
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: getColorByTheme(theme, allColors.dark1, allColors.light1),
  fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
  fontSize: "1.23rem",
  fontWeight: "400",
  padding: theme.spacing("18px", 3),
  whiteSpace: "nowrap",
  "&:first-of-type": {
    paddingLeft: theme.spacing(3),
  },
  "&:last-child": {
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up("sm")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(6),
    },
    "&:last-child": {
      paddingRight: theme.spacing(6),
    },
  },
  [theme.breakpoints.up("md")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(8),
    },
    "&:last-child": {
      paddingRight: theme.spacing(8),
    },
  },
}));
