import React, { FunctionComponent, useEffect, useState } from "react";
import { useTheme, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import "@pages/media.css";
import IndividualBrandChartPresentational from "./IndividualBrandChart.presentational";
import { GET_INDIVIDUAL_BRANDS_CHART_DATA } from "@src/graphql/queries/getBrandsList";
import { Loaders } from "@modules/Common/Loader/Loader";

const IndividualBrandChart: FunctionComponent = () => {
  const theme: Theme = useTheme();
  const classes = useStyles();
  const [brandChartData, setBrandChartData] = useState([]);
  const [brandChartTotal, setBrandChartTotal] = useState({
    totalPostAcross: 0,
    totalSpentAcross: 0,
    totalViewsAcross: 0,
    totalCreators: 0,
  });

  const params: any = useParams();
  const [getBrandsChart, { data: data1, loading, error }] = useLazyQuery(
    GET_INDIVIDUAL_BRANDS_CHART_DATA
  );

  useEffect(() => {
    getBrandsChart({
      variables: {
        tiktokusername: params.brand,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data1 && data1.getIndividualBrandsChart) {
      setBrandChartData(data1.getIndividualBrandsChart.brandChart);
      setBrandChartTotal(data1.getIndividualBrandsChart.brandHeaderData);
    }
  }, [data1, loading]);

  if (!brandChartData || loading === true) {
    return (
      <div id="load" className={classes.loader}>
        <Loaders />
      </div>
    );
  }

  return (
    <Box color={getColorByTheme(theme, allColors.dark1, allColors.light1)}>
      <IndividualBrandChartPresentational
        chartData={brandChartData}
        chartLoading={loading}
        chartTotal={brandChartTotal}
        chartError={error}
      />
    </Box>
  );
};

export default IndividualBrandChart;

export const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    position: "absolute",
    top: "49%",
    left: "47%",
  },
}));
