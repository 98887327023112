import { gql } from "@apollo/client";

const GET_ACCOUNT_TABLE_DATA = gql`
  query onGetAccountsList($input: AccountInput!) {
    getAccountsList(input: $input) {
      account {
        _id
        tiktokUserId
        tiktokUserName
        tiktokProfileLink
        bioLink
        brandCategory
        followers
        isBrand
        isVerified
        creators
        taggedPosts
        totalViews
        correctedCategories
        createdAt
        updatedAt
      }
      total
    }
  }
`;

const SEARCH_ACCOUNT_TABLE_DATA = gql`
  query onGetAccountsList($input: AccountInput!) {
    getAccountsList(input: $input) {
      account {
        _id
        tiktokUserId
        tiktokUserName
        tiktokProfileLink
        bioLink
        brandCategory
        followers
        isBrand
        isVerified
        creators
        taggedPosts
        totalViews
        correctedCategories
        createdAt
        updatedAt
      }
      total
    }
  }
`;

const UPDATE_ACCOUNT_DATA = gql`
  mutation onUpdateAccount(
    $id: String
    $isBrand: Boolean
    $correctedCategories: [String]
  ) {
    updateAccount(
      id: $id
      isBrand: $isBrand
      correctedCategories: $correctedCategories
    ) {
      success
      message
    }
  }
`;

export {
  GET_ACCOUNT_TABLE_DATA,
  SEARCH_ACCOUNT_TABLE_DATA,
  UPDATE_ACCOUNT_DATA,
};
