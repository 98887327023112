import { gql } from "@apollo/client";

const GET_CREATOR_GENRE = gql`
  query onGetCreatorsDetails($creatorId: String) {
    getCreatorsDetails(creatorId: $creatorId) {
      creatorEnteredGenre
      newGenre
      newGenreList {
        id
        genre
      }
    }
  }
`;

const UPDATE_CREATOR_DATA = gql`
  mutation onUpdateGenre($creatorId: String, $genreList: [genreList]) {
    updateGenre(creatorId: $creatorId, genreList: $genreList) {
      success
      message
    }
  }
`;

export { GET_CREATOR_GENRE, UPDATE_CREATOR_DATA };
