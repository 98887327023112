import React, { FunctionComponent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import "@pages/media.css";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { GET_CATEGORY_CHART } from "@src/graphql/queries/getCategories";
import CategoryChartPresentational from "./CategoriesChart.presentational";
import {
  categoryDataArray,
  CategoryFilter,
} from "@modules/Brands/Categories/chart/CategoriesChart.types";
import { categories } from "@modules/BrandTable/data";
import { monthFilter } from "@src/constants";

const CategoriesChart: FunctionComponent = () => {
  const theme: Theme = useTheme();
  const [CategoryChartData, setCategoryChartData] = useState<categoryDataArray>(
    {
      monthlySpentByCategory: [],
      totalViewsByCategory: [],
      totalSpentByMonth: [],
      totalSonseredPostByMonth: [],
      totalCreatorByCategory: [],
      totalBrandsByCategory: [],
    }
  );

  const [filter, setFilters] = useState<CategoryFilter>({
    filterByMonthlySpentByCategory: "All",
    filterByTotalViewsByCategory: "All",
    filterByTotalSpentByMonth: "All",
    filterByTotalSonseredPostByMonth: "All",
    filterByTotalCreatorByCategory: "",
  });
  const [getCategories, { data, loading, error }] =
    useLazyQuery(GET_CATEGORY_CHART);
  const modifiedCategory = categories.map((cat) => {
    return {
      title: cat,
      value: cat,
    };
  });
  modifiedCategory.unshift({ title: "All", value: "All" });
  useEffect(() => {
    getCategories({
      variables: {
        input: {
          filterByMonthlySpentByCategory: filter.filterByMonthlySpentByCategory,
          filterByTotalViewsByCategory: filter.filterByTotalViewsByCategory,
          filterByTotalSpentByMonth: filter.filterByTotalSpentByMonth,
          filterByTotalSonseredPostByMonth:
            filter.filterByTotalSonseredPostByMonth,
          filterByTotalCreatorByCategory: filter.filterByTotalCreatorByCategory,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (data && data?.getCategoriesChart) {
      setCategoryChartData(data?.getCategoriesChart);
    }
  }, [data, loading]);
  const filterSelection = (key: string, value: string) => {
    setFilters({
      ...filter,
      [key]: value,
    });
  };

  return (
    <Box color={getColorByTheme(theme, allColors.dark1, allColors.light1)}>
      <CategoryChartPresentational
        chartData={CategoryChartData}
        chartLoading={loading}
        chartError={error}
        filters={filter}
        setFilters={filterSelection}
        categories={modifiedCategory}
        monthsfilter={monthFilter()}
      />
    </Box>
  );
};

export default CategoriesChart;
