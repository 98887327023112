import React from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

const styles = (theme: any) => {
  return {
    tableSortLabel: {
      "& .MuiTableSortLabel-icon": {
        position: "absolute",
        left: "-30px",
      },
    },
    stickyColumn: {
      left: 0,
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      zIndex: 800,
    },
  };
};

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: getColorByTheme(
    theme,
    allColors.dark1 + "80",
    allColors.light1 + "80"
  ),
  fontSize: ".92rem",
  fontWeight: "700",
  lineHeight: "130%",
  letterSpacing: ".1em",
  padding: theme.spacing(3),
  position: "relative",
  textTransform: "uppercase",
  "&:first-of-type": {
    paddingLeft: theme.spacing(3),
    "&::after": {
      left: "30px",
      width: "calc(100% - 30px)",
    },
  },
  "&:last-child": {
    paddingRight: theme.spacing(3),
    "&::after": {
      width: "calc(100% - 30px)",
    },
  },
  "&::after": {
    content: "''",
    width: "100%",
    height: "1px",
    background: getColorByTheme(theme, allColors.dark1, allColors.light1),
    opacity: ".1",
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  [theme.breakpoints.up("sm")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(6),
      "&::after": {
        left: "60px !important",
        width: "calc(100% - 60px) !important",
      },
    },
    "&:last-child": {
      paddingRight: theme.spacing(6),
      "&::after": {
        width: "calc(100% - 60px) !important",
      },
    },
  },
  [theme.breakpoints.up("md")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(8),
      "&::after": {
        left: "80px !important",
        width: "calc(100% - 80px) !important",
      },
    },
    "&:last-child": {
      paddingRight: theme.spacing(8),
      "&::after": {
        width: "calc(100% - 80px) !important",
      },
    },
  },
}));

interface HeadRowType {
  id: string;
  label: string;
  link?: string;
  dontSort?: boolean;
  urlPrefix?: string;
}

interface Props {
  order: "asc" | "desc" | undefined;
  orderBy: string;
  onRequestSort: (p: any) => void;
  headRows: HeadRowType[];
}

const RowHeader = ({ order, orderBy, onRequestSort, headRows }: Props) => {
  const sx = styles(useTheme());
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) =>
          row.dontSort ? (
            <StyledTableHeadCell key={row["id"]}>
              <Box
                minWidth={
                  row.label.length < 13
                    ? row.label.length * 10
                    : row.label.length * 4
                }
              >
                {row.label}
              </Box>
            </StyledTableHeadCell>
          ) : (
            <StyledTableHeadCell
              key={row["id"]}
              sortDirection={orderBy === row["id"] ? order : false}
              style={{ position: "sticky" }}
              sx={
                row["id"] === "tiktokUsernameStickyColumn" ||
                row["id"] === "createdTimeStickyColumn" ||
                row["id"] === "categoryNameStickyColumn"
                  ? sx.stickyColumn
                  : {}
              }
            >
              <TableSortLabel
                active={orderBy === row["id"]}
                direction={order}
                onClick={createSortHandler(row["id"])}
                sx={sx.tableSortLabel}
              >
                <Box
                  minWidth={
                    row.label.length < 13
                      ? row.label.length * 10
                      : row.label.length * 4
                  }
                >
                  {row.label}
                </Box>
              </TableSortLabel>
            </StyledTableHeadCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default RowHeader;
