import React from "react";
import { FunctionComponent } from "react";
import { useMutation } from "@apollo/client";
import { Container, Box, Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import { STRIPE_URL_CONNECT } from "@src/graphql/queries/stripe";
import ShyFace from "@assets/ShyFace.png";
import Comment from "@assets/Comment.png";
import allColors from "@modules/Colors/Colors";
import { ButtonPill } from "@src/app/modules/Atoms/Buttons";

interface StripeOnboardingFailedPresentationalProps {
  message: string;
  id: string;
  onClick?: any;
}

export const StripeOnboardingFailedPresentational: FunctionComponent<
  StripeOnboardingFailedPresentationalProps
> = ({ message, id, onClick }) => {
  const sx = styles(useTheme());
  const classes = useStyles();
  const [getStripeUrl] = useMutation(STRIPE_URL_CONNECT);
  const handleOnClick = async () => {
    const stripeOnboardingToken = localStorage.getItem("stripeAccessToken");
    await getStripeUrl({
      variables: {
        token: stripeOnboardingToken,
      },
    }).then((res: any) => {
      if (res?.data?.stripeOnboard?.success) {
        const url = res?.data?.stripeOnboard?.url;
        window.open(url, "_blank");
      }
    });
  };

  const redirect = () => {
    window.location.href = "mailto:payments@ubiquitousinfluence.com";
  };

  return (
    <div className={classes.divStyle}>
      <Container className={classes.containerStyle}>
        <Paper sx={sx.paper} elevation={1}>
          <span className={classes.spanStyle}>
            <Typography sx={sx.heading}>Uh oh!</Typography>
            <img
              src={ShyFace}
              alt="Shy Face"
              width="25%"
              height="25%"
              className={classes.imgStyle}
            />
          </span>
          <p className={classes.textStyle}>
            {message} <br />
            Please contact us to find out what to do next.
          </p>
          {id === "StripeOnboardingFailed" && (
            <Box>
              <ButtonPill sx={sx.tryButton} onClick={handleOnClick}>
                <span className={classes.buttonText}>Try Again</span>
              </ButtonPill>
            </Box>
          )}
          <span className={classes.spanStyle}>
            <h3 onClick={redirect} className={classes.contactTextStyle}>
              Contact us
            </h3>
            <img
              src={Comment}
              alt="Comment"
              width="16%"
              height="16%"
              className={classes.commentImgStyle}
            />
          </span>
        </Paper>
      </Container>
    </div>
  );
};

const styles = (theme: any) => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justiftContent: "center",
      minHeight: {
        xs: "7vh",
        md: "23vh",
      },
      margin: "0 auto",
      padding: "2rem",
      width: {
        xs: "80%",
        md: "45%",
      },
      position: "relative",
      fontFamily: "Plus Jakarta Sans",
      borderRadius: { xs: "10px", md: "30px" },
    },
    heading: {
      color: allColors.dark1,
      fontStyle: "strong",
      fontSize: "32px",
      fontWeight: "550",
      textAlign: "center",
      marginTop: "5%",
      display: "inline-block",
      lineHeight: "130%",
      textShadow: "0.5px 0 currentColor",
    },
    tryButton: {
      backgroundColor: allColors.purple,
      textTransform: "capitalize",
      borderRadius: "10px",
      boxShadow: "none",
      color: allColors.dark3,
      padding: "1rem",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      margin: "5px",
      marginTop: "5%",
      marginBottom: "5%",
      width: {
        xs: "55vw",
        sm: "40vw",
        md: "20vw",
      },
      "&:hover": {
        backgroundColor: allColors.purple1,
      },
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  textStyle: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "150%",
    color: allColors.dark12,
  },
  buttonText: {
    color: allColors.light1,
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "150%",
    textShadow: "0.5px 0 currentColor",
  },
  divStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },
  containerStyle: { flexGrow: 1 },
  contactTextStyle: {
    color: allColors.purple,
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "150%",
    marginTop: "15%",
    marginBottom: "15%",
    display: "inline-block",
    textShadow: "0.5px 0 currentColor",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  commentImgStyle: {
    display: "inline-block",
    marginBottom: "15%",
    marginTop: "10%",
    marginLeft: "5%",
  },
  spanStyle: { whiteSpace: "nowrap", maxHeight: "70px" },
  imgStyle: {
    display: "inline-block",
    marginBottom: "12%",
  },
}));
