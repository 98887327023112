import React, { useEffect, useState } from "react";
import { Box, Hidden, useTheme, Paper, Typography } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { AutoSuggestBox } from "@modules/AutoSuggestBox/AutoSuggestBox";
import { Loaders } from "@modules/Common/Loader/Loader";
import { CreatorLineChart } from "@modules/CreatorLineChart/CreatorLineChart";
import { RootState } from "@src/redux/rootReducer";
import { GET_CREATOR_CHART_DATA } from "@src/graphql/queries/getCreatorList";

export const CreatorsChart = ({ creatorId }: { creatorId: string | null }) => {
  const sx = styles(useTheme());

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const creatorsData = useSelector(
    (state: RootState) => state.creators.creatorChartData
  );
  const selectedCreator = useSelector(
    (state: RootState) => state.creators.selectedCreator
  );
  const avgCounts = useSelector(
    (state: RootState) => state.creators.creatorAvgCounts
  );

  const [getChartPoints, { data: chartPoints }] = useLazyQuery(
    GET_CREATOR_CHART_DATA
  );

  useEffect(() => {
    dispatch({ type: "SELECT_CREATOR", payload: creatorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedCreator && selectedCreator !== "") {
      setLoading(true);
      getChartPoints({
        variables: {
          creatorId: selectedCreator,
        },
      });
    } else if (selectedCreator === "") {
      dispatch({
        type: "GET_CREATOR_CHART_DATA_RECORD",
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreator]);

  useEffect(() => {
    const data = chartPoints?.getCreatorsChartData;
    if (data !== undefined) {
      const avgCounts = {
        avgViewsCount: data.avgViewsCount,
        avgCommentsCount: data.avgCommentsCount,
        avgLikesCount: data.avgLikesCount,
        avgSharesCount: data.avgSharesCount,
        avgEngagementRateCount: !!data.avgEngagementRateCount
          ? data.avgEngagementRateCount
          : 0,
      };
      dispatch({
        type: "CREATOR_AVG_COUNTS",
        payload: avgCounts,
      });
      dispatch({
        type: "GET_CREATOR_CHART_DATA_RECORD",
        payload: data.post.length > 0 ? data.post : [],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartPoints]);

  useEffect(() => {
    setLoading(false);
  }, [creatorsData]);

  const theme = useTheme();

  return (
    <Box
      position="relative"
      sx={sx.bg_Colors}
      color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
    >
      <Paper sx={sx.paper} elevation={1}>
        <Hidden>
          <Box
            sx={sx.search}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box mr={4}>
              <AutoSuggestBox creatorId={creatorId} />
            </Box>
            {selectedCreator && avgCounts && (
              <Box>
                <Box display="flex" alignItems="center">
                  <Box mr="45px">
                    <Stat
                      title="Avg view count"
                      avgStats={avgCounts?.avgViewsCount}
                    />
                  </Box>
                  <Box mr="45px">
                    <Stat
                      title="Avg like count"
                      avgStats={avgCounts?.avgLikesCount}
                    />
                  </Box>
                  <Box mr="45px">
                    <Stat
                      title="Avg comment count"
                      avgStats={avgCounts?.avgCommentsCount}
                    />
                  </Box>
                  <Stat
                    title="Avg share count"
                    avgStats={avgCounts?.avgSharesCount}
                  />
                  <Box mr="45px">
                    <Stat
                      title="Avg Engagement Rate count"
                      avgStats={avgCounts?.avgEngagementRateCount}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Hidden>
        {loading ? (
          <div>
            <Loaders />
          </div>
        ) : (
          <CreatorLineChart
            creatorsData={creatorsData}
            selectedCreator={selectedCreator}
          />
        )}
      </Paper>
    </Box>
  );
};

const Stat = ({
  title,
  avgStats,
}: {
  title: string;
  avgStats: string | number;
}) => {
  return (
    <Box>
      <Typography
        variant="h2"
        component="span"
        display="block"
        style={{ color: "#3F4254" }}
      >
        {title}
      </Typography>
      <Typography variant="subtitle1" component="span">
        {avgStats.toLocaleString()}
      </Typography>
    </Box>
  );
};

const styles = (theme: any) => {
  return {
    bg_Colors: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark2
      ),
    },
    search: {
      marginBottom: "35px",
      marginTop: "20px",
    },
    paper: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark2
      ),
      minHeight: "500px",
      borderRadius: "2px",
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      width: "100%",
      position: "relative",
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: "auto",
      // It's 180 degree rotated that's why it's paddingTop instead of bottom
      paddingTop: theme.spacing(6),
      transform: "rotatex(180deg)",

      /* height */
      "&::-webkit-scrollbar": {
        height: "7px",
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        background: getColorByTheme(theme, allColors.light3, allColors.dark6),
        borderRadius: "50px",
        margin: theme.spacing(0, 2),
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: getColorByTheme(theme, allColors.light4, allColors.dark7),
        borderRadius: "50px",
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: getColorByTheme(theme, allColors.light5, allColors.dark8),
      },
    },
    tableWrapper2: {
      transform: "rotatex(180deg)",
      minHeight: "60vh",
    },
    colorHyperLink: {
      color: "inherit",
      fontWeight: 700,
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
      "&:hover": {
        color: "inherit",
      },
      "&:hover::after": {
        width: "100%",
      },
    },
    tableSortLabel: {
      "& .MuiTableSortLabel-icon": {
        position: "absolute",
        left: "-30px",
      },
    },
    userName: {
      color: "rgba(164, 148, 205, 0.6)",
      fontFamily: "Poppins",
      fontSize: "12px",
      lineHeight: "18px",
    },
    searchBoxWrap: {
      marginTop: "40px",
    },
  };
};
