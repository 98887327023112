import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { ErrorPage3 } from "@modules/ErrorsExamples/ErrorPage3";
import EllipsePinkTop from "@assets/Ellipse-pink-top.svg";
import EllipsePurpleTop from "@assets/Ellipse-purple-top.svg";
import EllipsePinkBottom from "@assets/Ellipse-pink-bottom.svg";
import EllipsePurpleBottom from "@assets/Ellipse-purple-bottom.svg";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";
import { CreatorsChartPage } from "@pages/CreatorsChartPage";
import { Header } from "@modules/Header/Header";
import { ROUTES, displaySidebar } from "@src/constants";
import { GenreSelection } from "@modules/GenreSelection/GenreSelection";
import { BrandsManagementPage } from "@pages/BrandManagement/BrandsManagementPage";
import LoginPage from "@pages/Login";
import { BrandPage } from "@pages/Brands/BrandPage";
import { SponsoredCreatorPage } from "@pages/SponsoredCreators/SponsoredCreatorPage";
import "@pages/media.css";
import { IndividualCreatorPage } from "@pages/IndividualCreator/IndividualCreatorPage";
import { IndividualBrandPage } from "@pages/IndividualBrand/IndividualBrandPage";
import { CategoryPage } from "@pages/Categories/CategoryPage";
import { StripeOnboardingSuccess } from "@pages/StripeOnboard/StripeOnboardingSuccess";
import { StripeOnboardingFailed } from "./pages/StripeOnboard/StripeOnboardingFailed";
import StripeRedirect from "./pages/StripeOnboard/StripeRedirect";
import Logo from "./modules/Logo/Logo";
import { MobileTos } from "@pages/Mobile-TOS/MobileTos";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: getColorByTheme(theme, allColors.light2, allColors.dark1),
  overflowY: "scroll",
  transition: ".4s ease-in-out",
  [theme.breakpoints.up("md")]: {
    /* width */
    "&::-webkit-scrollbar": {
      width: "auto",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: getColorByTheme(theme, allColors.light3, allColors.dark6),
      borderRadius: "50px",
      margin: theme.spacing(0, 2),
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: getColorByTheme(theme, allColors.light4, allColors.dark7),
      borderRadius: "50px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: getColorByTheme(theme, allColors.light5, allColors.dark8),
    },
  },
}));

const MobileStyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: allColors.dark1,
  overflowY: "scroll",
  transition: ".4s ease-in-out",
  [theme.breakpoints.up("md")]: {
    /* width */
    "&::-webkit-scrollbar": {
      width: "auto",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      background: allColors.dark6,
      borderRadius: "50px",
      margin: theme.spacing(0, 2),
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: allColors.dark7,
      borderRadius: "50px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: allColors.dark8,
    },
  },
}));

export function RoutesMain() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated: any = localStorage.getItem("accessToken");
  const stripeURLs = [
    ROUTES.STRIPE_ONBOARD_REDIRECT,
    ROUTES.STRIPE_SUCCESS,
    ROUTES.STRIPE_FAILED,
  ];

  const MobileURLs = [ROUTES.MOBILE_TOS];
  useEffect(() => {
    if (location?.pathname !== ROUTES.LOGIN) {
      dispatch({
        type: "INITIAL_PATH",
        payload: location?.pathname,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!MobileURLs.includes(location.pathname) ? (
        <StyledBox>
          <Box pt="47px" pb="56px" position="relative" minHeight="100vh">
            {!stripeURLs.includes(location.pathname) && (
              <>
                <Box position="absolute" top="0px" left="0">
                  <img src={EllipsePinkTop} alt="Ellipse pink" />
                </Box>
                <Box position="absolute" top="0px" left="0">
                  <img src={EllipsePurpleTop} alt="Ellipse purple" />
                </Box>
                <Box position="absolute" bottom="0px" right="0">
                  <img src={EllipsePinkBottom} alt="Ellipse pink" />
                </Box>
                <Box position="absolute" bottom="0px" right="0">
                  <img src={EllipsePurpleBottom} alt="Ellipse purple" />
                </Box>
              </>
            )}

            <>
              {!displaySidebar(location.pathname) &&
                !stripeURLs.includes(location.pathname) && (
                  <Container id="Container">
                    <Header />
                  </Container>
                )}

              {!displaySidebar(location.pathname) &&
                stripeURLs.includes(location.pathname) && (
                  <Container
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Logo />
                  </Container>
                )}

              <Routes>
                <Route
                  path="/"
                  element={checkAuth(
                    <Navigate to={ROUTES.BRAND_DATA} />,
                    isAuthenticated
                  )}
                />
                <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                <Route
                  path={ROUTES.CHART_SCREEN}
                  element={<CreatorsChartPage />}
                />
                <Route
                  path={ROUTES.GENRE_SCREEN}
                  element={<GenreSelection />}
                />
                <Route
                  path={ROUTES.STRIPE_ONBOARD_REDIRECT}
                  element={<StripeRedirect />}
                />
                <Route
                  path={ROUTES.STRIPE_SUCCESS}
                  element={<StripeOnboardingSuccess />}
                />
                <Route
                  path={ROUTES.STRIPE_FAILED}
                  element={<StripeOnboardingFailed />}
                />
                <Route
                  path={ROUTES.BRAND_LIST}
                  element={checkAuth(<BrandsManagementPage />, isAuthenticated)}
                />
                {displaySidebar(location.pathname) && (
                  <>
                    <Route
                      path={ROUTES.SPONSORED_CREATORS}
                      element={checkAuth(
                        <SponsoredCreatorPage />,
                        isAuthenticated
                      )}
                    />
                    <Route
                      path={ROUTES.BRAND_DATA}
                      element={checkAuth(<BrandPage />, isAuthenticated)}
                    />
                    <Route
                      path={ROUTES.CATEGORIES}
                      element={checkAuth(<CategoryPage />, isAuthenticated)}
                    />
                  </>
                )}
                <Route
                  path={ROUTES.CREATOR_DETAILS}
                  element={checkAuth(
                    <IndividualCreatorPage />,
                    isAuthenticated
                  )}
                />
                {location?.pathname !== "/brands/management" && (
                  <Route
                    path={ROUTES.BRAND_DETAILS}
                    element={checkAuth(
                      <IndividualBrandPage />,
                      isAuthenticated
                    )}
                  />
                )}
                <Route path="/*" element={<ErrorPage3 />} />
              </Routes>
            </>
          </Box>
        </StyledBox>
      ) : (
        <MobileStyledBox>
          <Box
            pt="47px"
            pb="56px"
            position="relative"
            minHeight="100vh"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Routes>
              <Route path={ROUTES.MOBILE_TOS} element={<MobileTos />} />
            </Routes>
          </Box>
        </MobileStyledBox>
      )}
    </>
  );
}

const checkAuth = (component: JSX.Element, auth: boolean) => {
  return auth ? component : <Navigate to={ROUTES.LOGIN} />;
};
