import {
  Box,
  Button,
  Popover,
  Typography,
  Link,
  useTheme,
} from "@mui/material";
import React from "react";
import { ROUTES } from "@src/constants";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

const styles = (theme: any) => {
  return {
    colorHyperLink: {
      color: "inherit",
      fontWeight: 700,
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
      "&:hover": {
        color: "inherit",
      },
      "&:hover::after": {
        width: "100%",
      },
    },
    button: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light2,
        allColors.dark10
      ),
      borderRadius: "50%",
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      fontFamily: "Arial, Helvetica, sans- serif",
      minWidth: 0,
      padding: 0,
      lineHeight: 1,
      width: 40,
      height: 40,
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light4,
          allColors.dark7
        ),
      },
      "& .btn-inner": {
        display: "flex",
        alignItems: "center",
        gap: "2px",
        "& .number": {
          fontSize: "1.1rem",
        },
      },
    },
    plusSign: {
      fontSize: "1.2rem",
    },
    popOver: {
      "& .MuiPopover-paper": {
        background: getColorByTheme(theme, "auto", allColors.dark3),
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        maxWidth: "250px",
      },
      "& .popover-box": {
        gap: "10px",
      },
    },
  };
};

const GenrePopover = ({ items, url }: any) => {
  const sx = styles(useTheme());
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const modifiedItems = items?.filter((_: string, i: number) => i > 0);
  return (
    <div>
      <Button
        disabled={items === undefined || items.length === 0}
        sx={sx.button}
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <Box className="btn-inner" component="span">
          <Box sx={sx.plusSign} component="span">
            +
          </Box>
          <Box className="number" component="span">
            {items[0] === "" ? "" : items?.length - 1 ?? 0}
          </Box>
        </Box>
      </Button>
      <Popover
        sx={sx.popOver}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexWrap="wrap" className="popover-box">
          {modifiedItems?.map((item: any, i: number) =>
            url === ROUTES.BRAND_DATA || url === ROUTES.SPONSORED_CREATORS ? (
              <Typography key={i} component="span">
                <Link
                  sx={sx.colorHyperLink}
                  href={`${url}/${item}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item}
                </Link>
                {modifiedItems.length - 1 === i ? "" : ","}
              </Typography>
            ) : (
              <Typography key={i} component="span">
                {item}
                {modifiedItems.length - 1 === i ? "" : ","}
              </Typography>
            )
          )}
        </Box>
      </Popover>
    </div>
  );
};

export default GenrePopover;
