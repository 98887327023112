import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getColorByTheme, getSorting } from "@modules/utils/utils";
import Pagination from "@modules/Pagination/Pagination";
import allColors from "@modules/Colors/Colors";
import BrandTableHead from "./BrandTableHead";
import BrandFilter from "@modules/Filter/BrandFilter";
import { monthNames, totalBrandPageCount } from "@src/constants";
import AutoCompleteCategory from "./AutoCompleteCategory";
import { AutoSuggestCreator } from "@modules/AutoSuggestBox/AutoSuggestCreator";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ACCOUNT_TABLE_DATA,
  SEARCH_ACCOUNT_TABLE_DATA,
  UPDATE_ACCOUNT_DATA,
} from "@src/graphql/queries/getAccountList";
import { Alert } from "react-bootstrap";
import { categories } from "@modules/BrandTable/data";
import PaginationDropDownPresentational from "@modules/Organisms/PaginationDropDown/PaginationDropDown.Presentational";
const headRows = [
  {
    id: "accountUsernameStickyColumn",
    label: "Account Username",
    link: "tiktokProfileLink",
    urlPrefix: "https://www.tiktok.com/",
  },
  { id: "isBrand", label: "is Brand", dontSort: true },
  { id: "category", label: "Tiktok Category", dontSort: true },
  { id: "correctedCategories", label: "Corrected Categories", dontSort: true },
  { id: "createdate", label: "Date Added" },
  { id: "updatedate", label: "Last Updated Date" },
];

const BrandTable = () => {
  const sx = styles(useTheme());
  const [brands, setBrands] = useState<any[]>([]);
  const [order, setOrder] = useState<"asc" | "desc" | undefined>("asc");
  const [orderBy, setOrderBy] = useState("");
  const [totalP, setTotalP] = useState(1);
  const [spage, setSPage] = useState(1);
  const [ePage, setEPage] = useState(1);
  const [pageFilter, setPageFilter] = useState<number>(1);
  const [filterPageCount, setFilterPageCount] = useState<number>(1);
  const [open, setOpen] = React.useState<boolean>(false);
  const [updateTableData, setupdateTableData] = useState<any[]>([]);
  const [suggestionValue, setSuggestionValue] = useState<string>("");
  const [onenter, setOnEnter] = useState<boolean>(false);
  const [clear, setClear] = useState<Boolean>(false);
  const [data, setData] = useState<any>([]);
  const [onOptionSelect, setOnOptionSelect] = useState(false);
  const [onSelect, setOnSelect] = useState(false);
  const [loaderPosition, setLoaderPosition] = useState<boolean>(false);
  const [pageRecord, setPageRecord] = useState<number>(1);
  const [pageDropDown, setPageDropDown] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [showMessage, setShowMessage] = useState("");
  const [filterValues, setFilterValues] = useState({
    accountNeverUpdated: false,
    accountWithoutCorrectedCategories: false,
    brandAccounts: false,
    nonBrandedAccounts: false,
    markedAsbrand: false,
  });
  const [page, setPage] = useState(1);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT_DATA);
  const [getAccountsList, { data: accountsData, loading }] = useLazyQuery(
    GET_ACCOUNT_TABLE_DATA
  );
  const [getSearchedBrandsList, { data: brandsList, loading: filterLoading }] =
    useLazyQuery(SEARCH_ACCOUNT_TABLE_DATA);

  const fetchBrand = (value?: any) => {
    const filterAccountNeverUpdated =
      filterValues.accountNeverUpdated === true ? 1 : null;
    const accountWithoutCorrectedCategories =
      filterValues.accountWithoutCorrectedCategories === true ? 1 : null;
    const filterOnlyBrandAccount =
      filterValues.brandAccounts === true ? 1 : null;
    const filterOnlyNonBrandAccount =
      filterValues.nonBrandedAccounts === true ? 1 : null;
    const markedAsbrand = filterValues.markedAsbrand === true ? 1 : null;

    getAccountsList({
      variables: {
        input: {
          pageNumber: page,
          tiktokUsername: value ? value : suggestionValue,
          sortByUserName: getSorting(
            "accountUsernameStickyColumn",
            order,
            orderBy
          ),
          sortByDateAdded: getSorting("createdate", order, orderBy),
          sortByLastUpdatedDate: getSorting("updatedate", order, orderBy),
          filterAccountNeverUpdated,
          filterAccountWithoutCorrectedCategories:
            accountWithoutCorrectedCategories,
          filterOnlyBrandAccount,
          filterOnlyNonBrandAccount,
          markedAsbrand,
        },
      },
    });
  };

  const fetchQuery = (value?: any) => {
    const filterAccountNeverUpdated =
      filterValues.accountNeverUpdated === true ? 1 : null;
    const accountWithoutCorrectedCategories =
      filterValues.accountWithoutCorrectedCategories === true ? 1 : null;
    const filterOnlyBrandAccount =
      filterValues.brandAccounts === true ? 1 : null;
    const filterOnlyNonBrandAccount =
      filterValues.nonBrandedAccounts === true ? 1 : null;
    const markedAsbrand = filterValues.markedAsbrand === true ? 1 : null;

    getSearchedBrandsList({
      variables: {
        input: {
          pageNumber: pageFilter,
          tiktokUsername: value ? value : suggestionValue,
          sortByUserName: getSorting(
            "accountUsernameStickyColumn",
            order,
            orderBy
          ),
          sortByDateAdded: getSorting("createdate", order, orderBy),
          sortByLastUpdatedDate: getSorting("updatedate", order, orderBy),
          filterAccountNeverUpdated,
          filterAccountWithoutCorrectedCategories:
            accountWithoutCorrectedCategories,
          filterOnlyBrandAccount,
          filterOnlyNonBrandAccount,
          markedAsbrand,
        },
      },
    });
  };

  useEffect(() => {
    if (suggestionValue === "") {
      fetchBrand();
      setClear(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, filterValues, clear]);

  useEffect(() => {
    if (suggestionValue !== "") {
      fetchQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, filterValues, pageFilter]);

  useEffect(() => {
    const searchAccountUsername: any = brandsList?.getAccountsList?.account;
    if (searchAccountUsername) {
      setData(searchAccountUsername);
      if (onenter) {
        setBrands(searchAccountUsername);
      }
      setupdateTableData(searchAccountUsername);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsList, pageFilter]);

  const onSuggestionSelected = (event: any, { suggestion }: any) => {
    if (event?.keyCode !== 13) {
      setOnEnter(true);
      setOnSelect(true);
      setOnOptionSelect(true);
      setPageFilter(1);
      setBrands([suggestion]);
      setTotalP([suggestion].length);
      setSPage(1);
      setEPage(1);
    }
  };

  useEffect(() => {
    setOnSelect(false);
    if (brands?.length === 1 && suggestionValue === brands[0]?.tiktokUserName) {
      setOnOptionSelect(true);
    } else {
      setOnOptionSelect(false);
    }
  }, [brands, suggestionValue]);

  useEffect(() => {
    if (suggestionValue === "") {
      setOnEnter(false);
      setPageFilter(1);
      if (accountsData && accountsData.getAccountsList) {
        setBrands(accountsData.getAccountsList.account);
      }
    }
    setPageFilter(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionValue]);

  const OnEnterList = (code: any) => {
    if (code?.keyCode === 13) {
      setOnSelect(true);
      setOnOptionSelect(true);
      setOnEnter(true);
      setBrands(updateTableData);
    }
  };

  const updateAccountMutation = (
    id: string,
    isBrand: boolean,
    categorylist: string[]
  ) => {
    updateAccount({
      variables: {
        id,
        isBrand,
        correctedCategories: categorylist,
      },
    }).then((res: any) => {
      if (res?.data?.updateAccount?.success) {
        setShowMessage("Account Updated Successfully!");
        fetchBrand();
      } else {
        setShowMessage("Something went wrong!");
      }
      setTimeout(() => {
        setShowMessage("");
      }, 3000);
    });
  };

  const handleRequestSort3 = (property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const isBrandToggle = async (_id: string) => {
    const findBrand = brands.find((br) => br._id === _id);
    const brand = brands.map((br) => {
      if (br._id === _id) {
        return {
          ...br,
          isBrand: !br.isBrand,
        };
      }
      return br;
    });
    setBrands(brand);
    if (findBrand.isBrand !== undefined) {
      await updateAccountMutation(
        _id,
        !findBrand.isBrand,
        findBrand.correctedCategories
      );
    }
  };

  const selecteCorrectedCategory = async (_id: string, value: any) => {
    const findBrand = brands.find((br) => br._id === _id);
    const brand = brands.map((br) => {
      if (br._id === _id) {
        return {
          ...br,
          correctedCategories: value,
        };
      }
      return br;
    });
    setBrands(brand);
    if (!!value) {
      await updateAccountMutation(_id, findBrand.isBrand, value);
    }
  };

  useEffect(() => {
    if (!brands || !accountsData) {
      setOpen(true);
    }
  }, [brands, accountsData]);

  useEffect(() => {
    setPage(1);
    setPageRecord(1);
    setPageDropDown(1);
  }, [
    filterValues.accountNeverUpdated,
    filterValues.accountWithoutCorrectedCategories,
    filterValues.brandAccounts,
    filterValues.nonBrandedAccounts,
    filterValues.markedAsbrand,
    order,
    orderBy,
    onenter,
    suggestionValue,
  ]);

  useEffect(() => {
    if (accountsData && accountsData.getAccountsList) {
      setBrands(accountsData.getAccountsList.account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsData]);

  useEffect(() => {
    setLoaderPosition(false);
  }, [
    filterValues.accountNeverUpdated,
    filterValues.accountWithoutCorrectedCategories,
    filterValues.brandAccounts,
    filterValues.nonBrandedAccounts,
    filterValues.markedAsbrand,
    order,
    orderBy,
    onenter,
    suggestionValue,
  ]);

  useEffect(() => {
    if (
      accountsData?.getAccountsList &&
      count !== accountsData?.getAccountsList?.total
    ) {
      setCount(
        brands
          ? Math.ceil(
              accountsData?.getAccountsList?.total / totalBrandPageCount
            )
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsData]);

  useEffect(() => {
    if (
      brandsList?.getAccountsList &&
      filterPageCount !== brandsList?.getAccountsList?.total
    ) {
      setFilterPageCount(
        brandsList?.getAccountsList
          ? Math.ceil(brandsList?.getAccountsList?.total / totalBrandPageCount)
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsList]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPageDropDown(parseInt(newValue));
      setLoaderPosition(true);
      setPageRecord(parseInt(newValue));
    }
  };

  let options = [];

  useEffect(() => {
    if (suggestionValue === "" && pageDropDown !== null) {
      setPage(pageDropDown);
    }
    if (suggestionValue !== "" && pageDropDown !== null) {
      setPageFilter(pageDropDown);
    }
  }, [pageDropDown, suggestionValue]);

  useEffect(() => {
    let endNumber = pageRecord * totalBrandPageCount;
    let startRecordNumber = endNumber - (totalBrandPageCount - 1);
    let total = 1;
    if (suggestionValue === "" && accountsData?.getAccountsList?.total) {
      total = accountsData?.getAccountsList?.total;
    } else if (suggestionValue !== "" && brandsList?.getAccountsList?.total) {
      total = brandsList?.getAccountsList?.total;
    }

    if (!onSelect) {
      setTotalP(total);
      setSPage(endNumber - (totalBrandPageCount - 1));
      setEPage(
        endNumber > total
          ? startRecordNumber + (total - startRecordNumber)
          : endNumber
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, accountsData, brandsList, pageRecord, suggestionValue]);

  for (
    let pageValue = 1;
    pageValue <=
    (suggestionValue === ""
      ? count === 0
        ? 1
        : count
      : filterPageCount === 0
      ? 1
      : filterPageCount);
    pageValue++
  ) {
    options.push(pageValue);
  }

  const onKeyDown = (ev: any) => {
    if (
      ev.key === "Enter" &&
      ev.target.value <= options.length &&
      ev.target.value > 0
    ) {
      if (ev.target.value && suggestionValue === "" && pageDropDown !== null) {
        setPage(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
      if (ev.target.value && suggestionValue !== "" && pageDropDown !== null) {
        setPageFilter(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
    }
  };

  const position: any = loaderPosition ? { top: "3%" } : { bottom: "3%" };

  return (
    <>
      {showMessage !== "" && (
        <Box sx={sx.alertBox}>
          <Alert variant="dark" style={{ width: "fit-content" }}>
            {showMessage}
          </Alert>
        </Box>
      )}
      <HeadSection
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        mx={1}
        mt={4}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="left"
        ></Box>
        <Box mr={1} sx={sx.headerBoxStyle}>
          <AutoSuggestCreator
            value={suggestionValue}
            setValue={setSuggestionValue}
            loading={filterLoading}
            fetchSuggestion={fetchQuery}
            suggestions={data}
            setSuggestions={setData}
            array={brandsList}
            onSuggestionSelected={onSuggestionSelected}
            onEnter={OnEnterList}
            placeholder={"Enter Account Username"}
            setClear={setClear}
            onOptionSelect={onOptionSelect}
          />
        </Box>
        <Box display="flex" className="right" mr={1}>
          <BrandFilter
            filterValues={filterValues}
            handleChange={setFilterValues}
          />
        </Box>
      </HeadSection>

      <Paper sx={sx.paper} elevation={1}>
        <Box sx={sx.tableWrapper}>
          {loading || !brands || !accountsData || filterLoading ? (
            <Backdrop sx={sx.backdropStyle} open={open}>
              <CircularProgress
                color="inherit"
                sx={sx.svgStyle}
                style={position}
              />
            </Backdrop>
          ) : (
            ""
          )}
          <Box sx={sx.tableWrapper2}>
            {brands?.length === 0 && loading === false && open === false ? (
              <Box ml={2}>
                <Typography variant="h5" component="span">
                  {"No Brand matches the applied filters"}
                </Typography>
              </Box>
            ) : (
              <Table sx={sx.table}>
                <BrandTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort3}
                  headRowsFiltered={headRows}
                />
                <TableBody>
                  {brands.length === 0 &&
                  loading === false &&
                  open === false ? (
                    <StyledTableRow>
                      <StyledTableBodyCell>
                        <Box maxWidth="400px" width="56vw">
                          Couldn't find any Account with matching filter
                        </Box>
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  ) : (
                    brands.map((row, i) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableBodyCell sx={sx.stickyColumn}>
                          <Link
                            sx={sx.colorHyperLink}
                            href={`https://www.tiktok.com/@${row.tiktokUserName}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.tiktokUserName}
                          </Link>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Checkbox
                            disableRipple
                            sx={sx.checkBox}
                            checked={row.isBrand}
                            onChange={(e) => isBrandToggle(row._id)}
                            name="checked"
                            color="primary"
                          />
                          <span>{row.isBrand}</span>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row.brandCategory}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Box>
                            <AutoCompleteCategory
                              correctedCategory={row.correctedCategories}
                              id={row._id}
                              onAdd={selecteCorrectedCategory}
                              options={categories}
                            />
                          </Box>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {!!row.createdAt
                            ? `${
                                monthNames[new Date(row.createdAt).getMonth()]
                              }-${
                                new Date(row.createdAt).getDate() < 10
                                  ? `0${new Date(row.createdAt).getDate()}`
                                  : new Date(row.createdAt).getDate()
                              }-${new Date(row.createdAt).getFullYear()}`
                            : ""}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {!!row.updatedAt
                            ? `${
                                monthNames[new Date(row.updatedAt).getMonth()]
                              }-${
                                new Date(row.updatedAt).getDate() < 10
                                  ? `0${new Date(row.updatedAt).getDate()}`
                                  : new Date(row.updatedAt).getDate()
                              }-${new Date(row.updatedAt).getFullYear()}`
                            : ""}
                        </StyledTableBodyCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </Box>
      </Paper>
      <Box mt={6} display="flex" justifyContent="center" width="100%">
        <Box sx={sx.paginationBoxStyle}>
          <Pagination
            count={
              suggestionValue === ""
                ? count === 0
                  ? 1
                  : count
                : filterPageCount === 0
                ? 1
                : filterPageCount
            }
            page={suggestionValue === "" ? page : pageFilter}
            onChange={(e: any, page: number) => {
              if (suggestionValue === "") {
                setPage(page);
                setPageRecord(page);
                setLoaderPosition(true);
                setPageDropDown(page);
              } else {
                setPageFilter(page);
                setPageRecord(page);
                setPageDropDown(page);
              }
            }}
          />
          <PaginationDropDownPresentational
            handleChange={handleChange}
            options={options}
            pageDropDown={pageDropDown}
            onKeyDown={onKeyDown}
          />
        </Box>
        <Box sx={sx.boxStyle}>
          {brands.length === 0 || loading || !brands || !totalP
            ? "0-0 of 0 items"
            : `${spage} - ${ePage} of ${
                suggestionValue === ""
                  ? accountsData?.getAccountsList?.total
                  : totalP
              } items`}
        </Box>
      </Box>
    </>
  );
};

export default BrandTable;

const styles = (theme: any) => {
  return {
    backdropStyle: {
      color: "#7026FF",
      backgroundColor: "rgb(240,230,0,0.5)",
      position: "absolute",
      backgroundPosition: "right top",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      backdropFilter: "blur(2px)",
      display: "flex",
      alignItems: "flex-end",
    },
    stickyColumn: {
      position: "sticky",
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark3
      ),
      left: 0,
      zIndex: 800,
    },
    boxStyle: {
      marginLeft: "auto",
      alignSelf: "center",
      fontFamily: "SF Pro Text",
      fontSize: "1rem",
      fontWeight: 700,
      display: "flex",
      width: "15%",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    paginationBoxStyle: {
      marginLeft: "auto",
      display: "flex",
      justifyContent: "center",
    },
    svgStyle: {
      position: "absolute",
    },
    headerBoxStyle: { marginRight: "auto" },
    loader: {
      position: "absolute",
      top: "49%",
      left: "47%",
    },
    paper: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      borderRadius: "21px",
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      width: "100%",
    },
    table: {
      minWidth: 500,
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark3
      ),
    },
    tableWrapper: {
      overflowX: "auto",
      overflowY: "hidden",
      // It's 180 degree rotated that's why it's paddingTop instead of bottom
      paddingTop: theme.spacing(6),
      transform: "rotatex(180deg)",

      /* height */
      "&::-webkit-scrollbar": {
        height: "7px",
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        background: getColorByTheme(theme, allColors.light3, allColors.dark6),
        borderRadius: "50px",
        margin: theme.spacing(0, 2),
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: getColorByTheme(theme, allColors.light4, allColors.dark7),
        borderRadius: "50px",
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: getColorByTheme(theme, allColors.light5, allColors.dark8),
      },
    },
    tableWrapper2: {
      transform: "rotatex(180deg)",
      minHeight: "5vh",
    },
    colorHyperLink: {
      textDecoration: "none",
      color: "inherit",
      fontWeight: 700,
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
      "&:hover": {
        color: "inherit",
      },
      "&:hover::after": {
        width: "100%",
      },
    },
    tableSortLabel: {
      "& .MuiTableSortLabel-icon": {
        position: "absolute",
        left: "-30px",
      },
    },
    checkBox: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.MuiCheckbox-colorPrimary": {
        color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      },
    },
    save: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark11
      ),
      borderRadius: "100px",
      boxShadow: getColorByTheme(theme, theme.shadows[1], "none"),
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "10px 25px 6px 25px",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      margin: "5px",
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light1,
          allColors.dark4
        ),
      },
    },
    chip: {
      background: getColorByTheme(theme, allColors.light2, allColors.dark10),
      fontSize: ".9rem",
      cursor: "pointer",
      margin: 2,
    },
    input: {
      zIndex: 1,
      top: 0,
      border: "none",
      borderRadius: "8px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: getColorByTheme(theme, allColors.light1, allColors.dark11),
      padding: "14px",
      paddingRight: "21px",
      paddingLeft: "20px",
      width: "320px",
    },
    alertBox: {
      position: "fixed",
      bottom: 50,
      right: "5%",
      zIndex: 100,
    },
  };
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  borderBottom: `1px solid ${getColorByTheme(
    theme,
    "rgba(0, 0, 0, 0.10)",
    "rgba(255, 255, 255, 0.1)"
  )}`,
  "&:nth-of-type(even)": {
    backgroundColor: getColorByTheme(theme, allColors.light1, allColors.dark3),
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: getColorByTheme(theme, allColors.dark1, allColors.light1),
  fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
  fontSize: "1.23rem",
  fontWeight: "400",
  padding: theme.spacing("18px", 1),
  whiteSpace: "nowrap",
  "&:first-of-type": {
    paddingLeft: theme.spacing(3),
  },
  "&:last-child": {
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up("sm")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(3),
    },
    "&:last-child": {
      paddingRight: theme.spacing(3),
    },
  },
  [theme.breakpoints.up("md")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(3),
    },
    "&:last-child": {
      paddingRight: theme.spacing(3),
    },
  },
}));

const HeadSection = styled(Box)(({ theme }) => ({
  gap: theme.spacing(2),
  "& .left": {
    width: "100%",
  },
  "& .right": {
    alignSelf: "flex-end",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    "& .left": {
      width: "auto",
    },
  },
}));
