import isEmail from "validator/lib/isEmail";
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles, styled, StyleRules } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { ButtonPill } from "@modules/Atoms/Buttons";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";
import OtpInput from "react-otp-input";
import { TailSpin } from "react-loader-spinner";
import { LogoSvg } from "@modules/Logo/Logo";
import { ADMIN_LOGIN, ADMIN_VERIFY_OTP } from "@src/graphql/queries/admin";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ERR_MSG, OTP_RESEND_TIME, ROUTES } from "@src/constants";
import Email from "@assets/email.svg";
import ErrorIcon from "@assets/errorIcon.svg";
export interface AuthFieldProps {
  email: boolean;
  otp: boolean;
  emailValue: string;
  otpValue: string;
  errEmail?: string;
  errOtp?: string;
}

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [otpCheck, setOtpCheck] = useState(false);
  const theme: Theme = useTheme();
  const sx = styles(useTheme());
  const [getLoginAccess] = useLazyQuery(ADMIN_LOGIN);
  const [verifyOtp] = useMutation(ADMIN_VERIFY_OTP);
  const submitHandler = (e: any) => {
    if (e.keyCode === 13) {
      sendOtp();
    }
  };

  const [field, setField] = useState<AuthFieldProps>({
    email: true,
    otp: false,
    emailValue: "",
    otpValue: "",
    errEmail: "",
    errOtp: "",
  });
  const [resendTimer, setResendTimer] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const timerRef: any = useRef();
  const sendOtp = async () => {
    try {
      setLoading(true);
      if (isEmail(field.emailValue)) {
        await getLoginAccess({
          variables: {
            email: field.emailValue,
          },
        }).then((data) => {
          const adminLogin = data?.data?.adminLogin;
          if (adminLogin?.success) {
            setResendTimer(true);
            setField({ ...field, otp: true, email: false, errEmail: "" });
          } else {
            setField({
              ...field,
              errEmail: ERR_MSG.NOTFOUNDEMAIL,
            });
          }
        });
        setLoading(false);
      } else {
        setField({
          ...field,
          errEmail: ERR_MSG.INVALIDEMAIL,
        });
      }
    } catch (error) {
      setResendTimer(false);
      setField({
        ...field,
        errEmail: "Something went wrong.",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (resendTimer) {
      startTimer();
    }
  }, [resendTimer]);

  const startTimer = () => {
    let timer = OTP_RESEND_TIME;
    setRemainingTime(timer);
    timerRef.current = setInterval(() => {
      timer = timer - 1;
      setRemainingTime(timer);
      if (timer === 0) {
        clearInterval(timerRef.current);
        setResendTimer(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (field.otpValue.length === 6) {
      OtpHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.otpValue.length]);

  const OtpHandler = async () => {
    setOtpCheck(true);

    try {
      if (field.otpValue) {
        const div = document.getElementById("testDiv");
        if (div?.getAttribute("alt")) {
          verifyOtp({
            variables: {
              email: field.emailValue,
              otp: field.otpValue,
              password: div?.getAttribute("alt"),
            },
          }).then(({ data }) => {
            if (data?.adminOtpVerify?.success) {
              const adminData = data?.adminOtpVerify?.data;
              localStorage.setItem("accessToken", adminData.idToken);
              setOtpCheck(false);
              const path = localStorage.getItem("initialPath");
              window.location.replace(path ? path : ROUTES.BRAND_LIST);
            } else {
              setField({
                ...field,
                errOtp: ERR_MSG.ERROTP,
              });
            }
          });
        } else {
          verifyOtp({
            variables: {
              email: field.emailValue,
              otp: field.otpValue,
            },
          }).then(({ data }) => {
            if (data?.adminOtpVerify?.success) {
              const adminData = data?.adminOtpVerify?.data;
              localStorage.setItem("accessToken", adminData.idToken);
              setOtpCheck(false);
              const path = localStorage.getItem("initialPath");
              window.location.replace(path ? path : ROUTES.BRAND_LIST);
            } else {
              setField({
                ...field,
                errOtp: ERR_MSG.ERROTP,
              });
            }
          });
        }
      }
    } catch (er) {
      if (er) {
        setField({
          ...field,
          errOtp: ERR_MSG.ERROTP,
        });
      } else {
        setField({
          ...field,
          errOtp: "",
        });
      }
    }
  };

  const inputHandler = (e?: string) => {
    setField({
      ...field,
      emailValue: e ? e : "",
    });
  };
  const otpInputHandler = (e?: string) => {
    if (e?.length) {
      if (e?.length < 7) {
        setField({ ...field, otpValue: e });
      }
    } else {
      setField({ ...field, otpValue: "" });
    }
  };
  return (
    <Container>
      <Paper sx={sx.paper} elevation={1}>
        <div>
          <Box sx={sx.heading}>
            {!field.otp ? (
              <Box sx={sx.headingSubContainer}>
                <Typography
                  style={{ marginRight: "15px" }}
                  sx={sx.text}
                  variant="h1"
                >
                  Login to
                </Typography>
                <Box>
                  <LogoSvg
                    color={theme.palette.mode === "light" ? "#00161F" : "#fff"}
                    width={150}
                  />
                </Box>
              </Box>
            ) : (
              <Box sx={sx.textContainer}>
                <Typography sx={sx.text} variant="h1">
                  Check your email for a code
                </Typography>
                <Typography sx={sx.caption} variant="h2">
                  {`We’ve sent a 6-character code to `}
                  <strong>{field.emailValue}</strong>
                  {`. The code
              expires shortly, so please enter it soon.`}
                </Typography>
              </Box>
            )}
          </Box>
          {field.email && (
            <Box sx={sx.fieldBox}>
              <CssTextField
                error={field.errEmail ? true : false}
                onKeyDown={submitHandler}
                helperText={
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "18px",
                    }}
                  >
                    {field.errEmail && (
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        src={ErrorIcon}
                        alt="icon"
                      />
                    )}{" "}
                    <span
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      {field.errEmail}
                    </span>
                  </span>
                }
                variant="outlined"
                label="Email address"
                sx={sx.emailInputBox}
                value={field.emailValue}
                onChange={(e) => inputHandler(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <img src={Email} alt="icon" />
                      </InputAdornment>
                    </>
                  ),
                }}
              />
              <Box>
                <ButtonPill sx={sx.save} onClick={sendOtp}>
                  {loading ? (
                    <TailSpin color="#F0E9FF" height={20} width={20} />
                  ) : (
                    "Login"
                  )}
                </ButtonPill>
              </Box>
            </Box>
          )}
          {field.otp && (
            <Box sx={sx.fieldBox}>
              {!otpCheck ? (
                <>
                  <OtpContainer
                    otp={field.otpValue}
                    handleChange={(e) => otpInputHandler(e)}
                  />
                  <div className="text-sm items-center justify-center">
                    {resendTimer ? (
                      <div className="pt-8">
                        Time remaining: {remainingTime} seconds
                      </div>
                    ) : (
                      <>
                        <p className="pt-8 text-neutral-charcoal text-base">
                          Didn’t receive a code?{" "}
                          <span
                            style={{ color: allColors.purple, fontWeight: 700 }}
                            data-test-id="signIn-otp-resend"
                            onClick={sendOtp}
                            className="ml-1 font-bold text-base text-primary-purple cursor-pointer "
                          >
                            Resend
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <TailSpin color="#7026FF" height={20} width={20} />
              )}
            </Box>
          )}
        </div>

        {/* <div id="recaptcha-container"></div> */}
      </Paper>
    </Container>
  );
};

export default LoginPage;

const OtpContainer = ({
  otp,
  handleChange,
}: {
  otp: string;
  handleChange: (...args: any) => void;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <OtpInput
      inputStyle={classes.inputBox}
      value={otp}
      onChange={handleChange}
      numInputs={6}
      renderInput={(props) => <input {...props} />}
      renderSeparator={
        <span style={{ display: "block", width: "15px" }}> </span>
      }
    />
  );
};

const styles = (theme: any) => {
  return {
    alertBox: {
      position: "fixed",
      bottom: 50,
      right: "5%",
      zIndex: 100,
    },
    headingSubContainer: {
      display: "flex",
      alignItems: "center",
    },
    text: {
      textTransform: "none",
      fontSize: "1.6rem",
      letterSpacing: "normal",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    caption: {
      textTransform: "none",
      fontWeight: "normal",
      width: "500px",
      padding: "1rem ",
      fontSize: "1rem",
      textAlign: "center",
      letterSpacing: "normal",
    },
    inputFocused: {
      outlineStyle: "none",
    },
    mainContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2rem",
    },
    heading: {
      marginTop: "50px",
      paddingBottom: "2rem",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      minHeight: "500px",
      borderRadius: "2px",
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      width: "100%",
      minWidth: "1000px",
      position: "relative",
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark2
      ),
    },
    fieldBox: {
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
    },
    save: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.purple,
        allColors.dark11
      ),
      borderRadius: "5px",
      boxShadow: getColorByTheme(theme, theme.shadows[1], "none"),
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "1rem",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      margin: "5px",
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.purple1,
          allColors.dark4
        ),
      },
      width: "320px",
    },
    buttonClass: {
      color: `${getColorByTheme(
        theme,
        allColors.dark1,
        allColors.light1
      )} !important`,
      background: `${getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark11
      )} !important`,
      border: "none !important",
    },
    emailInputBox: {
      minWidth: "300px",
      margin: "15px 0",
      fontFamily: "'Plus Jakarta Sans',  sans-serif !important",
      "&.MuiInputBase-input": {
        fontFamily: "'Plus Jakarta Sans',  sans-serif !important",
      },
    },
  };
};

const CssTextField = styled(TextField)({
  fontFamily:
    "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif !important",
  width: "320px",
  "&.MuiInputBase-input": {
    fontFamily:
      "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif !important",
  },
  "& .MuiFormHelperText-root": {
    fontFamily:
      "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif !important",
    marginRight: 0,
    marginLeft: 0,
  },
  "& label.Mui-focused": {
    fontFamily:
      "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif !important",
  },
  "& label": {
    fontFamily:
      "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#A0ADB3",
    },

    "&.Mui-focused fieldset": {
      borderWidth: "1px !important",
    },
  },
});

const PhoneInputstyles = (theme: Theme) => {
  const style: StyleRules = {
    fieldBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    inputclass: {
      background: "transparent !important",
      border: "none !important",
      color: `${getColorByTheme(
        theme,
        allColors.dark1,
        allColors.light1
      )} !important`,
      fontSize: "1.1rem !important",
      height: "50px !important",
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif !important",
    },
    input: {
      zIndex: 1,
      top: 0,
      border: "none",
      borderRadius: "8px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: getColorByTheme(theme, allColors.light2, allColors.dark11),
      width: "320px",
      marginBottom: "1rem",
      "&.react-tel-input .flag-dropdown:hover,&.react-tel-input .country-list .country:hover":
        {
          color: `${getColorByTheme(
            theme,
            allColors.dark1,
            allColors.light1
          )} !important`,
          background: `${getColorByTheme(
            theme,
            allColors.light2,
            allColors.dark10
          )} !important`,
        },
      "&.react-tel-input .country-list .country.highlight": {
        color: `${getColorByTheme(
          theme,
          allColors.dark1,
          allColors.light1
        )} !important`,
        background: `${getColorByTheme(
          theme,
          allColors.light2,
          allColors.dark10
        )} !important`,
      },
      "&.react-tel-input  .selected-flag .selected-flag:focus,.selected-flag.selected-flag:hover":
        {
          backgroundColor: `${getColorByTheme(
            theme,
            allColors.light2,
            allColors.dark10
          )} !important`,
        },

      "&.react-tel-input .selected-flag": {
        backgroundColor: `${getColorByTheme(
          theme,
          allColors.light2,
          allColors.dark10
        )} !important`,
      },
    },
    dropdownContainer: {
      "&::-webkit-scrollbar-track": {
        background: `${getColorByTheme(
          theme,
          allColors.light3,
          allColors.dark6
        )} !important`,
        borderRadius: "50px !important",
        margin: theme.spacing(0, 2),
      },

      color: `${getColorByTheme(
        theme,
        allColors.dark1,
        allColors.light1
      )} !important`,
      background: `${getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark11
      )} !important`,
      "&:focus,&:hover": {
        color: `${getColorByTheme(
          theme,
          allColors.dark1,
          allColors.light1
        )} !important`,
        background: `${getColorByTheme(
          theme,
          allColors.light1,
          allColors.dark11
        )} !important`,
      },
    },
    buttonClass: {
      color: `${getColorByTheme(
        theme,
        allColors.dark1,
        allColors.light1
      )} !important`,
      background: `${getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark11
      )} !important`,
      border: "none !important",
    },
    inputBox: {
      borderRadius: "8px",
      border: "none",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: getColorByTheme(theme, allColors.light2, allColors.dark11),
      width: "60px !important",
      height: "60px",
      fontSize: "1.5rem",
    },
  };
  return style;
};

const useStyles = makeStyles(PhoneInputstyles);
