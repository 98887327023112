/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { RoutesMain } from "./Routes";
import { I18nProvider } from "@src/_metronic/i18n";
import {
  materialTheme,
  LayoutSplashScreen,
  MaterialThemeProvider,
} from "@src/_metronic/layout";
import { ThemeProvider } from "@mui/material/styles";
import { Persistor } from "redux-persist";
import { Store } from "redux";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "@src/graphql/graphql";
interface AppProps {
  store: Store;
  persistor: Persistor;
  basename: string;
}

const App = ({ store, persistor, basename }: AppProps) => {
  return (
    <ThemeProvider theme={materialTheme}>
      <ApolloProvider client={apolloClient}>
        {/* Provide Redux store */}
        <Provider store={store}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <RoutesMain />
                  </I18nProvider>
                </MaterialThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </Provider>
      </ApolloProvider>
    </ThemeProvider>
  );
};
export default App;
