import React from "react";
import { Button, useTheme } from "@mui/material";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";

const styles = (theme: any) => {
  return {
    buttonStyle: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark2
      ),
      borderRadius: "100px",
      boxShadow: "none",
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "10px 25px 6px 25px",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light8,
          allColors.dark4
        ),
      },
    },
  };
};

export const ButtonPill = ({ children, ...props }: any) => {
  const sx = styles(useTheme());
  return (
    <Button sx={sx.buttonStyle} variant="contained" {...props}>
      {children}
    </Button>
  );
};
