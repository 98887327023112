import { Paper } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React, { FunctionComponent } from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  LineChart,
  Legend,
} from "recharts";
import { chartCardStyles } from "./ChartCard.styles";
import { ChartCardProps } from "@modules/Organisms/Charts/charts.types";
import {
  DataFormatter,
  DataFormatterYAxis,
  dateFormatter,
} from "@utils/digitFormatter";
import CustomToolTip from "@modules/Organisms/Charts/helper/CustomToolTip";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";
import CustomLegend from "@modules/Organisms/Charts/helper/CustomLegend";
import ChartHeader from "@modules/Organisms/Charts/ChartHeader/ChartHeader";
import FallBackChart from "@modules/Organisms/Charts/helper/FallBackChart";
const ChartCardPresentational: FunctionComponent<ChartCardProps> = ({
  classname,
  customstyle,
  data,
  xkey,
  xLabel,
  ykey,
  yLabel,
  secondYKey,
  secondYLabel,
  header,
  subHeader,
  selectedOption,
  dropdownOption,
  setDropdownOptions,
  filterKey,
}) => {
  const sx = chartCardStyles(useTheme());
  const theme: Theme = useTheme();
  return (
    <Paper
      sx={sx.paper}
      className={`${classname}`}
      style={{ ...customstyle, paddingBottom: header ? "5rem" : "1.5rem" }}
    >
      <ChartHeader
        data={data}
        header={header}
        subHeader={subHeader}
        dropdownOption={dropdownOption}
        selectedOption={selectedOption}
        setDropdownOptions={setDropdownOptions}
        filterKey={filterKey}
      />
      {data?.length === 0 && !data && (
        <FallBackChart classname=" mt-0 shadow-none" />
      )}
      {data && data.length > 0 && (
        <ResponsiveContainer width="100%" height="80%">
          <LineChart
            width={500}
            height={280}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 25,
              bottom: 5,
            }}
          >
            <CartesianGrid
              horizontal
              vertical={false}
              strokeDasharray="3 3"
              stroke={getColorByTheme(
                theme,
                `${allColors.light4}`,
                `${allColors.purple1}`
              )}
            />
            <XAxis
              tickFormatter={dateFormatter}
              dataKey={xkey}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              interval={0}
              tickFormatter={
                subHeader?.includes("$") ? DataFormatterYAxis : DataFormatter
              }
            />
            <Tooltip
              cursor={{
                stroke: "#aa8fda",
                strokeWidth: 2,
              }}
              wrapperStyle={{
                outline: "none",
                backgroundColor: getColorByTheme(
                  theme,
                  `${allColors.light4}`,
                  `${allColors.purple2}`
                ),
                padding: "20px 20px 20px 20px",
                borderRadius: "13px",
              }}
              formatter={function (value: any) {
                return `${value}`;
              }}
              labelFormatter={function (value) {
                return `${value}`;
              }}
              content={
                <CustomToolTip
                  xkey={xkey}
                  ykey={ykey}
                  xLabel={xLabel}
                  yLabel={yLabel}
                  secondYKey={secondYKey}
                  secondYLabel={secondYLabel}
                />
              }
            />

            <Line
              type="monotone"
              name={yLabel}
              dataKey={ykey}
              stroke="#7026FF"
              activeDot={{ r: 4 }}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              name={secondYLabel}
              dataKey={secondYKey}
              stroke="#82ca86"
              activeDot={{ r: 4 }}
              strokeWidth={2}
              dot={false}
            />

            {ykey && secondYKey ? (
              <Legend
                verticalAlign="bottom"
                height={36}
                align="left"
                content={CustomLegend}
              />
            ) : (
              ""
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default ChartCardPresentational;
