import React, { useState } from "react";
import { Box, Chip, MenuItem, Select, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

const useStyles = makeStyles((theme) => ({
  select: {
    background: getColorByTheme(theme, allColors.light3, allColors.dark3),
    fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
    fontSize: "1.23rem",
    width: "100%",
    borderRadius: "6px",
    "& .MuiSelect-select": {
      minWidth: "30px",
      color: getColorByTheme(theme, allColors.dark5, allColors.light1),
      padding: (props) =>
        props.multiple
          ? theme.spacing("11px", 4, "11px", 2)
          : theme.spacing(2, 0, 2, 2),
      "&:focus": {
        background: "none",
      },
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const SelectDropdown = ({
  options,
  selected,
  onChange,
  multiple,
  customClassname,
}) => {
  const classes = useStyles({ multiple });
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const MenuProps = {
    PaperProps: {
      style: {
        background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      },
    },
  };
  return (
    <Select
      variant="standard"
      onClick={() => setOpen((prev) => !prev)}
      open={open}
      disableUnderline
      className={`${classes.select} ${customClassname}`}
      value={selected}
      onChange={(e) => {
        onChange(e.target.value);
        if (!multiple) {
          setOpen((prev) => !prev);
        }
      }}
      IconComponent={DropDownIcon}
      MenuProps={MenuProps}
      multiple={multiple}
      renderValue={
        multiple
          ? (selected) => (
              <div className={classes.chips}>
                {selected.map((value) =>
                  value === "All" ? (
                    <Chip key={value} label={value} className={classes.chip} />
                  ) : (
                    <Chip
                      key={value}
                      label={value}
                      style={{
                        background: getColorByTheme(
                          theme,
                          allColors.light2,
                          allColors.dark10
                        ),
                        fontSize: ".9rem",
                        cursor: "pointer",
                        margin: 2,
                      }}
                      onDelete={(e) => {
                        e.stopPropagation();
                        const temp = [...selected];
                        const filtered = temp.filter(
                          (v) => v.toLowerCase() !== value.toLowerCase()
                        );
                        onChange(filtered);
                      }}
                    />
                  )
                )}
              </div>
            )
          : null
      }
    >
      {options.map((v, i) => (
        <MenuItem onClick={(e) => e.stopPropagation()} key={i} value={v.value}>
          {v.title}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SelectDropdown;

export const DropDownIcon = () => {
  const theme = useTheme();
  return (
    <Box
      style={{
        paddingRight: "15px",
        pointerEvents: "none",
      }}
      alt="dropdown arrow"
    >
      <DropDownIconSvg
        color={getColorByTheme(theme, allColors.dark5, allColors.light1)}
      />
    </Box>
  );
};

export const DropDownIconSvg = ({ color }) => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0830574 1.40238L3.74439 5.55938C3.77756 5.59397 3.81738 5.6215 3.86146 5.6403C3.90554 5.65911 3.95297 5.6688 4.00089 5.6688C4.04881 5.6688 4.09624 5.65911 4.14032 5.6403C4.1844 5.6215 4.22422 5.59397 4.25739 5.55938L7.91706 1.40238C7.94633 1.3694 7.96867 1.33087 7.98276 1.28909C7.99684 1.24731 8.00239 1.20312 7.99906 1.15915C7.99573 1.11519 7.9836 1.07234 7.96339 1.03315C7.94317 0.993963 7.91529 0.95924 7.88139 0.931043L7.26472 0.412043C7.19596 0.354068 7.10716 0.325455 7.01748 0.332377C6.9278 0.339299 6.84444 0.381202 6.78539 0.449042L4.06372 3.60004C4.0559 3.60913 4.04621 3.61643 4.03531 3.62143C4.0244 3.62643 4.01255 3.62902 4.00056 3.62902C3.98856 3.62902 3.97671 3.62643 3.96581 3.62143C3.95491 3.61643 3.94521 3.60913 3.93739 3.60004L1.21472 0.450042C1.18539 0.416267 1.14968 0.388607 1.10964 0.368645C1.0696 0.348684 1.02602 0.336812 0.98139 0.333709C0.937007 0.329752 0.892281 0.334734 0.849856 0.34836C0.807431 0.361986 0.768168 0.383981 0.73439 0.413042L0.11739 0.932376C0.08357 0.960626 0.0557575 0.995371 0.0355959 1.03455C0.0154342 1.07374 0.00333306 1.11657 8.06999e-06 1.16051C-0.00331692 1.20445 0.00220152 1.24861 0.0162372 1.29038C0.0302724 1.33216 0.0525403 1.37069 0.0817241 1.40371L0.0830574 1.40238Z"
        fill={color}
      />
    </svg>
  );
};
