import { gql } from "@apollo/client";

const GET_BRANDS_LIST = gql`
  query onGetBrandsList($input: Boolean) {
    getBrandsList(input: $input) {
      status
      success
      message
      accountData {
        _id
        tiktokUserId
        tiktokUserName
        tiktokProfileLink
        bioLink
        brandCategory
        followers
        isBrand
        isVerified
        creators
        taggedPosts
        totalViews
        correctedCategories
        createdAt
        updatedAt
      }
    }
  }
`;
const GET_BRANDS_CHART = gql`
  query onGetBrandsChart($firstMonth: String, $lastMonth: String) {
    getBrandsChart(firstMonth: $firstMonth, lastMonth: $lastMonth) {
      chartValue {
        dateId
        totalCpm
        totalViews
        accounts
        sponsoredPosts
      }
      brandsTotal {
        totalBrands
        totalEarning
        totalViewsPurchased
        totalSponsoredPost
      }
    }
  }
`;

const GET_INDIVIDUAL_CREATOR_BRANDS_DETAILS = gql`
  query GetIndividualCreatorsBrandChart($input: CreatorsBrandListInput!) {
    getIndividualCreatorsBrandChart(input: $input) {
      status
      success
      message
      totalRecords
      data {
        totalPosts
        totalEarning
        totalViews
        avgViews
        tiktokUsername
        tiktokProfileLink
        tiktokImage
      }
    }
  }
`;

const GET_INDIVIDUAL_BRANDS_CARD_DATA = gql`
  query onGetBrandsProfile($tiktokusername: String) {
    getBrandsProfile(tiktokusername: $tiktokusername) {
      account {
        tiktokUserName
        tiktokProfileLink
        bioLink
        followers
        creators
        totalViews
        tiktokProfileImage
        totalSpent
        totalPosts
        correctedCategories
      }
    }
  }
`;

const GET_INDIVIDUAL_BRANDS_CHART_DATA = gql`
  query onGetIndividualBrandsChart($tiktokusername: String) {
    getIndividualBrandsChart(tiktokusername: $tiktokusername) {
      brandHeaderData {
        totalPostAcross
        totalSpentAcross
        totalViewsAcross
        totalCreators
      }
      brandChart {
        dateId
        totalSpend
        totalViews
        totalCreatorsPosting
        totalPosts
      }
    }
  }
`;

export {
  GET_BRANDS_LIST,
  GET_BRANDS_CHART,
  GET_INDIVIDUAL_CREATOR_BRANDS_DETAILS,
  GET_INDIVIDUAL_BRANDS_CARD_DATA,
  GET_INDIVIDUAL_BRANDS_CHART_DATA,
};
