import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import allColors from "@src/app/modules/Colors/Colors";

export const MobileTos = () => {
  const sx = styles(useTheme());
  return (
    <>
      <Box sx={sx.boxStyle}>
        <Box>
          <Typography sx={sx.headerStyle}>Terms of Service</Typography>
        </Box>
        <Box>
          <Typography sx={sx.textStyle}>
            By clicking “accept” you agree to the following:
          </Typography>
          <br />
          <br />
          <Typography sx={sx.textStyle}>
            <Typography sx={sx.subHeaderStyle}>Services</Typography>
            This Creator Services Agreement (the “Agreement”) applies to your
            (“Creator”) access to and use of the websites, mobile application,
            widgets, APIs, emails, and other online products and services
            (collectively, the “UBQ Applications”) provided by The Ubiquitous
            Agency, Inc., a Delaware corporation (“UBQ”). By accessing and/or
            using any of the UBQ Applications, Creator agrees to the following:
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              1. Offer and Acceptance; Services.
            </Typography>
            From time to time during the Term (as defined in Section 2), UBQ
            shall transmit offers (each, an “Offer”) to Creator with respect to
            certain social media production and publication services (the “Work
            Product”). Creator may accept each such Offer through the App
            (defined in Section 5(d)). Upon acceptance of an Offer, Creator
            shall perform the services described in such Offer (in each case,
            the “Services”) in compliance with the terms and services set forth
            in this Agreement.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              2. Termination:
            </Typography>
            The term of this Agreement shall begin on the Effective Date and
            remain in effect unless and until it is terminated (the “Term”) by
            UBQ: (i) for convenience upon five (5) days prior notice to Creator;
            or (ii) immediately upon notice to Creator in the event that Creator
            commits a material breach of this Agreement. Upon termination of
            this Agreement, Creator shall only be entitled to the Fees (defined
            in Section 4(a)) accrued prior to the termination of the Agreement
            for Services satisfactorily rendered through the date of such
            termination.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              3. Relationship:
            </Typography>
            Creator acknowledges and agrees that it is an independent contractor
            and not an employee or agent of UBQ or its clients. Creator will not
            be entitled to any of the benefits which UBQ may make available to
            its employees, and Creator is not authorized to make any
            representation, contract or commitment on behalf of UBQ.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              4. Compensation:
            </Typography>
            <br />
            <br />
            <ul style={sx.textStyle}>
              <Typography variant="caption" sx={sx.subParaHeaderStyle}>
                a. Fees:
              </Typography>
              In full consideration for the Services satisfactorily rendered and
              the rights granted by Creator under this Agreement, UBQ shall pay
              the fees described in the applicable Offer within thirty (30) days
              of Creator’s completion of the Services (as determined by UBQ in
              its sole discretion). No additional compensation will be provided
              by UBQ to Creator. From time to time and at UBQ’s sole discretion,
              UBQ may provide Creator with a written option (an “Option”) to
              receive payment of Fees prior to the above-referenced thirty (30)
              day period in exchange for Creator’s payment of a fee identified
              by UBQ in such written Option. Creator is solely responsible for
              providing written notice to UBQ of the location where all Fees may
              be sent as well as the address where UBQ may send all applicable
              tax documents.
            </ul>
            <ul style={sx.textStyle}>
              <Typography variant="caption" sx={sx.subParaHeaderStyle}>
                b. Taxes: Before any Fees are paid to Creator, Creator is
                required by law to complete IRS form W9 and send it to{" "}
                <a href="mailto: ap@ubiquitousinfluence.com">
                  ap@ubiquitousinfluence.com
                </a>{" "}
                (alternatively, but only if and as instructed by UBQ, Creator
                shall use a third party payment processor to complete IRS form
                W9 and to receive payment of Fees).
              </Typography>
              If applicable,UBQ will issue an IRS Form 1099 to Creator for the
              amount of the Fees. Creator shall have sole and exclusive
              responsibility for the making of all reports and contributions,
              withholding payments and taxes to be collected, withheld, made and
              paid with respect to the Services.
            </ul>
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              5. Ownership Rights:
            </Typography>
            <br />
            <br />
            <ul style={sx.textStyle}>
              <Typography variant="caption" sx={sx.subParaHeaderStyle}>
                a. Ownership of Work Product:
              </Typography>
              All Work Product delivered as part of the Services, and any
              designs, inventions, works of authorship, trade secrets or other
              intellectual property arising from the Services, shall remain
              Creator’s sole property. Notwithstanding the foregoing, Creator
              shall grant to UBQ a non-exclusive, worldwide, paid-up,
              sublicensable, right and license to the applicable Work Product to
              allow UBQ to use such Work Product in any internal communications,
              analyses, or reporting, and external marketing with respect to UBQ
              or the App (the “UBQ License”). In the event that any Offer
              requires Creator to license the Work Product to a third party
              (each, a “Third Party”), and Creator accepts such Offer, then
              Creator shall also grant to the applicable Third Party an
              exclusive (except to the extent of a UBQ License), worldwide,
              paid-up, sublicensable, right and license to the applicable Work
              Product, to use or otherwise exploit the Work Product, in whole or
              in part, alone or in combination with other content, in any and
              all media whether now existing or later developed (the “License”).
              Any work product created by a Third Party (or any sublicensee)
              which may incorporate Work Product (“Derivative Work Product”),
              including without limitation e-newsletters, websites, press
              releases, media materials, social media content/posts and
              syndicated news features, shall be and remain the property of such
              Third Party (or sublicensee), as applicable, and such Third Party
              (or sublicensee), as applicable, shall have the right to use,
              reuse, reproduce, copyright and exhibit the Derivative Work
              Product throughout the world in any and all media and for any and
              all purposes. Creator shall not reproduce, distribute, disclose or
              sell to any other person or entity any Work Product unless each
              use is otherwise approved in advance and in writing by the
              applicable Third Party.
            </ul>
            <ul style={sx.textStyle}>
              <Typography variant="caption" sx={sx.subParaHeaderStyle}>
                b. Prior Approval:
              </Typography>
              All Work Product must be pre-approved by UBQ in advance and prior
              to posting, and will adhere to the terms set forth in this
              Agreement. Work Product shall be posted exactly as approved by
              UBQ.
            </ul>
            <ul style={sx.textStyle}>
              <Typography variant="caption" sx={sx.subParaHeaderStyle}>
                c. Background License:
              </Typography>
              If any part of the Services or Work Product or information
              provided hereunder is based on, incorporates, or is an improvement
              or derivative of, or cannot be reasonably and fully made, used,
              reproduced, distributed and otherwise exploited without using or
              violating technology or intellectual property rights owned by or
              licensed to Creator (or any person involved in the Services) and
              not assigned hereunder, Creator hereby grants to UBQ and the Third
              Party and its successors a perpetual, irrevocable, worldwide
              royalty-free, non-exclusive, sublicensable right and license to
              exploit and exercise all such technology and intellectual property
              rights in support of the Third Party’s exercise or exploitation of
              the Services, Work Product, other work or information performed or
              provided hereunder, or any assigned rights (including any
              modifications, improvements and derivatives of any of them).
            </ul>
            <ul style={sx.textStyle}>
              <Typography variant="caption" sx={sx.subParaHeaderStyle}>
                d. Ownership of App:
              </Typography>
              Creator acknowledges and agrees that UBQ is the sole owner (or is
              an authorized licensee) of the software application, and related
              source code, for use on an electronic device, which provides
              Creator with access to various Offers (the “App”). Creator shall
              not decompile, reverse engineer, decrypt, extract or disassemble
              the App or otherwise reduce or attempt to reduce any software or
              firmware in the App to source code form.
            </ul>
            <ul style={sx.textStyle}>
              <Typography variant="caption" sx={sx.subParaHeaderStyle}>
                e. Removal:
              </Typography>
              At any time during the Term and for a period of two (2) years
              thereafter, UBQ may request and Creator shall, remove any or all
              Work Product from any social media platform now in existence or
              hereinafter created. In the event UBQ terminates this Agreement
              upon the breach by Creator of Section 7(vii), Creator shall as
              soon as reasonably possible (and without the need to receive a
              request from UBQ) remove all Work Product from any social media
              platform now in existence or hereinafter created and which is
              controlled by Creator.
            </ul>
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              6. Creator Disclosure:
            </Typography>
            During the Term, whenever Creator addresses or gives an opinion
            about a certain brand or company or any products or services of such
            brand or company, Creator is required to disclose Creator’s
            relationship with such brand, company, product or service, and
            disclose the fact that Creator is being compensated for his/her
            Services as required by the FTC’s Guides Concerning the Use of
            Endorsements and Testimonials in Advertising (the “FTC Guides”).
            Creator further agrees to follow the guidelines set forth on the
            attached <span style={sx.subParaHeaderStyle}>Exhibit A</span> in the
            development of all Materials, as well as the FTC disclosure set
            forth on the attached{" "}
            <span style={sx.subParaHeaderStyle}> Exhibit B</span>Notwithstanding
            the termination provision set forth herein, UBQ reserves the right
            to immediately terminate this Agreement if Creator fails to make
            blog, social media or other disclosures in the manner required by
            the FTC Guides and as set forth in Exhibit A, which failure shall be
            deemed a material breach of the Agreement that is not capable of
            cure.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              7. Representations and Warranties:
            </Typography>
            Creator represents and warrants that during Creator’s provision of
            the Services: (i) Creator has the right and authority to enter into
            this Agreement without violating the rights of any third party; (ii)
            Creator will comply with all applicable laws, regulations,
            administrative guidelines, orders and ordinances, as well as UBQ’s
            instructions and guidelines (including, without limitation, the FTC
            Guides); (iii) Creator shall not knowingly make any false,
            misleading or disparaging remarks about UBQ or any brand to which
            the Services apply; (iv) the Services and any Work Product will be
            original, have not been previously published or otherwise
            distributed (in whole or in part), and shall not violate or
            otherwise infringe the rights of any third parties; (v) Creator will
            at all time while providing the Services, act in a professional
            manner in accordance with the highest standards in the industry for
            services similar to the Services to be performed by Creator, not be
            under the influence of drugs or alcohol, and shall not act or engage
            in any practice or conduct that is or shall be an offense involving
            moral turpitude under federal, state or local laws, or brings UBQ
            into public disrepute, contempt, scandal, or ridicule, or that
            insults or offends the community or any substantial organized group
            thereof, and (vi) the Services will be of good quality and free from
            all defects, whether patent or latent, in materials, workmanship,
            and design.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              8. Confidentiality:
            </Typography>
            In performing Services under this Agreement, Creator may be exposed
            to certain information deemed proprietary and confidential by UBQ
            (including, but not limited to the terms of this Agreement). Creator
            agrees that, during the Term of this Agreement and for a period of 5
            years thereafter, Creator will not at any time disclose to anyone
            any such confidential information without prior written consent from
            UBQ, except to authorized representatives and/or as required by law
            or legal process. This paragraph shall survive the termination or
            expiration of this Agreement.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              9. Indemnification:
            </Typography>
            Creator shall defend, indemnify and hold harmless UBQ and its
            parents, subsidiaries and affiliated companies, and their respective
            agents, employees, representatives, contractors, successors, and
            assigns (each, an “Indemnified Party”) from and against any and all
            claims, demands, suits, actions, losses, damages, costs, judgments,
            penalties and expenses of any kind (including reasonable legal fees
            and disbursements) (“Legal Liabilities”) which may be obtained
            against, imposed upon or suffered by an Indemnified Party as a
            result of (i) the material breach by Creator (or Creator’s
            employees, agents, contractors or representatives) of this Agreement
            or any of the warranties and representations made herein; (ii) a
            claim that the Services or any Work Product purchased hereunder
            infringes or misappropriates any United States or foreign patent,
            copyright, trade secret or other proprietary right, (iii) any
            content, statements, information or material supplied by Creator to
            UBQ in connection with the Services, or (iv) Creator’s intentional
            misconduct or negligent acts or omissions. This paragraph shall
            survive the termination or expiration of this Agreement.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              10. LIMITATION OF LIABILITY:
            </Typography>
            IN NO EVENT WILL UBQ BE LIABLE FOR: (I) ANY SPECIAL, INCIDENTAL,
            PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH
            THIS AGREEMENT, EVEN IF UBQ HAS BEEN INFORMED IN ADVANCE OF THE
            POSSIBILITY OF SUCH DAMAGES; OR (II) ANY DAMAGES IN EXCESS OF THE
            FEES PAID TO CREATOR DURING THE PRECEDING TWELVE (12) MONTH PERIOD.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              11. Liability Insurance:
            </Typography>
            Creator acknowledges that UBQ will not carry any liability insurance
            on behalf of Creator. Creator will maintain in force adequate
            liability insurance to protect Creator from claims of personal
            injury (or death) or tangible or intangible property damage
            (including loss of use) that arise out of any act or omission of
            Creator or any Creator personnel.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              12. Miscellaneous:
            </Typography>
            This Agreement shall be governed and construed in accordance with
            the laws of the State of Delaware, without giving effect to conflict
            of law principles, and the parties agree to the exclusive
            jurisdiction of the courts of the state and federal courts located
            in Chattanooga, TN, with respect to any actions or claims arising
            out of this Agreement. This Agreement, including the attached
            Exhibits, represents the entire agreement between the parties
            concerning the subject matter herein. This Agreement may only be
            modified or assigned by a written document signed by both parties.
            Creator understands and agrees that UBQ has the full rights as
            specified herein, but not the obligation to use any Work Product
            created under this Agreement. Notwithstanding anything in this
            Agreement to the contrary, neither party shall be liable to the
            other for failure to comply with any obligation under this Agreement
            if prevented from doing so by reason of contingencies beyond the
            reasonable control of the parties, including but not limited to the
            elements, fires, acts of God or acts or omissions or failures of
            carriers, utilities or other third parties. No waiver by any party
            of the nonperformance or breach of any term, condition or obligation
            to be performed or binding upon another party under this Agreement
            will be a waiver of any other nonperformance or breach. No failure
            or delay by any party in exercising any right or privilege under
            this Agreement shall operate as a waiver thereof.
            <br />
            <br />
          </Typography>

          <br />
          <br />
          <Typography sx={sx.textStyle}>
            <Typography sx={sx.subHeaderStyle}>EXHIBIT A</Typography>
            <Typography sx={sx.subHeaderStyle}>
              Ubiquitous Creator Guidelines
            </Typography>
            <br />
            Creator shall abide by the following Guidelines when performing
            Services as outlined in the Agreement and each Offer at the direct
            request of The Ubiquitous Agency, Inc. ("UBQ"), or when UBQ has
            provided an incentive or other benefits to Creator. <br />
            <br />
            For the avoidance of doubt, in addition to the obligations set forth
            in the Agreement, Creator’s Services include any ongoing electronic
            chronicle of information conducted on second party websites (i.e.
            personal website) or third party websites (e.g., Facebook, Twitter,
            Pinterest, Instagram, YouTube, etc.). Services also include making
            comments about UBQ, the brand, company, product or service of which
            is the subject of the Services, or those of its competitors, on
            other third party websites. <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              1. Disclose Your Connection to the Brand
            </Typography>
            <br />
            Whether you were directly engaged by the brand or through UBQ for
            Services, or have simply been given an incentive to review the
            brand's products, you must make your connection to the brand clear
            to readers. Review the attached Exhibit B, incorporated here by
            reference (“FTC Disclosure Acknowledgement”).
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              2. Give Your Honest and Truthful Opinions
            </Typography>
            <br />
            Your statements should always reflect your honest and truthful
            opinions and actual experiences. If a statement is not your opinion,
            but rather something that a brand has asked you to say, this fact
            should be made clear to readers. Unless otherwise set forth herein,
            if you received an incentive to review a brand's products or
            services, you are not obligated to write anything at all, but if you
            do, we encourage you to be honest about your views, whatever they
            may be.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              3. Only Make Factual Statements That Are Truthful and Can Be
              Verified
            </Typography>
            <br />
            Only make a factual statement about a product/service's
            characteristic or quality which you know for certain is true and can
            be verified. Remember that even if you don't expressly state a fact,
            it may be implied, and these Guidelines apply to both express and
            implied messages. Be especially careful about making any factual
            statement about a brand's competitors or their products/services.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              4. Do Not Disclose Any Confidential Information
            </Typography>
            <br />
            If you are in a position whereby you have received information about
            a brand, or its products or services, which is not known by the
            general public, do not include such information in your content. To
            the extent you have any question as to whether information is
            confidential in nature, either contact UBQ directly before
            disclosing such information or otherwise err on the side of caution.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              5. Respect Intellectual Property Rights
            </Typography>
            <br />
            Intellectual Property is the group of legal rights to things people
            create or invent. Intellectual property rights typically include
            copyright, trademark, and trade secret rights, as well as the right
            to use someone's name, likeness or voice. Examples are photographs,
            video, music, trademarks/logos, personal names/likenesses, writings,
            etc.
            <br />
            <br />
            Only use a brand’s intellectual property with express permission,
            and do not alter or modify any brand intellectual property. Only use
            third-party owned intellectual property with express permission or
            when such use is otherwise permitted under the law (such as a "fair
            use").
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              6. Do Not Send Email Messages on a Brand’s Behalf Unless Expressly
              Requested
            </Typography>
            <br />
            Unless expressly requested, UBQ does not want you to send any emails
            on its or any brand’s behalf, nor will it provide you any
            compensation if you do. To the extent that you send your own emails,
            UBQ encourages you to comply with all applicable laws.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              7. You Are Personally Responsible For Your Actions
            </Typography>
            <br />
            You should know that you are personally liable under federal and
            state law for your actions with respect to your Services, which
            makes following these Guidelines, as well as all other applicable
            laws, regulations and other legal authority, including but not
            limited to the FTC’s Guides Concerning the Use of Testimonials and
            Endorsements in Advertising and the Children’s Online Privacy
            Protection Act (“COPPA”). Furthermore, you should know that UBQ will
            hold you directly responsible for any claims which arise from your
            violation of the law, or UBQ’s rights, and by providing Services you
            hereby agree to indemnify UBQ from any liability which arises out of
            the foregoing.
            <br />
            <br />
            <Typography variant="caption" sx={sx.subParaHeaderStyle}>
              8. We Reserve The Right To Ask You To Remove Content
            </Typography>
            <br />
            By providing Services at the direct request of UBQ, or by accepting
            any incentive from UBQ, you agree that you will immediately remove,
            take down, or delete any published content relating to any brand
            identified in an Offer, its products or its services, those of its
            competitors or those associated with the brand, from any site or
            platform that UBQ notifies you that it finds objectionable. Even if
            we do not notify you, we expect that you will promptly remove any
            content for which you receive a legitimate complaint or which you
            later become aware may be in violation of the law or otherwise
            violate third party rights.
            <br />
            <br />
          </Typography>
          <br />
          <br />

          <Typography sx={sx.textStyle}>
            <Typography sx={sx.subHeaderStyle}>EXHIBIT B</Typography>
            <Typography sx={sx.subHeaderStyle}>
              FTC Disclosure Acknowledgement
            </Typography>
            <br />
            Creator shall follow the Federal Trade Commission (FTC) Disclosure
            Acknowledgement guidelines as outlined below to maintain compliance
            with the FTC’s Guides Concerning the Use of Endorsements and
            Testimonials in Advertising.
            <br />
            <br />
            You recognize that you are required to properly disclose your
            relationship with each brand that is the subject of each Offer.
            Proper disclosure may include the statement “Sponsored by [brand]”
            within the content’s title or within the first sentence of the
            content, or a static "[brand] Sponsored" banner on your website.
            Failure to provide proper disclosure will be considered a material
            breach of this Agreement that is not capable of cure and,
            notwithstanding the termination provisions set forth in the
            Agreement, UBQ reserves the right to immediately terminate the
            Agreement if you fail to make such disclosures in the manner
            required by the FTC Guides and as set forth herein. <br />
            <br />
            Your disclosure must be upfront, clear, stand out, and include
            unambiguous language. This connection should be disclosed regardless
            of space limitations of the medium and it should be prominent enough
            for consumers view it easily when they are reading your posts. Your
            readers must learn of the disclosure as quickly as possible,
            meaning, they cannot be required to scroll down a page to learn of
            the disclosure. This disclosure cannot be located only on an “About
            Me” page or only another hidden, or separate section of your
            website, blog, social media page, or platform.
            <br />
            <br />
            In general, disclosures should be:
            <br />
            <ul style={sx.textStyle}>
              <div>
                <CircleIcon sx={sx.bulletStyle} />
                Close to the claims to which they relate;
              </div>
              <div>
                <CircleIcon sx={sx.bulletStyle} />
                In a font that is easy to read
              </div>
              <div>
                <CircleIcon sx={sx.bulletStyle} />
                In a shade that stands out against the background;
              </div>
              <div>
                <CircleIcon sx={sx.bulletStyle} />
                For video ads, on the screen long enough to be noticed, read,
                and understood;
              </div>
              <div>
                <CircleIcon sx={sx.bulletStyle} />
                For audio disclosures, read at a cadence that is easy for
                consumers to follow and in words consumers will understand.
              </div>
            </ul>
            <br />
            <br />
            The FTC Guides also require a disclosure on any social media posts,
            pins, or channels for which you have been compensated. For example,
            you may make required disclosures on social media using hashtags
            (e.g., #sponsored, #ad and/or #PaidAd). You may also make required
            disclosures on social media using the title of your content (e.g.,
            “Sponsored Post,” “Sponsored Tweet,” “Sponsored Partnership,” or
            “Paid Partnership”), or in the language of the post (e.g., “Check
            out my new sponsored post about ...”).
            <br />
            <br />
            Full details of the FTC guidelines are at &nbsp;
            https://www.ftc.gov/tips-advice/business-center/guidance/ftcs-endorsement-guides-what-people-are-asking
            &nbsp; , which you hereby confirm you have reviewed and understood.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const styles = (theme: any) => {
  return {
    boxStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      backgroundColor: allColors.dark1,
      color: allColors.light1,
      fontFamily: "Plus Jakarta Sans",
      width: "90%",
      marginBottom: "12%",
    },
    headerStyle: {
      fontSize: "32px",
      fontWeight: "700",
    },
    subHeaderStyle: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "26px",
    },
    subParaHeaderStyle: {
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "26px",
      paddingRight: "5px",
    },
    textStyle: {
      fontSize: "15px",
      lineHeight: "22.5px",
    },
    bulletStyle: {
      height: "7px",
      width: "7px",
      marginRight: "0.75rem",
    },
  };
};
