import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { IndividualCreatorBrandDetails } from "@Models/Brands";
import { GET_INDIVIDUAL_CREATOR_BRANDS_DETAILS } from "@src/graphql/queries/getBrandsList";
import CreatorBrandDetailsPresentational from "./CreatorBrandDetailsTable.Presentational";
import { formatNumber, getSorting } from "@modules/utils/utils";
import { formatString } from "@modules/Organisms/Formatter/Formatter";
import { totalCount } from "@src/constants";

const CreatorBrandDetailsTable = () => {
  const [brands, setBrands] = useState<IndividualCreatorBrandDetails[]>([]);
  const [order, setOrder] = useState<"asc" | "desc" | undefined>("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [totalPage, setTotalPage] = useState(30);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loaderPosition, setLoaderPosition] = useState<boolean>(false);
  const [pageRecord, setPageRecord] = useState<number>(1);
  const [pageDropDown, setPageDropDown] = useState<number>(1);
  const params: any = useParams();

  const [getBrandsList, { data: brandsData, loading }] = useLazyQuery(
    GET_INDIVIDUAL_CREATOR_BRANDS_DETAILS
  );

  const headRows = [
    {
      id: "tiktokUsernameStickyColumn",
      label: "Brand Name",
      link: "tiktokProfileLink",
    },
    { id: "totalPosts", label: "Number of Posts" },
    { id: "totalViews", label: "Total Views" },
    { id: "avgViews", label: `Avg Views For ${params.creator}` },
  ];

  useEffect(() => {
    getBrandsList({
      variables: {
        input: {
          pageNumber: page,
          tiktokUsername: params.creator,
          sortByBrandUsername: getSorting(
            "tiktokUsernameStickyColumn",
            order,
            orderBy
          ),
          sortByTotalSponseredViews: getSorting("totalViews", order, orderBy),
          sortByAvgNumberOfPaidPostsViews: getSorting(
            "avgView",
            order,
            orderBy
          ),
          sortByTotalNumberOfPaidPosts: getSorting(
            "totalPosts",
            order,
            orderBy
          ),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page]);

  useEffect(() => {
    if (!brands || !brandsData) {
      setOpen(true);
    }
  }, [brands, brandsData]);

  useEffect(() => {
    setPage(1);
    setPageRecord(1);
    setPageDropDown(1);
  }, [order, orderBy]);

  useEffect(() => {
    if (brandsData && brandsData.getIndividualCreatorsBrandChart) {
      setBrands(
        formatBrandsList(brandsData.getIndividualCreatorsBrandChart.data)
      );
      setTotalPage(brandsData.getIndividualCreatorsBrandChart.totalRecords);
    }
  }, [brandsData]);

  const handleRequestSort3 = (property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      brandsData?.getIndividualCreatorsBrandChart &&
      count !== brandsData?.getIndividualCreatorsBrandChart?.totalRecords
    ) {
      setCount(
        brandsData?.getIndividualCreatorsBrandChart
          ? Math.ceil(
              brandsData?.getIndividualCreatorsBrandChart?.totalRecords /
                totalCount
            )
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsData]);

  useEffect(() => {
    setLoaderPosition(false);
  }, [order, orderBy]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPageDropDown(parseInt(newValue));
      setLoaderPosition(true);
      setPageRecord(parseInt(newValue));
    }
  };

  let options = [];

  useEffect(() => {
    if (pageDropDown !== null) {
      setPage(pageDropDown);
    }
  }, [pageDropDown]);

  for (let pageValue = 1; pageValue <= (count === 0 ? 1 : count); pageValue++) {
    options.push(pageValue);
  }

  const onKeyDown = (ev: any) => {
    if (
      ev.key === "Enter" &&
      ev.target.value <= options.length &&
      ev.target.value > 0
    ) {
      if (ev.target.value && pageDropDown !== null) {
        setPage(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
    }
  };

  const position: any = loaderPosition ? { top: "10%" } : { bottom: "10%" };
  const endNumber = pageRecord * totalCount;
  const startRecordNumber = endNumber - (totalCount - 1);
  const endRecordNumber =
    endNumber > totalPage
      ? startRecordNumber + (totalPage - startRecordNumber)
      : endNumber;

  return (
    <div>
      <CreatorBrandDetailsPresentational
        tableData={brandsData}
        brands={brands}
        headRows={headRows}
        handleRequestSort3={handleRequestSort3}
        order={order}
        orderBy={orderBy}
        count={count}
        page={page}
        setPage={setPage}
        open={open}
        loading={loading}
        position={position}
        setLoaderPosition={setLoaderPosition}
        setPageRecord={setPageRecord}
        startRecordNumber={startRecordNumber}
        endRecordNumber={endRecordNumber}
        totalPage={totalPage}
        handleChange={handleChange}
        options={options}
        pageDropDown={pageDropDown}
        setPageDropDown={setPageDropDown}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default CreatorBrandDetailsTable;

const formatBrandsList = (
  BrandData: IndividualCreatorBrandDetails[]
): IndividualCreatorBrandDetails[] => {
  return BrandData.map((v) => {
    return {
      ...v,
      tiktokUsernameStickyColumn: formatString(v.tiktokUsername),
      totalPosts: formatNumber(v.totalPosts),
      totalViews: formatNumber(v.totalViews),
      avgViews: formatNumber(v.avgViews),
    };
  });
};
