import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import { useTheme, makeStyles, StyleRules } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { GenreList } from "@Models/Genre";
import GList from "@modules/GenreSelection/genreList";

interface Props {
  selectedList: GenreList[];
  updateList: any;
}

export const AutoSuggestGenre = ({ selectedList, updateList }: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<GenreList[]>([]);
  const [suggestionsData, setSuggestionsData] = useState<GenreList[]>([]);

  useEffect(() => {
    const res = createSuggestionsList(GList, []);
    setSuggestionsData(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSuggestionsList = (
    genreList: GenreList[],
    parent: GenreList[]
  ) => {
    const genres: GenreList[] = [];
    genreList.forEach((g) => {
      const parentList: GenreList[] = [];
      let parentStr = "";
      parent.forEach((p) => {
        parentStr += `${p.genre}/`;
        parentList.push({
          id: p.id,
          genre: p.genre,
        });
      });
      genres.push({
        id: g.id,
        genre: g.genre,
        hierarchy: `${parentStr}${g.genre}`,
        parents: parentList,
      });
      if (g.subGenre) {
        const result = createSuggestionsList(g.subGenre, [
          ...parent,
          { id: g.id, genre: g.genre },
        ]);
        if (result) {
          genres.push(...result);
        }
      }
    });
    return genres;
  };

  const listIncludes = (item: GenreList, list: GenreList[]) => {
    return list.filter((e) => e.id === item.id).length > 0;
  };

  const onChange = (event: any, { newValue }: { newValue: string }) => {
    setValue(newValue);
  };
  const getSuggestions = (value: string): GenreList[] => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const suggestions: GenreList[] | boolean =
      suggestionsData &&
      suggestionsData.length > 0 &&
      suggestionsData
        .filter((data) => {
          if (
            data?.genre?.toLowerCase().includes(inputValue) &&
            !listIncludes(data, selectedList)
          ) {
            return true;
          }
          return false;
        })
        .slice(0, 5);

    return inputLength === 0
      ? []
      : suggestions && suggestions?.length
      ? suggestions
      : [];
  };

  const getSuggestionValue = (suggestion: GenreList) =>
    suggestion.hierarchy ? suggestion.hierarchy : suggestion.genre;

  const onSuggestionsFetchRequested = ({ value, reason }: any) => {
    setSuggestions(getSuggestions(value));
    if (reason === "suggestion-selected") {
      setSuggestions([]);
    }
  };
  const onSuggestionSelected = (
    event: any,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any
  ) => {
    const newGenres: GenreList[] = [];
    // eslint-disable-next-line array-callback-return
    suggestion.parents?.map((par: GenreList) => {
      if (!listIncludes(par, selectedList)) {
        newGenres.push({ id: par.id, genre: par.genre });
      }
    });
    if (!listIncludes(suggestion, selectedList)) {
      newGenres.push({ id: suggestion.id, genre: suggestion.genre });
    }
    updateList([...selectedList, ...newGenres]);
    clearSuggestions();
  };

  const renderSuggestion = (suggestion: GenreList) => (
    <p className={classes.name}>
      {suggestion.hierarchy ? suggestion.hierarchy : suggestion.genre}
    </p>
  );

  const renderInputComponent = (inputProps: any) => (
    <div>
      <input {...inputProps} />
    </div>
  );

  const clearSuggestions = () => {
    setValue("");
    setSuggestions([]);
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }: any) => {
    return (
      <div {...containerProps}>
        <strong>{children}</strong>
      </div>
    );
  };

  const inputProps = {
    placeholder: "Enter Genre",
    value,
    onChange: onChange,
    onBlur: () => setSuggestions([]),
  };

  return (
    <div className={classes.outer}>
      <Autosuggest
        theme={{
          input: classes.input,
          inputFocused: classes.inputFocused,
          suggestionsContainer: classes.suggestionsContainer,
          suggestion: classes.suggestion,
          suggestionHighlighted: classes.suggestionHighlighted,
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        alwaysRenderSuggestions={true}
        highlightFirstSuggestion={true}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
      <div className={classes.search}>
        <i style={{ color: "#a494cd" }} className="fa fa-search"></i>
      </div>
      {value !== "" && (
        <div className={classes.close} onClick={clearSuggestions}>
          <i
            className="far fa-times-circle fa-lg"
            style={{ color: "#a494cd" }}
          ></i>
        </div>
      )}
    </div>
  );
};

const styles = (theme: Theme) => {
  const style: StyleRules = {
    outer: {
      position: "relative",
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
    },
    search: {
      position: "absolute",
      zIndex: 999,
      top: 16,
      left: 18,
    },
    close: {
      position: "absolute",
      zIndex: 1000000,
      top: 14,
      left: 280,
      fontSize: "14px",
      cursor: "pointer",
    },
    input: {
      zIndex: 1,
      top: 0,
      border: "none",
      borderRadius: "8px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: getColorByTheme(theme, allColors.light3, allColors.dark11),
      padding: "14px",
      paddingRight: "41px",
      paddingLeft: "40px",
      width: "320px",
    },
    inputFocused: {
      outlineStyle: "none",
    },
    suggestionsContainer: {
      position: "absolute",
      background: getColorByTheme(theme, allColors.light3, allColors.dark11),
      boxSizing: "border-box",
      borderRadius: "8px",
      width: "320px",
      zIndex: 1000,
    },
    suggestion: {
      padding: "10px 150px 10px 18px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      listStyleType: "none",
      marginLeft: "-40px",
      cursor: "pointer",
    },
    suggestionHighlighted: {
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: "rgba(137,65,255, 0.3)",
      borderRadius: "8px",
      mixBlendMode: "normal",
    },
    name: {
      margin: "auto",
    },
  };
  return style;
};

const useStyles = makeStyles(styles);
