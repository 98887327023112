import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Hidden from "@mui/material/Hidden";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { toAbsoluteUrl } from "@src/_metronic/_helpers";
import { ButtonPill } from "@modules/Atoms/Buttons";
import { CreatorPost } from "@Models/Creators";

export const CreatorLineChart = ({
  creatorsData,
  selectedCreator,
}: {
  creatorsData: CreatorPost[];
  selectedCreator: string;
}) => {
  const [chartType, setChartType] = useState("views");
  const theme = useTheme();
  const sx = styles(useTheme());
  const classes = useStyles(theme);
  const renderTooltip = (props: any) => {
    const { payload } = props;
    return (
      <table>
        {payload.map((entry: any, index: number) => (
          <tbody key={index}>
            <tr>
              <td>Views Count</td>
              <td>
                {entry.payload.viewsCount
                  ? entry.payload.viewsCount.toLocaleString()
                  : 0}
              </td>
            </tr>
            <tr>
              <td>Likes Count</td>
              <td>
                {entry.payload.likesCount
                  ? entry.payload.likesCount.toLocaleString()
                  : 0}
              </td>
            </tr>
            <tr>
              <td style={{ paddingRight: "14px" }}>Comments Count</td>
              <td>
                {entry.payload.commentsCount
                  ? entry.payload.commentsCount.toLocaleString()
                  : 0}
              </td>
            </tr>
            <tr>
              <td>Shares Count</td>
              <td>
                {entry.payload.sharesCount
                  ? entry.payload.sharesCount.toLocaleString()
                  : 0}
              </td>
            </tr>
            <tr>
              <td>Engagement Rate</td>
              <td>
                {entry.payload.engagementRate
                  ? entry.payload.engagementRate.toLocaleString()
                  : 0}
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    );
  };
  const [modifiedCreatorData, setModifiedCreatorData] = useState(creatorsData);
  const filterCreatorData: any = () => {
    if (!!creatorsData && creatorsData.length > 0) {
      return modifiedCreatorData.map((item) => {
        const modifedpost = {
          ...item,
          likesCount: !!item.likesCount ? item.likesCount : 0,
          commentsCount: !!item.commentsCount ? item.commentsCount : 0,
          sharesCount: !!item.sharesCount ? item.sharesCount : 0,
          viewsCount: !!item.viewsCount ? item.viewsCount : 0,
          engagementRate: !!item.engagementRate
            ? parseInt(item.engagementRate)
            : 0,
        };
        return modifedpost;
      });
    }
  };
  useEffect(() => {
    if (!!creatorsData) {
      const arr = filterCreatorData();
      setModifiedCreatorData(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorsData]);
  return (
    <div>
      {creatorsData && creatorsData.length > 0 && (
        <Hidden>
          <ButtonPill
            onClick={() => setChartType("views")}
            sx={chartType === "views" ? sx.buttonSelected : sx.button}
          >
            Views Count
          </ButtonPill>
          <ButtonPill
            onClick={() => setChartType("likes")}
            sx={chartType === "likes" ? sx.buttonSelected : sx.button}
          >
            Likes Count
          </ButtonPill>
          <ButtonPill
            onClick={() => setChartType("comments")}
            sx={chartType === "comments" ? sx.buttonSelected : sx.button}
          >
            Comments Count
          </ButtonPill>
          <ButtonPill
            onClick={() => setChartType("shares")}
            sx={chartType === "shares" ? sx.buttonSelected : sx.button}
          >
            Shares Count
          </ButtonPill>
          <ButtonPill
            onClick={() => setChartType("engagementRate")}
            sx={chartType === "engagementRate" ? sx.buttonSelected : sx.button}
          >
            Engagement Rate
          </ButtonPill>
          <Box
            className={classes.chartBox}
            display="flex"
            style={{ marginTop: "63px", paddingBottom: "50px" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <ResponsiveContainer width="100%" aspect={4}>
              <LineChart
                className={classes.chartBox}
                width={900}
                height={250}
                data={modifiedCreatorData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="name"
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={50}
                />
                <YAxis />
                <Tooltip
                  cursor={{
                    stroke: "#aa8fda",
                    strokeWidth: 2,
                  }}
                  wrapperStyle={{
                    backgroundColor: "rgba(51, 47, 103, 0.443218)",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "13px",
                  }}
                  formatter={function (value: any) {
                    return `${value}`;
                  }}
                  labelFormatter={function (value) {
                    return `${value}`;
                  }}
                  content={renderTooltip}
                />
                {chartType === "views" && (
                  <Line
                    type="monotone"
                    stroke={"#7026FF"}
                    dataKey="viewsCount"
                    dot={false}
                    activeDot={{
                      stroke: "#800080",
                    }}
                  />
                )}
                {chartType === "likes" && (
                  <Line
                    type="monotone"
                    dataKey="likesCount"
                    stroke="#7026FF"
                    dot={false}
                  />
                )}
                {chartType === "comments" && (
                  <Line
                    type="monotone"
                    dataKey="commentsCount"
                    stroke="#7026FF"
                    dot={false}
                  />
                )}
                {chartType === "shares" && (
                  <Line
                    type="monotone"
                    dataKey="sharesCount"
                    stroke="#7026FF"
                    dot={false}
                  />
                )}
                {chartType === "engagementRate" && (
                  <Line
                    type="monotone"
                    dataKey="engagementRate"
                    stroke="#7026FF"
                    dot={false}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Hidden>
      )}
      {selectedCreator && creatorsData && creatorsData.length === 0 && (
        <Hidden>
          <Box>
            <img
              src={toAbsoluteUrl("/media/misc/GraphEmptyState.svg")}
              className={classes.emptyState}
              alt="alt"
            />
            <p className={classes.emptyStateText}>
              No data found for this username
            </p>
          </Box>
        </Hidden>
      )}
      {!selectedCreator && (
        <Hidden>
          <Box>
            <img
              src={toAbsoluteUrl("/media/misc/GraphEmptyState.svg")}
              className={classes.emptyState}
              alt="alt"
            />
            <p className={classes.emptyStateText}>Search for user data.</p>
          </Box>
        </Hidden>
      )}
    </div>
  );
};

const styles = (theme: any) => {
  return {
    buttonOuter: {
      background:
        "linear-gradient(96.11deg, #F648FB -11.18%, #A943FE 47.9%, #8941FF 98.68%, #7026FF 98.69%)",
      padding: "1px",
      margin: "6px",
      borderRadius: "9px",
    },
    buttonInner: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark1),
      borderRadius: "9px",
      padding: "12px 14px 9px 14px ",
    },
    legend: {
      padding: "6px",
      position: "relative",
      top: "1px",
      height: "6px",
      borderRadius: "6px",
      marginLeft: "5px",
    },
    viewLegend: {
      background:
        "linear-gradient(135deg, #7026FF 0%, #5000E3 19.24%, #7026FF 68.64%, #8941FF 81.77%, #8941FF 100%)",
    },
    likeLegend: {
      background:
        "linear-gradient(28deg, rgba(193, 99, 100, 0.0001) -14.35%, rgba(193, 108, 103, 0.558792) -8.46%, #C17168 -3.9%, #C1756A 0.33%, #C1DE8B 84.43%, #C1E98F 88.58%, rgba(193, 239, 145, 0.0925328) 95.42%, rgba(193, 247, 147, 0.0001) 103.76%)",
    },
    commentsLegend: {
      background:
        "linear-gradient(47.83deg, rgba(69, 175, 238, 0.0001) 0%, rgba(67, 177, 236, 0.615533) 3.59%, rgba(65, 179, 233, 0.767526) 7.69%, #3EB7E5 13.96%, #23DBBD 71.42%, rgba(30, 224, 183, 0.852977) 80.44%, rgba(28, 228, 179, 0.768733) 85.61%, rgba(27, 229, 177, 0.144475) 88.61%, rgba(23, 234, 172, 0.0001) 95.53%)",
    },
    tooltipWrap: {
      border: "1.8px solid",
      backgroundColor: "rgba(32, 30, 66, 0.5066)",
    },
    button: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark4
      ),
      borderRadius: "100px",
      boxShadow: getColorByTheme(theme, theme.shadows[1], "none"),
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "10px 25px 6px 25px",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      marginRight: "10px",
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light1,
          allColors.dark4
        ),
      },
    },
    buttonSelected: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light5,
        allColors.dark11
      ),
      borderRadius: "100px",
      boxShadow: getColorByTheme(theme, theme.shadows[1], "none"),
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "10px 25px 6px 25px",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      marginRight: "10px",
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light4,
          allColors.dark4
        ),
      },
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  chartBox: {
    width: "100%",
  },
  emptyStateText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    marginTop: "23px",
    marginBottom: "20px",
  },
  emptyState: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      // height: '221px',
    },
  },
}));
