import { gql } from "@apollo/client";

const GET_CREATOR_LIST = gql`
  query onSearchCreators($legalName: String, $pageNumber: Int!) {
    searchCreators(legalName: $legalName, pageNumber: $pageNumber) {
      creatorData {
        legalName
        tiktokUsername
        creatorId
      }
    }
  }
`;

const GET_CREATOR_CHART_DATA = gql`
  query onGetCreatorsChartData($creatorId: String!) {
    getCreatorsChartData(creatorId: $creatorId) {
      post {
        id
        name
        creatorId
        commentsCount
        likesCount
        viewsCount
        sharesCount
        date
        tiktokUsername
        postSocialDataId
        engagementRate
      }
      avgViewsCount
      avgCommentsCount
      avgLikesCount
      avgSharesCount
      avgEngagementRateCount
    }
  }
`;

const GET_CREATOR_DATA = gql`
  query onGetCreatorsProfile($creatorId: String) {
    getCreatorsDetails(creatorId: $creatorId) {
      legalName
      tiktokUsername
      creatorId
    }
  }
`;

const GET_BRANDS_CREATORS_LIST = gql`
  query onGetBrandsCreatorsList($tiktokBrandDeals: Boolean) {
    getBrandsCreatorsList(tiktokBrandDeals: $tiktokBrandDeals) {
      status
      message
      creatorList {
        _id
        tiktokUsername
        tiktokUserId
        tiktokProfileLink
        tiktokFollowers
        avgViewPerVideo
        tiktokBrands
        tiktokSponsoredPosts
        tiktokTotalBrandPosts
      }
    }
  }
`;

const GET_SPONSORED_CREATOR_CHART_DATA = gql`
  query onGetTopCreatorsChart($firstMonth: String, $lastMonth: String) {
    getTopCreatorsChart(firstMonth: $firstMonth, lastMonth: $lastMonth) {
      status
      message
      chartValue {
        dateId
        totalCpm
        totalViews
        accounts
        sponsoredPosts
      }
      creatorTotal {
        totalPaidCreators
        totalEarning
        totalViewsPurchased
        totalSponsoredPost
      }
    }
  }
`;

const GET_INDIVIDUAL_CREATOR_CARD_DATA = gql`
  query GetCreatorsDetails($creatorId: String, $tiktokUsername: String) {
    getCreatorsDetails(creatorId: $creatorId, tiktokUsername: $tiktokUsername) {
      tiktokUsername
      tiktokProfileLink
      tiktokFollowers
      tiktokSponsoredPosts
      tiktokBrands
      tiktokProfileImage
      totalEarned
      tiktokTotalBrandPostViews
      isNetworkCreator
      tiktokAvgViews
      tiktokBrandPostEngagementRate
      tiktokOrganicPostEngagementRate
      tiktokBlendedEngagement
      tiktokAvgOrganicPostViews
      tiktokAvgBrandPostViews
      categoryList
      phoneCountryCode
      phoneNumberSecondary
      emailSecondary
      contacts {
        type
        value
      }
    }
  }
`;

const GET_INDIVIDUAL_BRANDS_CREATOR_TAB = gql`
  query onGetIndividualBrandPageCreatorTab($input: BrandCreatorsInput!) {
    getIndividualBrandPageCreatorTab(input: $input) {
      status
      message
      postCount
      postList {
        _id
        totalPosts
        totalEarning
        totalViews
        avgViews
        creatorData {
          tiktokUsername
          creatorId
          tiktokUserId
          tiktokProfileLink
          tiktokFollowers
          tiktokAvgViews
          isNetworkCreator
          tiktokAvgViews
          tiktokProfileImage
          tiktokAvgBrandPostViews
        }
      }
    }
  }
`;

const GET_INDIVIDUAL_BRANDS_POST_TAB = gql`
  query getBrandIndividualPagePostTab($input: PostTabInput!) {
    getBrandIndividualPagePostTab(input: $input) {
      status
      message
      postCount
      post {
        commentCount
        createdTime
        creatorId
        description
        videoUrl
        likeCount
        playCount
        postSocialDataId
        shareCount
        tiktokUserId
        tiktokUsername
        viewCount
        isSponsored
        creatorEarned
        categoryList
        creatorData {
          tiktokUsername
          isNetworkCreator
          tiktokProfileLink
          tiktokProfileImage
        }
      }
    }
  }
`;

const GET_INDIVIDUAL_CREATOR_POST_TAB = gql`
  query ongetIndividualCreators($input: CreatorsBrandListInput!) {
    getIndividualCreators(input: $input) {
      totalRecords
      post {
        createdTime
        description
        videoUrl
        viewCount
        likeCount
        shareCount
        commentCount
        brandName
        brandLink
        brandImage
        totalEarning
      }
    }
  }
`;

const SEARCH_SPONSORED_CREATORS = gql`
  query SearchCreators(
    $legalName: String
    $topCreator: Boolean
    $pageNumber: Int!
    $isNetworkCreator: Boolean
    $sortByTiktokUsername: Int
    $sortByTiktokProfileLink: Int
    $sortByTotalEarned: Int
    $sortByTiktokTotalBrandPostViews: Int
    $sortByTiktokTotalBrandPosts: Int
    $sortByTiktokFollowers: Int
    $sortByTiktokAvgViews: Int
    $sortByBlendedPostEngRate: Int
    $sortByOrganicPostEngRate: Int
    $sortByPaidPostEngRate: Int
    $sortByAvgPostFreq: Int
    $sortByAvgOrganicPostViews: Int
    $sortByAvgPaidPostViews: Int
    $sortByCategory: [String]
  ) {
    searchCreators(
      legalName: $legalName
      topCreator: $topCreator
      pageNumber: $pageNumber
      isNetworkCreator: $isNetworkCreator
      sortByTiktokUsername: $sortByTiktokUsername
      sortByTiktokProfileLink: $sortByTiktokProfileLink
      sortByTotalEarned: $sortByTotalEarned
      sortByTiktokTotalBrandPostViews: $sortByTiktokTotalBrandPostViews
      sortByTiktokTotalBrandPosts: $sortByTiktokTotalBrandPosts
      sortByTiktokFollowers: $sortByTiktokFollowers
      sortByTiktokAvgViews: $sortByTiktokAvgViews
      sortByBlendedPostEngRate: $sortByBlendedPostEngRate
      sortByOrganicPostEngRate: $sortByOrganicPostEngRate
      sortByPaidPostEngRate: $sortByPaidPostEngRate
      sortByAvgPostFreq: $sortByAvgPostFreq
      sortByAvgOrganicPostViews: $sortByAvgOrganicPostViews
      sortByAvgPaidPostViews: $sortByAvgPaidPostViews
      sortByCategory: $sortByCategory
    ) {
      success
      status
      totalRecords
      creatorData {
        legalName
        tiktokUsername
        tiktokProfileLink
        tiktokProfileImage
        totalEarned
        tiktokTotalBrandPostViews
        tiktokTotalBrandPosts
        tiktokBrands
        categoryList
        tiktokFollowers
        tiktokAvgViews
        isNetworkCreator
        tiktokBrandPostEngagementRate
        tiktokOrganicPostEngagementRate
        tiktokBlendedEngagement
        tiktokAvgOrganicPostViews
        tiktokAvgBrandPostViews
        tiktokAvgPostFrequency
        phoneNumberSecondary
        emailSecondary
      }
    }
  }
`;

export {
  GET_CREATOR_LIST,
  GET_CREATOR_CHART_DATA,
  GET_CREATOR_DATA,
  GET_BRANDS_CREATORS_LIST,
  GET_SPONSORED_CREATOR_CHART_DATA,
  GET_INDIVIDUAL_CREATOR_CARD_DATA,
  GET_INDIVIDUAL_BRANDS_CREATOR_TAB,
  GET_INDIVIDUAL_CREATOR_POST_TAB,
  GET_INDIVIDUAL_BRANDS_POST_TAB,
  SEARCH_SPONSORED_CREATORS,
};
