import { gql } from "@apollo/client";

const GET_CREATOR_CHART_DETAILS = gql`
  query GetCreatorsOverview($tiktokUsername: String) {
    getCreatorsOverview(tiktokUsername: $tiktokUsername) {
      status
      success
      message
      creatorHeaderData {
        totalEarningAcross
        totalViewsAcross
        totalPostsAcross
        avgViewsAcross
        totalBrandsAcross
      }
      chartDataValue {
        dateId
        totalEarnings
        totalSponsoredViews
        totalViews
        totalBrands
        sponsoredPosts
        totalPosts
      }
    }
  }
`;

export { GET_CREATOR_CHART_DETAILS };
