import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { formatNumber, getSorting } from "@modules/utils/utils";
import { GET_INDIVIDUAL_BRANDS_CREATOR_TAB } from "@src/graphql/queries/getCreatorList";
import BrandCreatorDetailsPresentational from "./BrandCreatorDetailsTable.Presentational";
import { SponsoredCreatorBrandTab } from "@Models/Creators";
import { totalCount } from "@src/constants";
import { formatString } from "@modules/Organisms/Formatter/Formatter";

const BrandCreatorDetailsTable = () => {
  const [creators, setCreators] = useState<SponsoredCreatorBrandTab[]>([]);
  const [order, setOrder] = useState<"asc" | "desc" | undefined>("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [totalPage, setTotalPage] = useState(30);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loaderPosition, setLoaderPosition] = useState<boolean>(false);
  const [pageRecord, setPageRecord] = useState<number>(1);
  const [pageDropDown, setPageDropDown] = useState<number>(1);
  const params: any = useParams();

  const [getCreatorList, { data: creatorsData, loading }] = useLazyQuery(
    GET_INDIVIDUAL_BRANDS_CREATOR_TAB
  );

  const headRows = [
    {
      id: "tiktokUsernameStickyColumn",
      label: "Creators",
      link: "tiktokProfileLink",
    },
    { id: "totalPosts", label: "Number of Posts" },
    { id: "totalViews", label: "Total Views" },
    { id: "follower", label: "Followers" },
    { id: "avgViews", label: `Avg Views For ${params.brand}` },
    {
      id: "tiktokAvgBrandPostViews",
      label: "Overall Sponsored Posts Avg Views",
      dontSort: true,
    },
  ];

  useEffect(() => {
    getCreatorList({
      variables: {
        input: {
          tiktokUserName: params.brand,
          pageNumber: page,
          sortByPostUsername: getSorting(
            "tiktokUsernameStickyColumn",
            order,
            orderBy
          ),
          sortByTotalPosts: getSorting("totalPosts", order, orderBy),
          sortByViewCount: getSorting("totalViews", order, orderBy),
          sortBytiktokFollowers: getSorting("follower", order, orderBy),
          sortByAvgViews: getSorting("avgView", order, orderBy),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page]);

  useEffect(() => {
    if (!creators || !creatorsData) {
      setOpen(true);
    }
  }, [creators, creatorsData]);

  useEffect(() => {
    setPage(1);
    setPageRecord(1);
    setPageDropDown(1);
  }, [order, orderBy]);

  useEffect(() => {
    if (
      creatorsData &&
      creatorsData?.getIndividualBrandPageCreatorTab?.postList
    ) {
      setCreators(
        formatBrandsList(
          creatorsData?.getIndividualBrandPageCreatorTab?.postList
        )
      );
      setTotalPage(creatorsData?.getIndividualBrandPageCreatorTab?.postCount);
    }
  }, [creatorsData]);

  if (!creatorsData) {
  }

  const handleRequestSort3 = (property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      creatorsData?.getIndividualBrandPageCreatorTab &&
      count !== creatorsData?.getIndividualBrandPageCreatorTab?.postCount
    ) {
      setCount(
        creatorsData?.getIndividualBrandPageCreatorTab
          ? Math.ceil(
              creatorsData?.getIndividualBrandPageCreatorTab?.postCount /
                totalCount
            )
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorsData]);

  useEffect(() => {
    setLoaderPosition(false);
  }, [order, orderBy]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPageDropDown(parseInt(newValue));
      setLoaderPosition(true);
      setPageRecord(parseInt(newValue));
    }
  };

  let options = [];

  useEffect(() => {
    if (pageDropDown !== null) {
      setPage(pageDropDown);
    }
  }, [pageDropDown]);

  for (let pageValue = 1; pageValue <= (count === 0 ? 1 : count); pageValue++) {
    options.push(pageValue);
  }

  const onKeyDown = (ev: any) => {
    if (
      ev.key === "Enter" &&
      ev.target.value <= options.length &&
      ev.target.value > 0
    ) {
      if (ev.target.value && pageDropDown !== null) {
        setPage(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
    }
  };

  const position: any = loaderPosition
    ? { top: "10%", left: "30vw" }
    : { bottom: "10%", left: "30vw" };

  const endNumber = pageRecord * totalCount;
  const startRecordNumber = endNumber - (totalCount - 1);
  const endRecordNumber =
    endNumber > totalPage
      ? startRecordNumber + (totalPage - startRecordNumber)
      : endNumber;
  return (
    <div>
      <BrandCreatorDetailsPresentational
        creators={creators}
        headRows={headRows}
        handleRequestSort3={handleRequestSort3}
        order={order}
        orderBy={orderBy}
        count={count}
        page={page}
        setPage={setPage}
        open={open}
        loading={loading}
        position={position}
        setLoaderPosition={setLoaderPosition}
        setPageRecord={setPageRecord}
        startRecordNumber={startRecordNumber}
        endRecordNumber={endRecordNumber}
        totalPage={totalPage}
        handleChange={handleChange}
        options={options}
        pageDropDown={pageDropDown}
        setPageDropDown={setPageDropDown}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default BrandCreatorDetailsTable;

const formatBrandsList = (BrandData: SponsoredCreatorBrandTab[]) => {
  return BrandData.map((v) => {
    return {
      ...v,
      tiktokUsernameStickyColumn: formatString(v._id),
      totalPosts: formatNumber(v.totalPosts),
      totalViews: formatNumber(v.totalViews),
      avgViews: formatNumber(v.avgViews),
    };
  });
};
