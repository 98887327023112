import React, { FunctionComponent } from "react";
import SponsoredCreatorsTable from "@modules/Brands/Creator/SponsoredCreatorsTable";
import { SponsoredCreatorProps } from "./SponsoredCreator.types";

const SponsoredCreatorPresentational: FunctionComponent<SponsoredCreatorProps> = ({
  chartData,
  chartError,
  chartLoading,
  chartTotal,
  dateState,
  datePickerHandler,
}) => {
  return (
    <div>
      {/* PLS DO NOT DELETE */}
      {/* <CreatorListHeader />
      <DateRangePicker dateState={dateState} setDateState={datePickerHandler} />
      <div style={{ width: "100%" }}>
        {chartLoading ? (
          <FallBackLoading classname="mr-4" />
        ) : (
          <>
            {chartData && chartData.length > 0 ? (
              <ChartCard
                header={"Views by Month"}
                data={chartData || []}
                xkey="dateId"
                ykey="totalViews"
                xLabel="Month"
                yLabel="Total Views"
                subHeader={`Total Views: ${chartTotal?.totalViewsPurchased.toLocaleString()}`}
              />
            ) : (
              <FallBackChart />
            )}
          </>
        )}
        {chartLoading ? (
          <FallBackLoading classname="mr-4" />
        ) : (
          <>
            {chartData && chartData.length > 0 ? (
              <BarChart
                header={"Creators by Month"}
                data={chartData || []}
                xkey="dateId"
                ykey="accounts"
                xLabel="Month"
                yLabel=" Total Sponsored Creators"
                subHeader={`Total Creators: ${chartTotal?.totalPaidCreators.toLocaleString()}`}
              />
            ) : (
              <FallBackChart />
            )}
          </>
        )}
        {chartLoading ? (
          <FallBackLoading classname="mr-4" />
        ) : (
          <>
            {chartData && chartData.length > 0 ? (
              <BarChart
                header={"Posts by Month"}
                data={chartData || []}
                xkey="dateId"
                ykey="sponsoredPosts"
                xLabel="Month"
                yLabel="Sponsored Posts"
                subHeader={`Total Posts: ${chartTotal?.totalSponsoredPost.toLocaleString()}`}
              />
            ) : (
              <FallBackChart />
            )}
          </>
        )}
      </div> */}
      <SponsoredCreatorsTable />
    </div>
  );
};

export default SponsoredCreatorPresentational;
