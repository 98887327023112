import { monthNames } from "@src/constants";

export const DataFormatter = (num: any) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "QD" },
    { value: 1e15, symbol: "QT" },
    { value: 1e18, symbol: "ST" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function(item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol
    : "0";
};
export const DataFormatterYAxis = (num: any) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "QD" },
    { value: 1e15, symbol: "QT" },
    { value: 1e18, symbol: "ST" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function(item) {
      return num >= item.value;
    });
  return item
    ? "$" + (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol
    : "0";
};

export const dateFormatter = (date: string) => {
  try {
    if (date.length > 0 && date.split("/").length > 1) {
      const year = date.split("/")[0];
      const monthIndex = parseInt(date.split("/")[1]);
      const month = monthNames[monthIndex - 1];
      if (month) {
        return `${month} ${year}`;
      } else {
        return date;
      }
    } else {
      return date;
    }
  } catch (error) {
    console.log("date formatting error");
    return date;
  }
};
