import React, { useState, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import SponsoredCreatorsTablePresentational from "./SponsoredCreatorsTable.Presentational";
import { SponsoredCreator, CreatorSearchData } from "@Models/Creators";
import { GET_SPONSORED_CREATORS_TABLE_DATA } from "@src/graphql/queries/getTableData";
import { formatNumber, getSorting } from "@modules/utils/utils";
import {
  formatNumberToPercentage,
  formatPhoneNumber,
  formatString,
} from "@modules/Organisms/Formatter/Formatter";
import { SEARCH_SPONSORED_CREATORS } from "@src/graphql/queries/getCreatorList";
import { GET_DOWNLOADED_DATA } from "@src/graphql/queries/download";
import { totalCount } from "@src/constants";

const SponsoredCreatorsTable = () => {
  const [creators, setCreators] = useState<SponsoredCreator[]>([]);
  const [order, setOrder] = useState<"asc" | "desc" | undefined>("desc");
  const [orderBy, setOrderBy] = useState("tiktokTotalBrandPosts");
  const [page, setPage] = useState<number>(1);
  const [pageFilter, setPageFilter] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [filterPageCount, setFilterPageCount] = useState<number>(1);
  const [isNetworkCreator, setIsNetworkCreator] = useState(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loaderPosition, setLoaderPosition] = useState<boolean>(false);
  const [clear, setClear] = useState<Boolean>(false);
  const [pageRecord, setPageRecord] = useState<number>(1);
  const [link, setLink] = useState("");
  const [pageDropDown, setPageDropDown] = useState<number>(1);
  const tableRef = useRef<any>(null);
  const [getBrandsCreatorsList, { data: creatorsData, loading }] = useLazyQuery(
    GET_SPONSORED_CREATORS_TABLE_DATA
  );

  const [correctedCategories, setCorrectedCategories] = useState([]);
  const selecteCorrectedCategory = (_id: string, value: any) => {
    setCorrectedCategories(value);
  };

  const [suggestionValue, setSuggestionValue] = useState<string>("");
  const [updateTableData, setupdateTableData] = useState<any[]>([]);
  const [data, setData] = useState<CreatorSearchData[]>([]);
  const [onenter, setOnEnter] = useState<boolean>(false);
  const [downloadload, setDownloadload] = useState(false);
  const [downloadClick, setDownloadClick] = useState(false);
  const [onSelect, setOnSelect] = useState(false);
  const [onOptionSelect, setOnOptionSelect] = useState(false);
  const [totalP, setTotalP] = useState(1);
  const [spage, setSPage] = useState(1);
  const [ePage, setEPage] = useState(1);
  const [getCreatorsList, { data: creatorsList, loading: filterLoading }] =
    useLazyQuery(SEARCH_SPONSORED_CREATORS);

  const [
    getDownloadBrandsCreatorsList,
    { data: downloadedData, loading: downloadLoading },
  ] = useLazyQuery(GET_DOWNLOADED_DATA);

  const headRows = [
    {
      id: "tiktokUsernameStickyColumn",
      label: "Creator",
      link: "tiktokProfileLink",
    },
    { id: "tiktokTotalBrandPostViews", label: "Sponsored Views" },
    { id: "tiktokTotalBrandPosts", label: "Total Paid Posts" },
    { id: "tiktokBrands", label: "Brands", dontSort: true },
    { id: "categoryList", label: "Categories", dontSort: true },
    { id: "tiktokFollowers", label: "Followers" },
    { id: "tiktokAvgViews", label: "Average Views - Blended" },
    {
      id: "tiktokAvgBrandPostViews",
      label: "Average views - Paid Posts",
    },
    {
      id: "tiktokAvgOrganicPostViews",
      label: "Average Views - Organic Posts",
    },
    {
      id: "tiktokAvgPostFrequency",
      label: "Average Posting Frequency",
    },
    {
      id: "tiktokBrandPostEngagementRate",
      label: "Paid Post Engagement Rate",
    },
    {
      id: "tiktokOrganicPostEngagementRate",
      label: "Organic Post Engagement Rate",
    },
    {
      id: "tiktokBlendedEngagement",
      label: "Blended Engagement Rate",
    },
    { id: "phoneNumberSecondary", label: "Phone", dontSort: true },
    { id: "emailSecondary", label: "Email", dontSort: true },
  ];

  const fetchBrandCreatorsList = () => {
    getBrandsCreatorsList({
      variables: {
        input: {
          pageNumber: page,
          sortByTiktokUsername: getSorting(
            "tiktokUsernameStickyColumn",
            order,
            orderBy
          ),
          sortByTiktokTotalBrandPostViews: getSorting(
            "tiktokTotalBrandPostViews",
            order,
            orderBy
          ),
          sortByTiktokTotalBrandPosts: getSorting(
            "tiktokTotalBrandPosts",
            order,
            orderBy
          ),
          sortByTiktokFollowers: getSorting("tiktokFollowers", order, orderBy),
          sortByTiktokAvgViews: getSorting("tiktokAvgViews", order, orderBy),
          sortByBlendedPostEngRate: getSorting(
            "tiktokBlendedEngagement",
            order,
            orderBy
          ),
          sortByOrganicPostEngRate: getSorting(
            "tiktokOrganicPostEngagementRate",
            order,
            orderBy
          ),
          sortByPaidPostEngRate: getSorting(
            "tiktokBrandPostEngagementRate",
            order,
            orderBy
          ),
          sortByAvgPostFreq: getSorting(
            "tiktokAvgPostFrequency",
            order,
            orderBy
          ),
          sortByAvgOrganicPostViews: getSorting(
            "tiktokAvgOrganicPostViews",
            order,
            orderBy
          ),
          sortByAvgPaidPostViews: getSorting(
            "tiktokAvgBrandPostViews",
            order,
            orderBy
          ),
          sortByCategory: correctedCategories,
          isNetworkCreator: isNetworkCreator ? true : null,
        },
      },
    });
  };

  useEffect(() => {
    getDownloadBrandsCreatorsList({
      variables: {
        list: "Creator",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clear === true) {
      tableRef.current?.scrollIntoView({ block: "start" });
    }
  }, [clear, creators]);

  useEffect(() => {
    if (!creators || !creatorsData || !creatorsList) {
      setOpen(true);
    }
  }, [creators, creatorsData, creatorsList]);

  useEffect(() => {
    if (suggestionValue === "") {
      fetchBrandCreatorsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, correctedCategories, clear, isNetworkCreator]);

  useEffect(() => {
    if (creatorsData && creatorsData.getBrandsCreatorsList) {
      setCreators(
        formatCreatorList(creatorsData.getBrandsCreatorsList.creatorList)
      );
    }
  }, [creatorsData, loading]);

  useEffect(() => {
    setPage(1);
    setPageRecord(1);
    setPageDropDown(1);
  }, [
    order,
    orderBy,
    correctedCategories,
    isNetworkCreator,
    onenter,
    suggestionValue,
  ]);

  const handleRequestSort3 = (property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      creatorsData?.getBrandsCreatorsList &&
      count !== creatorsData?.getBrandsCreatorsList?.totalRecords
    ) {
      setCount(
        creatorsData?.getBrandsCreatorsList ? Math.ceil(totalP / totalCount) : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalP]);

  useEffect(() => {
    if (
      creatorsList?.searchCreators &&
      filterPageCount !== creatorsList?.searchCreators?.totalRecords
    ) {
      setFilterPageCount(
        creatorsList?.searchCreators ? Math.ceil(totalP / totalCount) : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalP]);

  const OnEnterList = (code: any) => {
    if (code?.keyCode === 13) {
      setOnEnter(true);
      setCreators(formatCreatorList(updateTableData));
    }
  };
  const fetchQuery = (value?: any) => {
    let creatorName = value ? value.split("(") : suggestionValue.split("(");
    let suggest = "";
    if (creatorName.length > 1) {
      suggest = creatorName[1].slice(0, -1);
    } else {
      suggest = creatorName[0];
    }
    getCreatorsList({
      variables: {
        legalName: suggest,
        topCreator: true,
        pageNumber: pageFilter,
        sortByTiktokUsername: getSorting(
          "tiktokUsernameStickyColumn",
          order,
          orderBy
        ),
        sortByTiktokTotalBrandPostViews: getSorting(
          "tiktokTotalBrandPostViews",
          order,
          orderBy
        ),
        sortByTiktokTotalBrandPosts: getSorting(
          "tiktokTotalBrandPosts",
          order,
          orderBy
        ),
        sortByTiktokFollowers: getSorting("tiktokFollowers", order, orderBy),
        sortByTiktokAvgViews: getSorting("tiktokAvgViews", order, orderBy),
        sortByBlendedPostEngRate: getSorting(
          "tiktokBlendedEngagement",
          order,
          orderBy
        ),
        sortByOrganicPostEngRate: getSorting(
          "tiktokOrganicPostEngagementRate",
          order,
          orderBy
        ),
        sortByPaidPostEngRate: getSorting(
          "tiktokBrandPostEngagementRate",
          order,
          orderBy
        ),
        sortByAvgPostFreq: getSorting("tiktokAvgPostFrequency", order, orderBy),
        sortByAvgOrganicPostViews: getSorting(
          "tiktokAvgOrganicPostViews",
          order,
          orderBy
        ),
        sortByAvgPaidPostViews: getSorting(
          "tiktokAvgBrandPostViews",
          order,
          orderBy
        ),
        isNetworkCreator: isNetworkCreator === true ? true : null,
        sortByCategory: correctedCategories,
      },
    });
  };

  const searchCreatorsData: CreatorSearchData[] =
    creatorsList?.searchCreators?.creatorData;

  useEffect(() => {
    if (searchCreatorsData) {
      setData(searchCreatorsData);
      if (onenter) {
        setCreators(formatCreatorList(searchCreatorsData));
      }
      setupdateTableData(searchCreatorsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorsList, pageFilter]);

  useEffect(() => {
    if (downloadedData && downloadedData.getDownloadBrandsCreatorsList) {
      setLink(downloadedData.getDownloadBrandsCreatorsList.link);
    }
  }, [downloadedData]);

  const onSuggestionSelected = (event: any, { suggestion }: any) => {
    if (event?.keyCode !== 13) {
      setOnSelect(true);
      setOnOptionSelect(true);
      setPageFilter(1);
      setCreators(formatCreatorList([suggestion]));
      setTotalP([suggestion].length);
      setSPage(1);
      setEPage(1);
    }
  };
  useEffect(() => {
    setOnSelect(false);
    if (
      data?.length > 1 ||
      suggestionValue.substring(0, suggestionValue.indexOf("(")) !==
        data[0]?.legalName
    ) {
      setOnOptionSelect(false);
    }

    if (
      data?.length === 1 &&
      suggestionValue.substring(0, suggestionValue.indexOf("(") - 1) ===
        data[0]?.legalName
    ) {
      setOnOptionSelect(true);
    }
  }, [data, suggestionValue]);

  useEffect(() => {
    if (suggestionValue === "") {
      setOnEnter(false);
      setPageFilter(1);
      if (creatorsData && creatorsData.getBrandsCreatorsList) {
        setCreators(
          formatCreatorList(creatorsData.getBrandsCreatorsList.creatorList)
        );
      }
    }
    setPageFilter(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionValue]);

  useEffect(() => {
    setLoaderPosition(false);
  }, [order, orderBy, correctedCategories, isNetworkCreator, suggestionValue]);

  useEffect(() => {
    if (suggestionValue !== "") {
      fetchQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, pageFilter]);

  const csvDownload = () => {
    setDownloadClick(true);
  };
  useEffect(() => {
    if (downloadClick) {
      setDownloadload(downloadLoading);
    }
    if (downloadClick && !downloadLoading && link) {
      const url = link;
      window.open(url, "_blank");
      setDownloadClick(false);
      setDownloadload(false);
    }
  }, [downloadLoading, downloadClick, link]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPageDropDown(parseInt(newValue));
      setLoaderPosition(true);
      setPageRecord(parseInt(newValue));
    }
  };

  let options = [];

  useEffect(() => {
    if (suggestionValue === "" && pageDropDown !== null) {
      setPage(pageDropDown);
    }
    if (suggestionValue !== "" && pageDropDown !== null) {
      setPageFilter(pageDropDown);
    }
  }, [pageDropDown, suggestionValue]);
  useEffect(() => {
    let endNumber = pageRecord * totalCount;
    let startRecordNumber = endNumber - (totalCount - 1);
    let total = 1;
    if (
      suggestionValue === "" &&
      creatorsData?.getBrandsCreatorsList?.totalRecords
    ) {
      total = creatorsData?.getBrandsCreatorsList?.totalRecords;
    } else if (
      suggestionValue !== "" &&
      creatorsList?.searchCreators?.totalRecords
    ) {
      total = creatorsList?.searchCreators?.totalRecords;
    }

    if (!onSelect) {
      setTotalP(total);
      setSPage(endNumber - (totalCount - 1));
      setEPage(
        endNumber > total
          ? startRecordNumber + (total - startRecordNumber)
          : endNumber
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creators, creatorsData, creatorsList, pageRecord, suggestionValue]);

  for (
    let pageValue = 1;
    pageValue <=
    (suggestionValue === ""
      ? count === 0
        ? 1
        : count
      : filterPageCount === 0
      ? 1
      : filterPageCount);
    pageValue++
  ) {
    options.push(pageValue);
  }

  const onKeyDown = (ev: any) => {
    if (
      ev.key === "Enter" &&
      ev.target.value <= options.length &&
      ev.target.value > 0
    ) {
      if (ev.target.value && suggestionValue === "" && pageDropDown !== null) {
        setPage(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
      if (ev.target.value && suggestionValue !== "" && pageDropDown !== null) {
        setPageFilter(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
    }
  };

  const position: any = loaderPosition ? { top: "10%" } : { bottom: "10%" };

  return (
    <div>
      <SponsoredCreatorsTablePresentational
        tableData={creatorsData}
        creators={creators}
        headRows={headRows}
        handleRequestSort3={handleRequestSort3}
        order={order}
        orderBy={orderBy}
        count={count}
        filterPageCount={filterPageCount}
        page={page}
        setPage={setPage}
        correctedCategories={correctedCategories}
        selectCorrectedCategory={selecteCorrectedCategory}
        isNetworkCreator={isNetworkCreator}
        setIsNetworkCreator={setIsNetworkCreator}
        value={suggestionValue}
        setValue={setSuggestionValue}
        loading={filterLoading}
        fetchSuggestion={fetchQuery}
        suggestions={data}
        setSuggestions={setData}
        array={creatorsList}
        onSuggestionSelected={onSuggestionSelected}
        onEnter={OnEnterList}
        placeholder="Enter Creator Username"
        setPageFilter={setPageFilter}
        pageFilter={pageFilter}
        open={open}
        tableLoader={loading}
        position={position}
        setLoaderPosition={setLoaderPosition}
        tableRef={tableRef}
        setClear={setClear}
        setPageRecord={setPageRecord}
        startRecordNumber={spage}
        endRecordNumber={ePage}
        totalPage={totalP}
        csvDownload={csvDownload}
        totalFilterRecord={totalP}
        downloadLoading={downloadload}
        handleChange={handleChange}
        options={options}
        pageDropDown={pageDropDown}
        setPageDropDown={setPageDropDown}
        onKeyDown={onKeyDown}
        onOptionSelect={onOptionSelect}
      />
    </div>
  );
};

export default SponsoredCreatorsTable;

const formatCreatorList = (
  creatorData: SponsoredCreator[]
): SponsoredCreator[] => {
  return creatorData.map((v) => {
    return {
      ...v,
      tiktokUsernameStickyColumn: formatString(v.tiktokUsername),
      tiktokTotalBrandPostViews: formatNumber(v.tiktokTotalBrandPostViews),
      tiktokTotalBrandPosts: formatNumber(v.tiktokTotalBrandPosts),
      tiktokFollowers: formatNumber(v.tiktokFollowers),
      tiktokAvgBrandPostViews: formatNumber(v.tiktokAvgBrandPostViews),
      tiktokAvgOrganicPostViews: formatNumber(v.tiktokAvgOrganicPostViews),
      tiktokAvgPostFrequency: formatNumber(v.tiktokAvgPostFrequency),
      tiktokAvgViews: formatNumber(v.tiktokAvgViews),
      tiktokBrandPostEngagementRate: formatNumberToPercentage(
        v.tiktokBrandPostEngagementRate
      ),
      tiktokOrganicPostEngagementRate: formatNumberToPercentage(
        v.tiktokOrganicPostEngagementRate
      ),
      tiktokBlendedEngagement: formatNumberToPercentage(
        v.tiktokBlendedEngagement
      ),
      phoneNumberSecondary: formatPhoneNumber(v.phoneNumberSecondary),
      emailSecondary: formatString(v.emailSecondary),
    };
  });
};
