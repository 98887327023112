export const IndividualBrandChartStyles = (theme: any) => {
  return {
    skeleton: {
      width: "90%",
      height: "30vh",
      borderRadius: "20px",
      margin: "1.5rem",
    },
  };
};
