import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useLazyQuery } from "@apollo/client";
import { GET_CATEGORIES_TABLE_DATA } from "@src/graphql/queries/getTableData";
import { formatNumber, getSorting } from "@modules/utils/utils";
import CategoriesTablePresentational from "./CategoriesTable.Presentational";
import { Categories } from "@Models/Categories";
import {
  formatString,
  formatToDollarValue,
} from "@modules/Organisms/Formatter/Formatter";
import { totalCount } from "@src/constants";

const CategoriesTable = () => {
  const [categories, setCategories] = useState<Categories[]>([]);
  const [order, setOrder] = useState<"asc" | "desc" | undefined>("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [totalPage, setTotalPage] = useState(30);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loaderPosition, setLoaderPosition] = useState<boolean>(false);
  const [pageRecord, setPageRecord] = useState<number>(1);
  const [pageDropDown, setPageDropDown] = useState<number>(1);
  const [getCategoriesList, { data: CategoriesData, loading }] = useLazyQuery(
    GET_CATEGORIES_TABLE_DATA
  );

  const headRows = [
    {
      id: "categoryNameStickyColumn",
      label: "Category Name",
      link: "tiktokProfileLink",
    },
    { id: "brandListLength", label: "Brands" },
    { id: "creatorListLength", label: "Creators" },
    { id: "totalSpent", label: "Total Spend" },
    { id: "totalViews", label: "Total Views" },
    { id: "totalPosts", label: "Total Posts" },
  ];

  useEffect(() => {
    getCategoriesList({
      variables: {
        input: {
          pageNumber: page,
          sortByCategoryName: getSorting(
            "categoryNameStickyColumn",
            order,
            orderBy
          ),
          sortByTotalBrands: getSorting("brandListLength", order, orderBy),
          sortByTotalCreators: getSorting("creatorListLength", order, orderBy),
          sortByTotalSpent: getSorting("totalSpent", order, orderBy),
          sortByTotalViews: getSorting("totalViews", order, orderBy),
          sortByTotalPosts: getSorting("totalPosts", order, orderBy),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page]);

  useEffect(() => {
    if (!categories || !CategoriesData) {
      setOpen(true);
    }
  }, [categories, CategoriesData]);

  useEffect(() => {
    setPage(1);
    setPageRecord(1);
    setPageDropDown(1);
  }, [order, orderBy]);

  useEffect(() => {
    if (CategoriesData && CategoriesData?.getCategoriesList?.categoryData) {
      setCategories(
        formatCategoryList(CategoriesData?.getCategoriesList?.categoryData)
      );
      setTotalPage(CategoriesData?.getCategoriesList?.postCount);
    }
  }, [CategoriesData]);

  const handleRequestSort3 = (property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      CategoriesData?.getCategoriesList &&
      count !== CategoriesData?.getCategoriesList?.postCount
    ) {
      setCount(
        CategoriesData?.getCategoriesList
          ? Math.ceil(CategoriesData?.getCategoriesList?.postCount / totalCount)
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CategoriesData]);

  useEffect(() => {
    setLoaderPosition(false);
  }, [order, orderBy]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPageDropDown(parseInt(newValue));
      setLoaderPosition(true);
      setPageRecord(parseInt(newValue));
    }
  };

  let options = [];

  useEffect(() => {
    if (pageDropDown !== null) {
      setPage(pageDropDown);
    }
  }, [pageDropDown]);

  for (let pageValue = 1; pageValue <= (count === 0 ? 1 : count); pageValue++) {
    options.push(pageValue);
  }

  const onKeyDown = (ev: any) => {
    if (
      ev.key === "Enter" &&
      ev.target.value <= options.length &&
      ev.target.value > 0
    ) {
      if (ev.target.value && pageDropDown !== null) {
        setPage(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
    }
  };

  const position: any = loaderPosition ? { top: "10%" } : { bottom: "10%" };
  const endNumber = pageRecord * totalCount;
  const startRecordNumber = endNumber - (totalCount - 1);
  const endRecordNumber =
    endNumber > totalPage
      ? startRecordNumber + (totalPage - startRecordNumber)
      : endNumber;

  return (
    <div>
      <CategoriesTablePresentational
        tableData={CategoriesData}
        categories={categories}
        headRows={headRows}
        handleRequestSort3={handleRequestSort3}
        order={order}
        orderBy={orderBy}
        count={count}
        page={page}
        setPage={setPage}
        open={open}
        loading={loading}
        position={position}
        setLoaderPosition={setLoaderPosition}
        setPageRecord={setPageRecord}
        startRecordNumber={startRecordNumber}
        endRecordNumber={endRecordNumber}
        totalPage={totalPage}
        handleChange={handleChange}
        options={options}
        pageDropDown={pageDropDown}
        setPageDropDown={setPageDropDown}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default CategoriesTable;

const formatCategoryList = (CategoriesData: Categories[]): Categories[] => {
  return (
    CategoriesData &&
    CategoriesData.map((v) => {
      return {
        ...v,
        categoryNameStickyColumn: formatString(v.name),
        brandListLength: formatNumber(v.brandListLength),
        creatorListLength: formatNumber(v.creatorListLength),
        totalSpent: formatToDollarValue(formatNumber(v.totalSpent)),
        totalViews: formatNumber(v.totalViews),
        totalPosts: formatNumber(v.totalPosts),
      };
    })
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    position: "absolute",
    top: "49%",
    left: "47%",
  },
}));
