import { gql } from "@apollo/client";

const GET_DOWNLOADED_DATA = gql`
  query getDownloadBrandsCreatorsList($list: String) {
    getDownloadBrandsCreatorsList(list: $list) {
      message
      status
      link
    }
  }
`;
const GET_UBQ_DATA = gql`
  query getDownloadCreatorList {
    getDownloadCreatorList {
      message
      status
      link
    }
  }
`;

export { GET_DOWNLOADED_DATA, GET_UBQ_DATA };
