import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import * as AppConfigs from "@utils/config";
declare global {
  interface Window {
    app: any;
  }
}

let envUrls = AppConfigs.devEnvironmentUrls;
switch (window.app.env.REACT_APP_ENV) {
  case AppConfigs.Environment.STAGING:
    envUrls = AppConfigs.stagingEnvironmentUrls;
    break;
  case AppConfigs.Environment.DEV:
    envUrls = AppConfigs.devEnvironmentUrls;
    break;
  case AppConfigs.Environment.PROD:
    envUrls = AppConfigs.prodEnvironmentUrls;
    break;
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, ${path}`
      );
    });
  if (networkError) {
    console.log("[Network error]: ", networkError);
  }
});

// authlink to set headers and authorization information
const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem("accessToken");
  const headersObj = {
    ...headers,
    Authorization: accessToken,
    "x-api-key": envUrls.xApiKey,
  };
  return {
    headers: headersObj,
  };
});

const httpLink = createHttpLink({
  uri: envUrls.apiUrl,
});
const splitLink = split(() => true, authLink.concat(httpLink));
const combinedLink = from([errorLink, splitLink]);

export const apolloClient = new ApolloClient({
  link: combinedLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
