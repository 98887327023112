import { FunctionComponent } from "react";
import React from "react";
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { HeadRowType } from "@Models/Brands";
import RowHeader from "@modules/Organisms/Header/RowHeader";
import { IndividualCreatorPostTab } from "@Models/Creators";
import { monthNames, ROUTES } from "@src/constants";
import Pagination from "@modules/Pagination/Pagination";
import tiktok from "@assets/tiktok.png";
import user from "@assets/user.png";
import GenrePopover from "@modules/GenrePopover/GenrePopover";
import PaginationDropDownPresentational from "@modules/Organisms/PaginationDropDown/PaginationDropDown.Presentational";
export interface CreatorPostDetailsTablePresentationalProps {
  posts: IndividualCreatorPostTab[];
  headRows: HeadRowType[];
  handleRequestSort3: (property: any) => void;
  order: "asc" | "desc" | undefined;
  orderBy: string;
  count: number;
  page: number;
  setPage: any;
  open: boolean;
  loading: boolean;
  position: any;
  setLoaderPosition: any;
  setPageRecord: React.Dispatch<React.SetStateAction<number>>;
  startRecordNumber: number;
  endRecordNumber: number;
  totalPage: number;
  handleChange: (event: any, newValue: any) => void;
  options: number[];
  pageDropDown: number;
  setPageDropDown: React.Dispatch<React.SetStateAction<number>>;
  onKeyDown: (ev: any) => void;
}
const CreatorPostDetailsTablePresentational: FunctionComponent<
  CreatorPostDetailsTablePresentationalProps
> = ({
  posts,
  headRows,
  order,
  orderBy,
  handleRequestSort3,
  count,
  page,
  setPage,
  open,
  loading,
  position,
  setLoaderPosition,
  setPageRecord,
  startRecordNumber,
  endRecordNumber,
  totalPage,
  handleChange,
  options,
  pageDropDown,
  setPageDropDown,
  onKeyDown,
}) => {
  const sx = styles(useTheme());
  const theme: Theme = useTheme();
  return (
    <Box sx={sx.parent}>
      <Paper sx={sx.paper} elevation={1}>
        <Box sx={sx.tableWrapper}>
          {loading || !posts ? (
            <Backdrop sx={sx.backdropStyle} open={open}>
              <CircularProgress
                color="inherit"
                sx={sx.svgStyle}
                style={position}
              />
            </Backdrop>
          ) : (
            ""
          )}
          <Box sx={sx.tableWrapper2}>
            <Table sx={sx.table}>
              <RowHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort3}
                headRows={headRows}
              />
              <TableBody>
                {posts.length === 0 && loading === false ? (
                  <StyledTableRow>
                    <StyledTableBodyCell>
                      <Box maxWidth="400px" width="56vw">
                        Couldn't find any post
                      </Box>
                    </StyledTableBodyCell>
                  </StyledTableRow>
                ) : (
                  posts.map((row: any, i: number) => (
                    <StyledTableRow key={i}>
                      <StyledTableBodyCell
                        sx={sx.stickyColumn}
                        style={{
                          backgroundColor:
                            i % 2
                              ? getColorByTheme(
                                  theme,
                                  allColors.light3,
                                  allColors.dark2
                                )
                              : getColorByTheme(
                                  theme,
                                  allColors.light1,
                                  allColors.dark3
                                ),
                        }}
                      >
                        {!!row?.createdTime
                          ? `${
                              monthNames[
                                new Date(
                                  row?.createdTime.substring(0, 8)
                                ).getMonth()
                              ]
                            }-${row?.createdTime.substring(8, 10)}-${new Date(
                              row?.createdTime
                            ).getFullYear()}`
                          : ""}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <Box display="flex" alignItems="center">
                          <Avatar
                            src={
                              row.brandImage
                                ? row.brandImage.split("?")[0]
                                : user
                            }
                            sx={sx.avtar}
                          />
                          <Link
                            sx={sx.colorHyperLink}
                            href={`${ROUTES.BRAND_DATA}/${row.brandName}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {row.brandName}
                          </Link>
                          <Link
                            href={`${row.brandLink ? row.brandLink : ""}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Avatar src={tiktok} sx={sx.link} />
                          </Link>
                        </Box>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <Box display="flex" alignItems="center">
                          <Box display="flex" alignItems="center">
                            <Box sx={sx.textContainer}>{row.description}</Box>
                            {row?.description && (
                              <GenrePopover items={["", row.description]} />
                            )}
                          </Box>
                          <Link
                            href={`${row?.videoUrl}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Avatar src={tiktok} sx={sx.link} />
                          </Link>
                        </Box>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>{row.viewCount}</StyledTableBodyCell>
                      <StyledTableBodyCell>{row.likeCount}</StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {row.shareCount}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {row.commentCount}
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Paper>
      <Box mt={6} display="flex" justifyContent="center" width="100%">
        <Box sx={sx.paginationBoxStyle}>
          <Pagination
            count={count === 0 ? 1 : count}
            page={page}
            onChange={(e: any, page: number) => {
              setLoaderPosition(true);
              setPageRecord(page);
              setPage(page);
              setPageDropDown(page);
            }}
          />
          <PaginationDropDownPresentational
            handleChange={handleChange}
            options={options}
            pageDropDown={pageDropDown}
            onKeyDown={onKeyDown}
          />
        </Box>
        <Box sx={sx.boxStyle}>
          {posts.length === 0 || loading || !posts
            ? "0-0 of 0 items"
            : `${startRecordNumber} - ${endRecordNumber} of ${totalPage} items`}
        </Box>
      </Box>
    </Box>
  );
};
export default CreatorPostDetailsTablePresentational;
const styles = (theme: any) => {
  return {
    backdropStyle: {
      color: "#7026FF",
      backgroundColor: "rgb(240,230,0,0.5)",
      position: "absolute",
      backgroundPosition: "right top",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      backdropFilter: "blur(2px)",
      display: "flex",
      alignItems: "flex-end",
      width: "100vw",
    },
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 800,
    },
    boxStyle: {
      marginLeft: "auto",
      alignSelf: "center",
      fontFamily: "SF Pro Text",
      fontSize: "1rem",
      fontWeight: 700,
      display: "flex",
      width: "15%",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    paginationBoxStyle: {
      marginLeft: "auto",
      display: "flex",
      justifyContent: "center",
    },
    svgStyle: {
      position: "absolute",
    },
    parent: {
      width: "100%",
      display: "table",
    },
    avtar: {
      marginRight: theme.spacing(2),
      width: "50px",
      height: "50px",
    },
    link: {
      marginLeft: theme.spacing(1),
      width: "25px",
      height: "25px",
    },
    textContainer: {
      width: "200px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    paper: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      borderRadius: "21px",
      paddingTop: theme.spacing(2),
    },
    table: {
      minWidth: 300,
    },
    tableWrapper: {
      overflowX: "auto",
      // It's 180 degree rotated that's why it's paddingTop instead of bottom
      paddingTop: theme.spacing(6),
      transform: "rotatex(180deg)",
      /* height */
      "&::-webkit-scrollbar": {
        height: "7px",
      },
      /* Track */
      "&::-webkit-scrollbar-track": {
        background: getColorByTheme(theme, allColors.light3, allColors.dark6),
        borderRadius: "50px",
        margin: theme.spacing(0, 2),
      },
      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: getColorByTheme(theme, allColors.light4, allColors.dark7),
        borderRadius: "50px",
      },
      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: getColorByTheme(theme, allColors.light5, allColors.dark8),
      },
    },
    tableWrapper2: {
      transform: "rotatex(180deg)",
      minHeight: "5vh",
      display: "inline-grid",
    },
    colorHyperLink: {
      textDecoration: "none",
      color: "inherit",
      fontWeight: 700,
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
      "&:hover": {
        color: "inherit",
      },
      "&:hover::after": {
        width: "100%",
      },
    },
    tableSortLabel: {
      "& .MuiTableSortLabel-icon": {
        position: "absolute",
        left: "-30px",
      },
    },
  };
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  "&:nth-of-type(even)": {
    backgroundColor: getColorByTheme(theme, allColors.light3, allColors.dark2),
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: getColorByTheme(theme, allColors.dark1, allColors.light1),
  fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
  fontSize: "1.23rem",
  fontWeight: "400",
  padding: theme.spacing("10px", 3),
  whiteSpace: "nowrap",
  "&:first-of-type": {
    paddingLeft: theme.spacing(3),
  },
  "&:last-child": {
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up("sm")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(3),
    },
    "&:last-child": {
      paddingRight: theme.spacing(3),
    },
  },
  [theme.breakpoints.up("md")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(8),
    },
    "&:last-child": {
      paddingRight: theme.spacing(8),
    },
  },
}));
