import { Paper, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React, { FunctionComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Legend,
} from "recharts";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";
import {
  DataFormatter,
  DataFormatterYAxis,
  dateFormatter,
} from "@utils/digitFormatter";
import ChartHeader from "@modules/Organisms/Charts/ChartHeader/ChartHeader";
import { BarChartCardProps } from "@modules/Organisms/Charts/charts.types";
import CustomLegend from "@modules/Organisms/Charts/helper/CustomLegend";
import CustomToolTip from "@modules/Organisms/Charts/helper/CustomToolTip";
import FallBackChart from "@modules/Organisms/Charts/helper/FallBackChart";
import {
  barchartCardStyles,
  barchartTopBarTextStyles,
} from "./barChart.styles";
const BarChartCardPresentational: FunctionComponent<BarChartCardProps> = ({
  classname,
  customstyle,
  data,
  xkey,
  xLabel,
  ykey,
  yLabel,
  secondYKey,
  secondYLabel,
  header,
  subHeader,
  selectedOption,
  dropdownOption,
  setDropdownOptions,
  filterKey,
}) => {
  const sx = barchartCardStyles(useTheme());
  const classes = barchartTopBarTextStyles();
  const theme: Theme = useTheme();
  const size = xkey === "category" ? 200 : 30;
  const orient = xkey === "category" ? -90 : 0;
  const height = xkey === "category" ? 600 : 380;
  const ratio = xkey === "category" ? 3 : 5;
  const dy = xkey === "category" ? -15 : 0;
  return (
    <Paper
      className={`${classname}`}
      sx={sx.paper}
      style={{ ...customstyle, paddingBottom: header ? "1.5rem" : "1.5rem" }}
    >
      <ChartHeader
        data={data}
        header={header}
        subHeader={subHeader}
        dropdownOption={dropdownOption}
        selectedOption={selectedOption}
        setDropdownOptions={setDropdownOptions}
        filterKey={filterKey}
      />
      {(data?.length === 0 || !data) && <FallBackChart classname=" mt-0" />}
      {data && data.length > 0 && (
        <ResponsiveContainer width="99%" height="100%" aspect={ratio}>
          <BarChart
            width={500}
            height={height}
            data={data}
            margin={{
              top: 15,
              right: 30,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid
              horizontal
              vertical={false}
              strokeDasharray="3 3"
              stroke={getColorByTheme(
                theme,
                `${allColors.light4}`,
                `${allColors.purple1}`
              )}
            />
            <XAxis
              dataKey={xkey}
              tickFormatter={dateFormatter}
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              interval={0}
              style={{ textAnchor: "end" }}
              angle={orient}
              allowDataOverflow={false}
              height={size}
              dx={dy}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              interval={0}
              tickFormatter={
                subHeader?.includes("$") ? DataFormatterYAxis : DataFormatter
              }
            />
            <Tooltip
              cursor={false}
              wrapperStyle={{
                outline: "none",
                backgroundColor: getColorByTheme(
                  theme,
                  `${allColors.light4}`,
                  `${allColors.purple2}`
                ),
                padding: "20px 20px 20px 20px",
                borderRadius: "13px",
              }}
              formatter={function (value: any) {
                return `${value}`;
              }}
              labelFormatter={function (value) {
                return `${value}`;
              }}
              content={
                <CustomToolTip
                  xkey={xkey}
                  ykey={ykey}
                  secondYKey={secondYKey}
                  xLabel={xLabel}
                  yLabel={yLabel}
                  secondYLabel={secondYLabel}
                />
              }
            />

            {ykey && secondYKey ? (
              <Legend
                verticalAlign="bottom"
                height={36}
                align="left"
                content={CustomLegend}
              />
            ) : (
              ""
            )}

            <Bar
              barSize={25}
              name={yLabel}
              dataKey={String(ykey)}
              fill="#7026FF"
              radius={3}
            >
              <LabelList
                formatter={DataFormatter}
                dataKey={ykey}
                position="top"
                className={classes.topBarText}
              />
            </Bar>
            <Bar
              barSize={25}
              name={secondYLabel}
              dataKey={String(secondYKey)}
              fill="#82ca86"
              radius={3}
            >
              <LabelList
                formatter={DataFormatter}
                dataKey={secondYKey}
                position="top"
                style={{ color: "red" }}
                className={classes.topBarText}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default BarChartCardPresentational;
