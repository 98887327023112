import { gql } from "@apollo/client";

const GET_CREATORS_TABLE_DATA = gql`
  query onGetCreatorsList($input: GetCreatorsInput!) {
    getCreatorsList(input: $input) {
      creatorCount
      creators {
        legalName
        tiktokUsername
        creatorId
        tiktokUserId
        tiktokProfileLink
        tiktokFollowers
        tiktokAvgViews
        tiktokMedianViews
        genre
        femaleFollowersPer
        maleFollowersPer
        age18To24
        age25To34
        instagram
        instaFollowers
        signingType
        lastUpdatedDate
        age35Plus
        youtubeLink
        twitterLink
        contentType
        industryType
        soundCampaignGenre
      }
    }
  }
`;

const GET_SEARCH_CREATORS_DATA = gql`
  query onGetCreatorsList($input: GetCreatorsInput!) {
    getCreatorsList(input: $input) {
      creatorCount
      creators {
        legalName
        tiktokUsername
        creatorId
        tiktokUserId
        tiktokProfileLink
        tiktokFollowers
        tiktokAvgViews
        tiktokMedianViews
        genre
        femaleFollowersPer
        maleFollowersPer
        age18To24
        age25To34
        instagram
        instaFollowers
        signingType
        lastUpdatedDate
        age35Plus
        youtubeLink
        twitterLink
        contentType
        industryType
        soundCampaignGenre
      }
    }
  }
`;
const GET_HEADERS_DATA = gql`
  query onGetCreatorsStats {
    getCreatorsStats {
      totalCreators
      totalFollowers
      totalAvgViews
    }
  }
`;

const GET_SPONSORED_CREATOR_HEADERS_DATA = gql`
  query ongetCreatorsHeaderPage {
    getCreatorsHeaderPage {
      totalPaidCreators
      totalEarning
      totalViewsPurchased
      totalSponsoredPost
    }
  }
`;

const GET_SPONSORED_CREATORS_TABLE_DATA = gql`
  query ongetBrandsCreatorsList($input: BrandsCreatorsListInput!) {
    getBrandsCreatorsList(input: $input) {
      totalRecords
      creatorList {
        _id
        tiktokUsername
        tiktokProfileLink
        tiktokProfileImage
        totalEarned
        tiktokTotalBrandPostViews
        tiktokTotalBrandPosts
        tiktokBrands
        categoryList
        tiktokFollowers
        tiktokAvgViews
        isNetworkCreator
        tiktokBrandPostEngagementRate
        tiktokOrganicPostEngagementRate
        tiktokBlendedEngagement
        tiktokAvgOrganicPostViews
        tiktokAvgBrandPostViews
        tiktokAvgPostFrequency
        phoneNumberSecondary
        emailSecondary
      }
    }
  }
`;

const GET_BRANDS_HEADERS_DATA = gql`
  query ongetBrandsHeaderPage {
    getBrandsHeaderPage {
      totalBrands
      totalEarning
      totalViewsPurchased
      totalSponsoredPost
    }
  }
`;

const GET_BRANDS_TABLE_DATA = gql`
  query onGetBrandsLits($input: BrandListInput!) {
    getBrandsList(input: $input) {
      totalRecords
      accountData {
        _id
        tiktokUserId
        tiktokUserName
        tiktokProfileLink
        tiktokProfileImage
        totalSpent
        totalViews
        totalPosts
        creators
        correctedCategories
        followers
      }
    }
  }
`;

const GET_CATEGORIES_TABLE_DATA = gql`
  query onGetCategoriesList($input: CategoriesInput!) {
    getCategoriesList(input: $input) {
      postCount
      categoryData {
        name
        totalViews
        totalSpent
        totalPosts
        brandListLength
        creatorListLength
      }
    }
  }
`;
const SEARCH_BRAND_DATA = gql`
  query SearchBrand(
    $tiktokUsername: String
    $pageNumber: Int!
    $sortByBrandUsername: Int
    $sortByTotalSpent: Int
    $sortByTotalSponseredViews: Int
    $sortByTotalNumberOfPaidPosts: Int
    $sortByBrandsFollowers: Int
    $sortByCategory: [String]
  ) {
    searchBrand(
      tiktokUsername: $tiktokUsername
      pageNumber: $pageNumber
      sortByBrandUsername: $sortByBrandUsername
      sortByTotalSpent: $sortByTotalSpent
      sortByTotalSponseredViews: $sortByTotalSponseredViews
      sortByTotalNumberOfPaidPosts: $sortByTotalNumberOfPaidPosts
      sortByBrandsFollowers: $sortByBrandsFollowers
      sortByCategory: $sortByCategory
    ) {
      success
      totalRecords
      accountData {
        _id
        tiktokUserId
        tiktokUserName
        tiktokProfileLink
        tiktokProfileImage
        totalSpent
        totalViews
        totalPosts
        creators
        correctedCategories
        followers
      }
    }
  }
`;

export {
  GET_CREATORS_TABLE_DATA,
  GET_SEARCH_CREATORS_DATA,
  GET_HEADERS_DATA,
  GET_SPONSORED_CREATOR_HEADERS_DATA,
  GET_SPONSORED_CREATORS_TABLE_DATA,
  GET_BRANDS_HEADERS_DATA,
  GET_BRANDS_TABLE_DATA,
  GET_CATEGORIES_TABLE_DATA,
  SEARCH_BRAND_DATA,
};
