import React from "react";
import { Box, Container, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import "@pages/media.css";
import CategoriesTable from "@modules/Brands/Categories/grid/CategoriesTable";
import CategoriesChart from "@modules/Brands/Categories/chart/CategoriesChart";
import Sidebar from "@modules/SideBar/Sidebar";
import { Header } from "@modules/Header/Header";
export const CategoryPage = () => {
  const theme: Theme = useTheme();
  return (
    <Sidebar>
      <Container id="Container">
        <Header />
        <Box
          width="100%"
          position="relative"
          color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
        >
          <CategoriesChart />
          <CategoriesTable />
        </Box>
      </Container>
    </Sidebar>
  );
};
