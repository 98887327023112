import {
  useMediaQuery,
  useTheme,
  Pagination as MuiPagination,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

const styles = (theme: any) => {
  return {
    paginationItem: {
      "& .MuiPaginationItem-root": {
        background: getColorByTheme(theme, allColors.light6, allColors.dark3),
        fontFamily: "SF Pro Text",
        fontSize: "1rem",
        fontWeight: 700,
      },
      "& .Mui-selected": {
        border: "1px solid rgba(137, 65, 255, 1)",
      },
      "& .MuiPaginationItem-icon": {
        height: 20,
        width: 20,
      },
      "& .MuiPaginationItem-root.Mui-selected": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light6,
          allColors.dark3
        ),
      },
      "& .MuiPaginationItem-page.Mui-disabled": {
        opacity: 0.5,
      },
    },
  };
};
interface Props {
  count: number;
  page: number;
  onChange: (e: any, page: number) => void;
}

const Pagination = ({ count, page, onChange }: Props) => {
  const sx = styles(useTheme());
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  return (
    <MuiPagination
      sx={sx.paginationItem}
      page={page}
      onChange={onChange}
      count={count}
      shape="rounded"
      boundaryCount={matches ? 2 : 1}
      siblingCount={1}
    />
  );
};

export default Pagination;
