import { FunctionComponent } from "react";
import React from "react";
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Avatar,
  Typography,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { TailSpin } from "react-loader-spinner";
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { ButtonPill } from "@modules/Atoms/Buttons";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { SponsoredCreator, HeadRowType } from "@Models/Creators";
import Pagination from "@modules/Pagination/Pagination";
import GenrePopover from "@modules/GenrePopover/GenrePopover";
import RowHeader from "@modules/Organisms/Header/RowHeader";
import tiktok from "@assets/tiktok.png";
import AutoCompleteCategory from "@modules/BrandTable/AutoCompleteCategory";
import CheckboxWithTitle from "@modules/Checkboxes/CheckboxWithTitle";
import { toAbsoluteUrl } from "@src/_metronic/_helpers";
import { ROUTES } from "@src/constants";
import { AutoSuggestCreator } from "@modules/AutoSuggestBox/AutoSuggestCreator";
import user from "@assets/user.png";
import { categories } from "@modules/BrandTable/data";
import PaginationDropDownPresentational from "@modules/Organisms/PaginationDropDown/PaginationDropDown.Presentational";

export interface SponsoredCreatorsTablePresentationalProps {
  tableData: SponsoredCreator[];
  creators: SponsoredCreator[];
  headRows: HeadRowType[];
  handleRequestSort3: (property: any) => void;
  order: "asc" | "desc" | undefined;
  orderBy: string;
  count: number;
  filterPageCount: number;
  page: number;
  setPage: any;
  pageFilter: number;
  setPageFilter: any;
  correctedCategories: Array<string>;
  selectCorrectedCategory: (_id: string, value: any) => void;
  isNetworkCreator: boolean;
  setIsNetworkCreator: React.Dispatch<React.SetStateAction<boolean>>;

  value: string;
  setValue: (...args: any) => void;
  suggestions: Array<any>;
  setSuggestions: (...args: any) => void;
  loading?: boolean;
  array?: any;
  fetchSuggestion: (...args: any) => void;
  onSuggestionSelected: (...args: any) => void;
  onEnter: (...args: any) => void;
  placeholder?: string;
  open: boolean;
  tableLoader?: boolean;
  position: any;
  setLoaderPosition: any;
  tableRef: any;
  setClear: React.Dispatch<React.SetStateAction<Boolean>>;
  setPageRecord: React.Dispatch<React.SetStateAction<number>>;
  startRecordNumber: number;
  endRecordNumber: number;
  totalPage: number;
  csvDownload: () => void;
  totalFilterRecord: number;
  downloadLoading?: boolean;
  handleChange: (event: any, newValue: any) => void;
  options: number[];
  pageDropDown: number;
  setPageDropDown: React.Dispatch<React.SetStateAction<number>>;
  onKeyDown: (ev: any) => void;
  onOptionSelect: boolean;
}

const SponsoredCreatorsTablePresentational: FunctionComponent<
  SponsoredCreatorsTablePresentationalProps
> = ({
  tableData,
  creators,
  headRows,
  order,
  orderBy,
  handleRequestSort3,
  count,
  page,
  setPage,
  correctedCategories,
  selectCorrectedCategory,
  isNetworkCreator,
  setIsNetworkCreator,
  suggestions,
  setSuggestions,
  onSuggestionSelected,
  setValue,
  loading,
  array,
  value,
  fetchSuggestion,
  onEnter,
  placeholder,
  pageFilter,
  setPageFilter,
  filterPageCount,
  open,
  tableLoader,
  position,
  setLoaderPosition,
  tableRef,
  setClear,
  setPageRecord,
  startRecordNumber,
  endRecordNumber,
  totalPage,
  csvDownload,
  totalFilterRecord,
  downloadLoading,
  handleChange,
  options,
  pageDropDown,
  setPageDropDown,
  onKeyDown,
  onOptionSelect,
}) => {
  const sx = styles(useTheme());
  const theme: Theme = useTheme();
  return (
    <Box sx={sx.parent} ref={tableRef}>
      <>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box mr={1}>
            <ButtonPill onClick={csvDownload}>
              {downloadLoading ? (
                <div>
                  <TailSpin color="#7026FF" height={20} width={20} />
                </div>
              ) : (
                <span>Download</span>
              )}
            </ButtonPill>
          </Box>
          <Box style={{ marginLeft: "auto" }}>
            <CheckboxWithTitle
              title="Network Creator"
              checked={isNetworkCreator}
              onChange={(value: any) => {
                setIsNetworkCreator(value);
              }}
            />
          </Box>
          <Box mr={1}>
            <AutoSuggestCreator
              value={value}
              setValue={setValue}
              loading={loading}
              fetchSuggestion={fetchSuggestion}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
              array={array}
              onSuggestionSelected={onSuggestionSelected}
              onEnter={onEnter}
              placeholder={placeholder}
              setClear={setClear}
              onOptionSelect={onOptionSelect}
            />
          </Box>
          <AutoCompleteCategory
            customStyle={{ width: "30%" }}
            icon={<SearchIcon />}
            placeholder="Search by categories"
            correctedCategory={correctedCategories}
            id={"1"}
            onAdd={selectCorrectedCategory}
            options={categories}
          />
        </Box>
        <Box mt={2} sx={sx.headerBoxStyle}>
          <Box sx={sx.boxStyle}>
            <Typography sx={sx.typography}>
              {creators.length === 0 ||
              loading ||
              !creators ||
              !tableData ||
              !totalFilterRecord
                ? "Total Creators: 0"
                : `Total Creators: ${
                    value === "" ? totalPage : totalFilterRecord
                  }`}
            </Typography>
            <Typography sx={sx.typography}>
              {creators.length === 0 ||
              loading ||
              !creators ||
              !tableData ||
              !totalFilterRecord
                ? "0-0 of 0 items"
                : `${startRecordNumber} - ${endRecordNumber} of ${
                    value === "" ? totalPage : totalFilterRecord
                  } items`}
            </Typography>
          </Box>
        </Box>
        <Paper sx={sx.paper} elevation={1}>
          <Box sx={sx.tableWrapper}>
            {tableLoader || !creators || !tableData ? (
              <Backdrop sx={sx.backdropStyle} open={open}>
                <CircularProgress
                  color="inherit"
                  sx={sx.svgStyle}
                  style={position}
                />
              </Backdrop>
            ) : (
              ""
            )}
            <Box sx={sx.tableWrapper2}>
              <Table sx={sx.table}>
                <RowHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort3}
                  headRows={headRows}
                />
                <TableBody>
                  {creators.length === 0 && tableLoader === false ? (
                    <StyledTableRow>
                      <StyledTableBodyCell>
                        <Box maxWidth="400px" width="56vw">
                          Couldn't find any creator
                        </Box>
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  ) : (
                    creators?.map((row: SponsoredCreator, i: number) => (
                      <StyledTableRow key={i}>
                        <StyledTableBodyCell
                          sx={sx.stickyColumn}
                          style={{
                            backgroundColor:
                              i % 2
                                ? getColorByTheme(
                                    theme,
                                    allColors.light3,
                                    allColors.dark2
                                  )
                                : getColorByTheme(
                                    theme,
                                    allColors.light1,
                                    allColors.dark3
                                  ),
                          }}
                        >
                          <Toolbar sx={sx.toolbarStyle}>
                            <Avatar
                              src={
                                row?.tiktokProfileImage
                                  ? row?.tiktokProfileImage
                                  : user
                              }
                              sx={sx.avtar}
                            />
                            <Link
                              sx={sx.colorHyperLink}
                              href={`${ROUTES.SPONSORED_CREATORS}/${row.tiktokUsername}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>{row?.tiktokUsername}</span>
                            </Link>
                            <Link
                              href={`${row?.tiktokProfileLink}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                <Avatar src={tiktok} sx={sx.link} />
                              </span>
                            </Link>
                            {row?.isNetworkCreator && (
                              <img
                                src={toAbsoluteUrl("/media/svg/ubq/ubq.svg")}
                                alt="ubq"
                                style={{
                                  marginLeft: theme.spacing(1),
                                  width: "25px",
                                  height: "25px",
                                }}
                              />
                            )}
                          </Toolbar>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokTotalBrandPostViews}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokTotalBrandPosts}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <Link
                                sx={sx.colorHyperLink}
                                href={`${ROUTES.BRAND_DATA}/${
                                  row?.tiktokBrands ? row.tiktokBrands[0] : ""
                                }`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span>
                                  {row?.tiktokBrands &&
                                  row?.tiktokBrands.length > 0
                                    ? row?.tiktokBrands[0]
                                    : ""}
                                </span>
                              </Link>
                            </Box>
                            {row?.tiktokBrands &&
                              row?.tiktokBrands[1] &&
                              row?.tiktokBrands[1]?.length !== 0 && (
                                <GenrePopover
                                  items={row?.tiktokBrands}
                                  url={ROUTES.BRAND_DATA}
                                />
                              )}
                          </Box>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              {row?.categoryList && row?.categoryList.length > 0
                                ? row?.categoryList[0]
                                : ""}
                            </Box>
                            {row?.categoryList &&
                              row?.categoryList[1] &&
                              row?.categoryList[1]?.length !== 0 && (
                                <GenrePopover items={row.categoryList} />
                              )}
                          </Box>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokFollowers}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokAvgViews}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokAvgBrandPostViews}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokAvgOrganicPostViews}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokAvgPostFrequency}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokBrandPostEngagementRate}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokOrganicPostEngagementRate}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.tiktokBlendedEngagement}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.phoneNumberSecondary}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.emailSecondary}
                        </StyledTableBodyCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

        <Box mt={6} display="flex" justifyContent="center" width="100%">
          <Pagination
            count={
              value === ""
                ? count === 0
                  ? 1
                  : count
                : filterPageCount === 0
                ? 1
                : filterPageCount
            }
            page={value === "" ? page : pageFilter}
            onChange={(e: any, page: number) => {
              if (value === "") {
                setPage(page);
                setPageRecord(page);
                setLoaderPosition(true);
                setPageDropDown(page);
              } else {
                setPageFilter(page);
                setPageRecord(page);
                setPageDropDown(page);
              }
            }}
          />
          <PaginationDropDownPresentational
            handleChange={handleChange}
            options={options}
            pageDropDown={pageDropDown}
            onKeyDown={onKeyDown}
          />
        </Box>
      </>
    </Box>
  );
};

export default SponsoredCreatorsTablePresentational;

const styles = (theme: any) => {
  return {
    backdropStyle: {
      display: "flex",
      alignItems: "flex-end",
      width: "250vw",
      color: "#7026FF",
      backgroundColor: "rgb(240,230,0,0.5)",
      position: "absolute",
      backgroundPosition: "right top",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      backdropFilter: "blur(2px)",
    },
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 800,
    },
    typography: {
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      fontSize: "1.23rem",
    },
    boxStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      width: "30%",
      backgroundColor: "transparent",
    },
    headerBoxStyle: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    svgStyle: {
      position: "absolute",
      left: "45vw",
    },
    toolbarStyle: { paddingLeft: "0" },
    parent: { paddingTop: "3rem" },

    link: {
      marginLeft: theme.spacing(1),
      width: "25px",
      height: "25px",
    },
    avtar: {
      marginRight: theme.spacing(2),
      width: "80px",
      height: "80px",
    },

    paper: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      borderRadius: "21px",
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      width: "100%",
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: "auto",
      // It's 180 degree rotated that's why it's paddingTop instead of bottom
      paddingTop: theme.spacing(6),
      transform: "rotatex(180deg)",

      /* height */
      "&::-webkit-scrollbar": {
        height: "7px",
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        background: getColorByTheme(theme, allColors.light3, allColors.dark6),
        borderRadius: "50px",
        margin: theme.spacing(0, 2),
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: getColorByTheme(theme, allColors.light4, allColors.dark7),
        borderRadius: "50px",
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: getColorByTheme(theme, allColors.light5, allColors.dark8),
      },
    },
    tableWrapper2: {
      transform: "rotatex(180deg)",
      minHeight: "5vh",
    },
    colorHyperLink: {
      textDecoration: "none",
      color: "inherit",
      fontWeight: 700,
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
      "&:hover": {
        color: "inherit",
      },
      "&:hover::after": {
        width: "100%",
      },
    },
    tableSortLabel: {
      "& .MuiTableSortLabel-icon": {
        position: "absolute",
        left: "-30px",
      },
    },
  };
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  "&:nth-of-type(even)": {
    backgroundColor: getColorByTheme(theme, allColors.light3, allColors.dark2),
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: getColorByTheme(theme, allColors.dark1, allColors.light1),
  fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
  fontSize: "1.23rem",
  fontWeight: "400",
  padding: theme.spacing("18px", 3),
  whiteSpace: "nowrap",
  "&:first-of-type": {
    paddingLeft: theme.spacing(3),
  },
  "&:last-child": {
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up("sm")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(6),
    },
    "&:last-child": {
      paddingRight: theme.spacing(6),
    },
  },
  [theme.breakpoints.up("md")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(8),
    },
    "&:last-child": {
      paddingRight: theme.spacing(8),
    },
  },
}));
