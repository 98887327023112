import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { formatNumber, getSorting } from "@modules/utils/utils";
import { GET_INDIVIDUAL_BRANDS_POST_TAB } from "@src/graphql/queries/getCreatorList";
import BrandPostDetailsPresentational from "./BrandPostDetailsTable.presentational";
import { SponsoredCreatorPostTab } from "@Models/Creators";
import { formatString } from "@modules/Organisms/Formatter/Formatter";
import { totalCount } from "@src/constants";

const BrandPostDetailsTable = () => {
  const [posts, setPosts] = useState<SponsoredCreatorPostTab[]>([]);
  const [order, setOrder] = useState<"asc" | "desc" | undefined>("desc");
  const [orderBy, setOrderBy] = useState("createdTimeStickyColumn");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [totalPage, setTotalPage] = useState(30);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loaderPosition, setLoaderPosition] = useState<boolean>(false);
  const [pageRecord, setPageRecord] = useState<number>(1);
  const [pageDropDown, setPageDropDown] = useState<number>(1);
  const params: any = useParams();

  const [getPostList, { data: postsData, loading }] = useLazyQuery(
    GET_INDIVIDUAL_BRANDS_POST_TAB
  );

  const headRows = [
    { id: "createdTimeStickyColumn", label: "Post Date" },
    {
      id: "tiktokUsername",
      label: "Creators",
      link: "tiktokProfileLink",
    },
    { id: "description", label: "Post Captions", dontSort: true },
    { id: "totalViews", label: "Total Views" },
    { id: "totalLikes", label: "Total Likes" },
    { id: "totalShares", label: "Total Shares" },
    { id: "totalComments", label: "Total Comments" },
  ];

  useEffect(() => {
    getPostList({
      variables: {
        input: {
          tiktokUsername: params.brand,
          pageNumber: page,
          sortByPostDate: getSorting("createdTimeStickyColumn", order, orderBy),
          sortByPostUsername: getSorting("tiktokUsername", order, orderBy),
          sortByTotalviews: getSorting("totalViews", order, orderBy),
          sortByTotalLikes: getSorting("totalLikes", order, orderBy),
          sortByTotalShares: getSorting("totalShares", order, orderBy),
          sortByTotalComments: getSorting("totalComments", order, orderBy),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page]);

  useEffect(() => {
    if (!posts || !postsData) {
      setOpen(true);
    }
  }, [posts, postsData]);

  useEffect(() => {
    setPage(1);
    setPageRecord(1);
    setPageDropDown(1);
  }, [order, orderBy]);

  useEffect(() => {
    if (postsData && postsData?.getBrandIndividualPagePostTab?.post) {
      setPosts(
        formatBrandsList(postsData?.getBrandIndividualPagePostTab?.post)
      );
      setTotalPage(postsData?.getBrandIndividualPagePostTab?.postCount);
    }
  }, [postsData]);

  const handleRequestSort3 = (property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      postsData?.getBrandIndividualPagePostTab &&
      count !== postsData?.getBrandIndividualPagePostTab?.postCount
    ) {
      setCount(
        postsData?.getBrandIndividualPagePostTab
          ? Math.ceil(
              postsData?.getBrandIndividualPagePostTab?.postCount / totalCount
            )
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsData]);

  useEffect(() => {
    setLoaderPosition(false);
  }, [order, orderBy]);

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPageDropDown(parseInt(newValue));
      setLoaderPosition(true);
      setPageRecord(parseInt(newValue));
    }
  };

  let options = [];

  useEffect(() => {
    if (pageDropDown !== null) {
      setPage(pageDropDown);
    }
  }, [pageDropDown]);

  for (let pageValue = 1; pageValue <= (count === 0 ? 1 : count); pageValue++) {
    options.push(pageValue);
  }

  const onKeyDown = (ev: any) => {
    if (
      ev.key === "Enter" &&
      ev.target.value <= options.length &&
      ev.target.value > 0
    ) {
      if (ev.target.value && pageDropDown !== null) {
        setPage(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
    }
  };

  const position: any = loaderPosition
    ? { top: "10%", left: "30vw" }
    : { bottom: "10%", left: "30vw" };

  const endNumber = pageRecord * totalCount;
  const startRecordNumber = endNumber - (totalCount - 1);
  const endRecordNumber =
    endNumber > totalPage
      ? startRecordNumber + (totalPage - startRecordNumber)
      : endNumber;

  return (
    <div>
      <BrandPostDetailsPresentational
        posts={posts}
        headRows={headRows}
        handleRequestSort3={handleRequestSort3}
        order={order}
        orderBy={orderBy}
        count={count}
        page={page}
        setPage={setPage}
        open={open}
        loading={loading}
        position={position}
        setLoaderPosition={setLoaderPosition}
        setPageRecord={setPageRecord}
        startRecordNumber={startRecordNumber}
        endRecordNumber={endRecordNumber}
        totalPage={totalPage}
        handleChange={handleChange}
        options={options}
        pageDropDown={pageDropDown}
        setPageDropDown={setPageDropDown}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default BrandPostDetailsTable;

const formatBrandsList = (postData: SponsoredCreatorPostTab[]) => {
  return postData.map((v) => {
    return {
      ...v,
      tiktokUsername: formatString(v.tiktokUsername),
      likeCount: formatNumber(v.likeCount),
      shareCount: formatNumber(v.shareCount),
      commentCount: formatNumber(v.commentCount),
      viewCount: formatNumber(v.viewCount),
      description: formatString(v.description),
    };
  });
};
