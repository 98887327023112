import React, { useState, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { BrandData } from "@Models/Brands";
import {
  GET_BRANDS_TABLE_DATA,
  SEARCH_BRAND_DATA,
} from "@src/graphql/queries/getTableData";
import BrandDataTablePresentational from "./BrandDataTable.Presentational";
import { formatNumber, getSorting } from "@modules/utils/utils";
import { formatString } from "@modules/Organisms/Formatter/Formatter";
import { totalCount } from "@src/constants";
import { GET_DOWNLOADED_DATA } from "@src/graphql/queries/download";

const BrandDataTable = () => {
  const [brands, setBrands] = useState<BrandData[]>([]);
  const [order, setOrder] = useState<"asc" | "desc" | undefined>("desc");
  const [orderBy, setOrderBy] = useState("totalPost");
  const [page, setPage] = useState<number>(1);
  const [pageFilter, setPageFilter] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [filterPageCount, setFilterPageCount] = useState<number>(1);
  const [correctedCategories, setCorrectedCategories] = useState([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loaderPosition, setLoaderPosition] = useState<boolean>(false);
  const [clear, setClear] = useState<Boolean>(false);
  const tableRef = useRef<any>(null);
  const [pageRecord, setPageRecord] = useState<number>(1);
  const [pageDropDown, setPageDropDown] = useState<number>(1);
  const [onSelect, setOnSelect] = useState(false);
  const [onOptionSelect, setOnOptionSelect] = useState(false);
  const selecteCorrectedCategory = (_id: string, value: any) => {
    setCorrectedCategories(value);
  };
  const [getBrandsList, { data: brandsData, loading }] = useLazyQuery(
    GET_BRANDS_TABLE_DATA
  );
  const [getDownloadBrandsCreatorsList] = useLazyQuery(GET_DOWNLOADED_DATA);

  const [suggestionValue, setSuggestionValue] = useState<string>("");
  const [data, setData] = useState<BrandData[]>([]);
  const [getfilterBrandList, { data: brandslist, loading: filterLoading }] =
    useLazyQuery(SEARCH_BRAND_DATA);
  const [updateTableData, setupdateTableData] = useState<any[]>([]);
  const [onenter, setOnEnter] = useState<boolean>(false);
  const headRows = [
    {
      id: "tiktokUsernameStickyColumn",
      label: "Brand Username",
      link: "tiktokProfileLink",
    },
    { id: "totalViews", label: "Sponsored Views" },
    { id: "totalPost", label: "Total number of paid posts" },
    { id: "creators", label: "Creators", dontSort: true },
    { id: "correctedCategories", label: "Categories", dontSort: true },
    { id: "followers", label: "Brand Followers" },
  ];
  const [totalP, setTotalP] = useState(1);
  const [spage, setSPage] = useState(1);
  const [ePage, setEPage] = useState(1);

  const position: any = loaderPosition ? { top: "10%" } : { bottom: "10%" };

  useEffect(() => {
    let endNumber = pageRecord * totalCount;
    let startRecordNumber = endNumber - (totalCount - 1);
    const total =
      suggestionValue !== ""
        ? brandslist?.searchBrand?.totalRecords
        : brandsData?.getBrandsList?.totalRecords;
    if (!onSelect) {
      setTotalP(total);
      setSPage(endNumber - (totalCount - 1));
      setEPage(
        endNumber > total
          ? startRecordNumber + (total - startRecordNumber)
          : endNumber
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, brandsData, brandslist, pageRecord, suggestionValue]);
  useEffect(() => {
    if (!brands || !brandsData) {
      setOpen(true);
    }
  }, [brands, brandsData]);

  useEffect(() => {
    if (suggestionValue === "") {
      getBrandsList({
        variables: {
          input: {
            pageNumber: page,
            sortByBrandUsername: getSorting(
              "tiktokUsernameStickyColumn",
              order,
              orderBy
            ),
            sortByTotalSponseredViews: getSorting("totalViews", order, orderBy),
            sortByTotalNumberOfPaidPosts: getSorting(
              "totalPost",
              order,
              orderBy
            ),
            sortByBrandsFollowers: getSorting("followers", order, orderBy),
            sortByCategory: correctedCategories,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, clear, correctedCategories]);

  useEffect(() => {
    if (clear === true) {
      tableRef.current?.scrollIntoView({ block: "start" });
    }
  }, [clear, brands]);

  useEffect(() => {
    setPage(1);
    setPageRecord(1);
    setPageDropDown(1);
  }, [order, orderBy, correctedCategories, onenter, suggestionValue]);

  useEffect(() => {
    if (brandsData && brandsData.getBrandsList) {
      setBrands(formatBrandsList(brandsData.getBrandsList.accountData));
    }
  }, [brandsData, loading]);

  const handleRequestSort3 = (property: any) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      brandsData?.getBrandsList &&
      count !== brandsData?.getBrandsList?.totalRecords
    ) {
      setCount(
        brandsData?.getBrandsList
          ? Math.ceil(brandsData?.getBrandsList?.totalRecords / totalCount)
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalP]);

  useEffect(() => {
    if (
      brandslist?.searchBrand &&
      filterPageCount !== brandslist?.searchBrand?.totalRecords
    ) {
      setFilterPageCount(
        brandslist?.searchBrand?.totalRecords
          ? Math.ceil(totalP / totalCount)
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalP]);

  const OnEnterList = (code: any) => {
    if (code?.keyCode === 13) {
      setOnEnter(true);
      setBrands(formatBrandsList(updateTableData));
    }
  };
  const fetchQuery = (value?: any) => {
    getfilterBrandList({
      variables: {
        tiktokUsername: value ? value : suggestionValue,
        pageNumber: pageFilter,
        sortByBrandUsername: getSorting(
          "tiktokUsernameStickyColumn",
          order,
          orderBy
        ),
        sortByTotalSponseredViews: getSorting("totalViews", order, orderBy),
        sortByTotalNumberOfPaidPosts: getSorting("totalPost", order, orderBy),
        sortByBrandsFollowers: getSorting("followers", order, orderBy),
        sortByCategory: correctedCategories,
      },
    });
  };

  const searchBrandData: BrandData[] = brandslist?.searchBrand?.accountData;

  useEffect(() => {
    if (searchBrandData) {
      setData(searchBrandData);
      if (onenter) {
        setBrands(formatBrandsList(searchBrandData));
      }
      setupdateTableData(searchBrandData);
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandslist, pageFilter]);
  const onSuggestionSelected = (event: any, { suggestion }: any) => {
    if (event?.keyCode !== 13) {
      setOnSelect(true);
      setOnOptionSelect(true);
      setPageFilter(1);
      setBrands(formatBrandsList([suggestion]));
      setTotalP([suggestion].length);
      setSPage(1);
      setEPage(1);
    }
  };

  useEffect(() => {
    setOnSelect(false);
    if (brands?.length === 1 && suggestionValue === brands[0]?.tiktokUserName) {
      setOnOptionSelect(true);
    } else {
      setOnOptionSelect(false);
    }
  }, [brands, suggestionValue]);

  useEffect(() => {
    if (suggestionValue === "") {
      setOnEnter(false);
      if (brandsData && brandsData.getBrandsList) {
        setBrands(formatBrandsList(brandsData.getBrandsList.accountData));
      }
    }
    setPageFilter(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionValue]);

  useEffect(() => {
    setLoaderPosition(false);
  }, [order, orderBy, correctedCategories, suggestionValue]);

  useEffect(() => {
    if (suggestionValue !== "") {
      fetchQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, pageFilter]);

  const csvDownload = () => {
    getDownloadBrandsCreatorsList({
      variables: {
        list: "Brand",
      },
    }).then((res) => {
      const response = res?.data?.getDownloadBrandsCreatorsList;
      if (response) {
        window.open(response.link, "_blank");
      }
    });
  };

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      setPageDropDown(parseInt(newValue));
      setLoaderPosition(true);
      setPageRecord(parseInt(newValue));
    }
  };

  let options = [];

  useEffect(() => {
    if (suggestionValue === "" && pageDropDown !== null) {
      setPage(pageDropDown);
    }
    if (suggestionValue !== "" && pageDropDown !== null) {
      setPageFilter(pageDropDown);
    }
  }, [pageDropDown, suggestionValue]);

  for (
    let pageValue = 1;
    pageValue <=
    (suggestionValue === ""
      ? count === 0
        ? 1
        : count
      : filterPageCount === 0
      ? 1
      : filterPageCount);
    pageValue++
  ) {
    options.push(pageValue);
  }

  const onKeyDown = (ev: any) => {
    if (
      ev.key === "Enter" &&
      ev.target.value <= options.length &&
      ev.target.value > 0
    ) {
      if (ev.target.value && suggestionValue === "" && pageDropDown !== null) {
        setPage(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
      if (ev.target.value && suggestionValue !== "" && pageDropDown !== null) {
        setPageFilter(parseInt(ev.target.value));
        setPageDropDown(parseInt(ev.target.value));
        setPageRecord(parseInt(ev.target.value));
      }
    }
  };

  return (
    <div>
      <BrandDataTablePresentational
        tableData={brandsData}
        brands={brands}
        headRows={headRows}
        handleRequestSort3={handleRequestSort3}
        order={order}
        orderBy={orderBy}
        count={count}
        page={page}
        setPage={setPage}
        correctedCategories={correctedCategories}
        selectCorrectedCategory={selecteCorrectedCategory}
        value={suggestionValue}
        setValue={setSuggestionValue}
        loading={filterLoading}
        fetchSuggestion={fetchQuery}
        suggestions={data}
        setSuggestions={setData}
        onSuggestionSelected={onSuggestionSelected}
        onEnter={OnEnterList}
        placeholder="Enter Brand Username"
        open={open}
        tableLoader={loading}
        position={position}
        setLoaderPosition={setLoaderPosition}
        filterPageCount={filterPageCount}
        setPageFilter={setPageFilter}
        pageFilter={pageFilter}
        tableRef={tableRef}
        setClear={setClear}
        setPageRecord={setPageRecord}
        startRecordNumber={spage}
        endRecordNumber={ePage}
        totalPage={totalP}
        csvDownload={csvDownload}
        totalFilterRecord={totalP}
        handleChange={handleChange}
        options={options}
        pageDropDown={pageDropDown}
        setPageDropDown={setPageDropDown}
        onKeyDown={onKeyDown}
        onOptionSelect={onOptionSelect}
      />
    </div>
  );
};

export default BrandDataTable;

const formatBrandsList = (BrandData: BrandData[]): BrandData[] => {
  return BrandData.map((v) => {
    return {
      ...v,
      tiktokUsernameStickyColumn: formatString(v.tiktokUserName),
      totalViews: formatNumber(v.totalViews),
      totalPosts: formatNumber(v.totalPosts),
      followers: formatNumber(v.followers),
    };
  });
};
