import React from "react";
import { Container } from "@mui/material";
import "@pages/media.css";
import IndividualBrandPagePresentational from "./IndividualBrandPage.presentational";

export const IndividualBrandPage = () => {
  return (
    <Container id="Container" className="mt-10">
      <IndividualBrandPagePresentational />
    </Container>
  );
};
