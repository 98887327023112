import React, { FunctionComponent } from "react";
import { useTheme } from "@mui/material";
import { Skeleton } from "@mui/lab";
import ChartCard from "@modules/Organisms/Charts/LineCharts/ChartCard";
import BarChart from "@modules/Organisms/Charts/BarCharts/barChart";
import { IndividualBrandChartStyles } from "./IndividualBrandChart.styles";
import { IndividualBrandChartProps } from "./IndividualBrandChart.types";
import FallBackChart from "@modules/Organisms/Charts/helper/FallBackChart";
import { formatNumber } from "@modules/utils/utils";
const IndividualBrandChartPresentational: FunctionComponent<
  IndividualBrandChartProps
> = ({ chartData, chartError, chartLoading, chartTotal }) => {
  const sx = IndividualBrandChartStyles(useTheme());
  return (
    <div id="container" style={{ display: "flex" }}>
      <div className="row" style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            {chartLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={sx.skeleton}
              />
            ) : (
              <>
                {chartData && chartData.length > 0 ? (
                  <ChartCard
                    header={"Views by Month"}
                    data={chartData}
                    xkey="dateId"
                    ykey="totalViews"
                    xLabel="Month"
                    yLabel="Total Views"
                    subHeader={`Total Views: ${
                      chartTotal && chartTotal.totalViewsAcross
                        ? formatNumber(chartTotal.totalViewsAcross)
                        : ""
                    }`}
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
          </div>
          <div>
            {chartLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={sx.skeleton}
              />
            ) : (
              <>
                {chartData && chartData.length > 0 ? (
                  <BarChart
                    header={"Total Creators Posting by Month"}
                    data={chartData}
                    classname="mr-4"
                    xkey="dateId"
                    ykey="totalCreatorsPosting"
                    xLabel="Month"
                    yLabel="Total Creators Posting"
                    subHeader={`Total Creators Posting: ${
                      chartTotal && chartTotal.totalCreators
                        ? formatNumber(chartTotal.totalCreators)
                        : ""
                    }`}
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
            {chartLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={sx.skeleton}
              />
            ) : (
              <>
                {chartData && chartData.length > 0 ? (
                  <BarChart
                    header={"Posts by Month"}
                    data={chartData}
                    xkey="dateId"
                    ykey="totalPosts"
                    xLabel="Month"
                    yLabel="Total Posts"
                    subHeader={`Total Posts: ${
                      chartTotal && chartTotal.totalPostAcross
                        ? formatNumber(chartTotal.totalPostAcross)
                        : ""
                    }`}
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualBrandChartPresentational;
