import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";
import SelectDropDownWithLabel from "@modules/Dropdowns/SelectDropDownWithLabel";
import { ChartHeaderProps } from "@modules/Organisms/Charts/charts.types";
import { chartHeaderStyles } from "./ChartHeader.styles";

const ChartHeader: FunctionComponent<ChartHeaderProps> = ({
  header,
  data,
  dropdownOption,
  selectedOption,
  setDropdownOptions,
  subHeader,
  filterKey,
}) => {
  const classes = chartHeaderStyles();
  return (
    <Box display="flex" justifyContent="space-between">
      <div>
        {header && (
          <Box className={classes.headingContainer}>
            <h2 className={classes.cardHeading}>{header}</h2>
          </Box>
        )}
        {header && data && data.length > 0 && (
          <Box className={`${classes.headingContainer} pb-6 pl-13`}>
            <h6>{subHeader}</h6>
          </Box>
        )}
      </div>
      {dropdownOption && setDropdownOptions && (
        <Box style={{ marginLeft: "auto" }}>
          <SelectDropDownWithLabel
            customClassname={classes.dropdown}
            options={dropdownOption}
            selected={selectedOption}
            onChange={(value) => {
              setDropdownOptions(filterKey, value);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChartHeader;
