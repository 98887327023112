import parsePhoneNumber from "libphonenumber-js";

export const formatPhoneNumber = (value: string): string => {
  if (value)
    return `+${parsePhoneNumber(value)?.countryCallingCode} ${parsePhoneNumber(
      value
    )?.formatNational()}`;
  return "-";
};

export const formatString = (value: string): string => {
  if (value) return value;
  return "-";
};

export const formatToDollarValue = (value: number | string) => {
  if (value) return "$" + value;
  return "-";
};

export const formatToPercentage = (value: number | string) => {
  if (value) return value + "%";
  return "-";
};

export const formatNumberToPercentage = (value: any) => {
  if (value)
    return Math.round((value * 100 + Number.EPSILON) * 100) / 100 + "%";
  return "-";
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};
