import React, { useState, useEffect, useRef } from "react";
import Autosuggest from "react-autosuggest";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useLazyQuery } from "@apollo/client";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { CreatorChartList } from "@Models/Creators";
import { makeStyles, StyleRules } from "@mui/styles";
import {
  GET_CREATOR_LIST,
  GET_CREATOR_DATA,
} from "@src/graphql/queries/getCreatorList";

interface Props {
  creatorId: string | null;
}

export const AutoSuggestBox = ({ creatorId }: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<CreatorChartList[]>([]);
  const timeout = useRef<any>(null);

  const [getCreatorsList, { data: creatorsList }] =
    useLazyQuery(GET_CREATOR_LIST);

  const [getCreatorData, { data: creatorsData }] =
    useLazyQuery(GET_CREATOR_DATA);

  const dispatch = useDispatch();

  useEffect(() => {
    if (creatorId && creatorId.length > 0) {
      getCreatorData({
        variables: {
          creatorId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = creatorsData?.getCreatorsDetails;
    if (data) {
      setValue(`${data.legalName} (${data.tiktokUsername})`);
    }
  }, [creatorsData]);

  useEffect(() => {
    const data = creatorsList?.searchCreators?.creatorData;
    if (data && data.length) {
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  }, [creatorsList]);

  const onChange = (event: any, { newValue }: { newValue: string }) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (newValue.length > 0) {
      timeout.current = setTimeout(() => {
        getCreatorsList({
          variables: {
            legalName: newValue,
            pageNumber: 1
          },
        });
      }, 500);
    } else {
      setSuggestions([]);
    }
    setValue(newValue);
  };

  const getSuggestionValue = (suggestion: CreatorChartList) =>
    suggestion.tiktokUsername
      ? `${suggestion.legalName} (${suggestion.tiktokUsername})`
      : suggestion.legalName;

  const onSuggestionsFetchRequested = ({ value, reason }: any) => {
    if (reason === "suggestion-selected") {
      setSuggestions([]);
    }
  };
  const onSuggestionSelected = (
    event: any,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any
  ) => {
    dispatch({ type: "SELECT_CREATOR", payload: suggestion.creatorId });
  };

  const renderSuggestion = (suggestion: CreatorChartList) => (
    <p className={classes.name}>
      {suggestion.tiktokUsername
        ? `${suggestion.legalName} (${suggestion.tiktokUsername})`
        : suggestion.legalName}
    </p>
  );

  const renderInputComponent = (inputProps: any) => (
    <div>
      <input {...inputProps} />
    </div>
  );

  const clearSuggestions = () => {
    setValue("");
    setSuggestions([]);
    dispatch({ type: "SELECT_CREATOR", payload: "" });
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }: any) => {
    return (
      <div {...containerProps}>
        <strong>{children}</strong>
      </div>
    );
  };

  const inputProps = {
    placeholder: "Enter creator name.",
    value,
    onChange: onChange,
    onBlur: () => setSuggestions([]),
  };

  return (
    <div className={classes.outer}>
      <Autosuggest
        theme={{
          input: classes.input,
          inputFocused: classes.inputFocused,
          suggestionsContainer: classes.suggestionsContainer,
          suggestion: classes.suggestion,
          suggestionHighlighted: classes.suggestionHighlighted,
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        alwaysRenderSuggestions={true}
        highlightFirstSuggestion={true}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
      <div className={classes.search}>
        <i style={{ color: "#a494cd" }} className="fa fa-search"></i>
      </div>
      {value !== "" && (
        <div className={classes.close} onClick={clearSuggestions}>
          <i
            className="far fa-times-circle fa-lg"
            style={{ color: "#a494cd" }}
          ></i>
        </div>
      )}
    </div>
  );
};

const styles = (theme: Theme) => {
  const style: StyleRules = {
    outer: {
      position: "relative",
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
    },
    search: {
      position: "absolute",
      zIndex: 1000,
      top: 16,
      left: 18,
    },
    close: {
      position: "absolute",
      zIndex: 1000,
      top: 14,
      left: 280,
      fontSize: "14px",
      cursor: "pointer",
    },
    input: {
      zIndex: 1,
      top: 0,
      border: "none",
      borderRadius: "8px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: getColorByTheme(theme, allColors.light3, allColors.dark11),
      padding: "14px",
      paddingRight: "41px",
      paddingLeft: "40px",
      width: "320px",
    },
    inputFocused: {
      outlineStyle: "none",
    },
    suggestionsContainer: {
      position: "absolute",
      background: getColorByTheme(theme, allColors.light3, allColors.dark11),
      boxSizing: "border-box",
      borderRadius: "8px",
      width: "320px",
      zIndex: 1000,
    },
    suggestion: {
      padding: "10px 150px 10px 18px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      listStyleType: "none",
      marginLeft: "-40px",
      cursor: "pointer",
    },
    suggestionHighlighted: {
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: "rgba(137,65,255, 0.3)",
      borderRadius: "8px",
      mixBlendMode: "normal",
    },
    name: {
      margin: "auto",
    },
  };
  return style;
};

const useStyles = makeStyles(styles);
