import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "@modules/Auth/_redux/authRedux";
import { customersSlice } from "@modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "@modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "@modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "@modules/ECommerce/_redux/specifications/specificationsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { Creator, CreatorState } from "@Models/Creators";

const initialState = {
  darkMode: false,
};

const modeReducer = (state = initialState, action: PayloadAction<boolean>) => {
  switch (action.type) {
    case "SWITCH_MODE":
      return { darkMode: action.payload };
    default:
      return state;
  }
};

export const creatorHeaderCheckboxReducer = (
  state = { data: [], creator: [] },
  action: PayloadAction<any>
): any => {
  switch (action.type) {
    case "[creatorSelect] Action":
      const selectedCreator = state.creator.findIndex(
        (v: Creator) => v.creatorId === action.payload.newSelecteds.creatorId
      );
      return {
        ...state,
        creator:
          selectedCreator >= 0
            ? state.creator
            : state.creator.concat(action.payload.newSelecteds),
      };
    case "[creatorDeselect] Action":
      const deselectedCreator = state.creator.map((v: Creator) => {
        return v.creatorId;
      });
      if (deselectedCreator.includes(action.payload.newSelecteds.creatorId)) {
        return {
          ...state,
          creator: state.creator.filter(
            (creator: Creator) =>
              creator.creatorId !== action.payload.newSelecteds.creatorId
          ),
        };
      }
      break;
    case "[creatorArraySelect] Action":
      const selectedCreatorArray = state.creator.map((v: Creator) => {
        return v.creatorId;
      });

      const addSelectedCreatorToArray: any = action.payload.newSelecteds.filter(
        (creator: Creator) => !selectedCreatorArray.includes(creator.creatorId)
      );

      return {
        ...state,
        creator: state.creator.concat(addSelectedCreatorToArray),
      };
    case "[creatorArrayDeselect] Action":
      const deselectedCreatorArray = action.payload.newSelecteds.map(
        (v: Creator) => {
          return v.creatorId;
        }
      );
      return {
        ...state,
        creator: state.creator.filter(
          (creator: Creator) =>
            !deselectedCreatorArray.includes(creator.creatorId)
        ),
      };
    default:
      return state;
  }
};

const creatorsReducer = (
  state = [],
  action: PayloadAction<CreatorState>
): any => {
  switch (action.type) {
    case "GET_CREATOR_CHART_DATA_RECORD":
      return {
        ...state,
        creatorChartData: action.payload,
      };
    case "GET_CREATORS_LIST":
      return {
        ...state,
        creatorList: action.payload,
      };
    case "SELECT_CREATOR":
      return {
        ...state,
        selectedCreator: action.payload,
      };
    case "CREATORS_LIST":
      return {
        ...state,
        creatorsList: action.payload,
      };
    case "FILTER_OPTIONS":
      return {
        ...state,
        filterOptions: action.payload,
      };
    case "TOTAL_STATS":
      return {
        ...state,
        totalStats: action.payload,
      };
    case "CREATOR_AVG_COUNTS":
      return {
        ...state,
        creatorAvgCounts: action.payload,
      };
    default:
      return state;
  }
};

export const brandReducer = (state = [], action: PayloadAction<any>): any => {
  switch (action.type) {
    case "FILTER_OPTIONS":
      return {
        ...state,
        filterOptions: action.payload,
      };
    default:
      return state;
  }
};
export const pathReducer = (state = [], action: PayloadAction<any>): any => {
  switch (action.type) {
    case "INITIAL_PATH":
      localStorage.setItem("initialPath", action.payload);
      return {
        ...state,
        path: action.payload,
      };
    default:
      return state;
  }
};
export const sponsoredCreatorFilterReducer = (
  state = { data: [] },
  action: PayloadAction<any>
): any => {
  switch (action.type) {
    case "SPONSORED_CREATOR_FILTER":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export const brandFilterReducer = (
  state = { data: [] },
  action: PayloadAction<any>
): any => {
  switch (action.type) {
    case "BRAND_FILTER":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  modeReducer,
  creators: creatorsReducer,
  brand: brandReducer,
  initialPath: pathReducer,
  sponsoredCreatorFilter: sponsoredCreatorFilterReducer,
  brandFilter: brandFilterReducer,
  creatorHeaderCheckbox: creatorHeaderCheckboxReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga()]);
}
