import { Theme } from "@mui/material/styles";

export const formatNumber = (value: string | number) => {
  const n = Number(value);
  // toLocaleString => 10000 => 10,000
  if (n || n === 0) {
    return Math.round(n).toLocaleString();
  } else {
    return "-";
  }
};

export const getColorByTheme = (theme: Theme, light: any, dark: any) => {
  return theme.palette.mode === "light" ? light : dark;
};

export const getSorting = (
  sortOrder: string,
  order: "asc" | "desc" | undefined,
  orderBy: string
) => {
  return sortOrder !== orderBy ? 0 : order === "asc" ? 1 : -1;
};
