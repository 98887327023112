const list = [
  {
    id: "1",
    genre: "Analyst",
  },
  {
    id: "2",
    genre: "Business",
  },
  {
    id: "3",
    genre: "Customer Service",
  },
  {
    id: "4",
    genre: "Driver",
    subGenre: [
      {
        id: "4.1",
        genre: "Ride-Share Driver",
      },
      {
        id: "4.2",
        genre: "Truck Driver",
      },
    ],
  },
  {
    id: "5",
    genre: "Educator",
  },
  {
    id: "6",
    genre: "Engineer",
  },
  {
    id: "7",
    genre: "Entrepreneur",
  },
  {
    id: "8",
    genre: "Farming",
  },
  {
    id: "9",
    genre: "Finance",
    subGenre: [
      {
        id: "9.1",
        genre: "Crypto",
      },
      {
        id: "9.2",
        genre: "Real Estate",
      },
      {
        id: "9.3",
        genre: "Stocks",
      },
    ],
  },
  {
    id: "10",
    genre: "First Responder",
  },
  {
    id: "11",
    genre: "Fisherman",
  },
  {
    id: "12",
    genre: "Food Service",
  },
  {
    id: "13",
    genre: "Gamer",
    subGenre: [
      {
        id: "13.1",
        genre: "Pro Gamer",
      },
    ],
  },
  {
    id: "14",
    genre: "Government",
  },
  {
    id: "15",
    genre: "Healthcare",
    subGenre: [
      {
        id: "15.1",
        genre: "Alternative Medicine",
      },
      {
        id: "15.2",
        genre: "Chiropractor",
      },
      {
        id: "15.3",
        genre: "Dental",
        subGenre: [{ id: "15.3.1", genre: "Dentist" }],
      },
      {
        id: "15.4",
        genre: "Dietician",
      },
      {
        id: "15.5",
        genre: "Doctor",
      },
      {
        id: "15.6",
        genre: "Massage",
      },
      {
        id: "15.7",
        genre: "Nurse",
      },
      {
        id: "15.8",
        genre: "Physical Therapy",
      },
      {
        id: "15.9",
        genre: "Speech-Language Pathologist",
      },
      {
        id: "15.10",
        genre: "Mental Health",
        subGenre: [{ id: "15.10.1", genre: "Therapist" }],
      },
      {
        id: "15.11",
        genre: "Veterinary",
      },
    ],
  },
  {
    id: "16",
    genre: "Insurance",
  },
  {
    id: "17",
    genre: "IT",
  },
  {
    id: "18",
    genre: "Legal",
  },
  {
    id: "19",
    genre: "Marketing",
  },
  {
    id: "20",
    genre: "Military",
  },
  {
    id: "21",
    genre: "Office Worker",
  },
  {
    id: "23",
    genre: "Pilot",
  },
  {
    id: "24",
    genre: "Real Estate",
  },
  {
    id: "25",
    genre: "Student",
    subGenre: [
      {
        id: "25.1",
        genre: "High school",
      },
      {
        id: "25.2",
        genre: "College",
      },
      {
        id: "25.3",
        genre: "Graduate School",
      },
    ],
  },
  {
    id: "26",
    genre: "Trades work",
    subGenre: [
      {
        id: "26.1",
        genre: "Builder / Construction / Carpenter / Masonry",
      },
      {
        id: "26.2",
        genre: "Electrician",
      },
      {
        id: "26.3",
        genre: "Mechanic",
      },
      {
        id: "26.4",
        genre: "Plumber",
      },
      {
        id: "26.5",
        genre: "Welder",
      },
    ],
  },
  {
    id: "27",
    genre: "Travel",
  },
  {
    id: "28",
    genre: "Vlog/Writer",
  },
  {
    id: "29",
    genre: "Fitness",
    subGenre: [
      {
        id: "29.1",
        genre: "Amateur Fitness",
        subGenre: [
          {
            id: "29.1.1",
            genre: "Pilates",
          },
          {
            id: "29.1.2",
            genre: "Weight Loss",
          },
          {
            id: "29.1.3",
            genre: "Weight Training",
          },
          {
            id: "29.1.4",
            genre: "Yoga",
          },
        ],
      },
      {
        id: "29.2",
        genre: "Bodybuiliding",
      },
      {
        id: "29.3",
        genre: "Professional Fitness/Personal Training",
      },
    ],
  },
  {
    id: "30",
    genre: "Sports",
    subGenre: [
      {
        id: "30.1",
        genre: "Amateur Athlete",
      },
      {
        id: "30.2",
        genre: "Badminton",
      },
      {
        id: "30.3",
        genre: "Baseball",
      },
      {
        id: "30.4",
        genre: "Basketball",
      },
      {
        id: "30.5",
        genre: "Baton Twirling",
      },
      {
        id: "30.6",
        genre: "Billiards/Pool Player",
      },
      {
        id: "30.7",
        genre: "Bowling",
      },
      {
        id: "30.8",
        genre: "Cheerleading",
      },
      {
        id: "30.9",
        genre: "Circus",
      },
      {
        id: "30.10",
        genre: "Coach",
      },
      {
        id: "30.11",
        genre: "College Athlete",
      },
      {
        id: "30.12",
        genre: "Cricket",
      },
      {
        id: "30.13",
        genre: "Cycle",
      },
      {
        id: "30.14",
        genre: "Equestrian",
      },
      {
        id: "30.15",
        genre: "Fencing",
      },
      {
        id: "30.16",
        genre: "Football - American",
      },
      {
        id: "30.17",
        genre: "Frisbee",
      },
      {
        id: "30.18",
        genre: "Golf",
      },
      {
        id: "30.19",
        genre: "Gymnastics",
      },
      {
        id: "30.20",
        genre: "Hacky Sack",
      },
      {
        id: "30.21",
        genre: "Handball",
      },
      {
        id: "30.22",
        genre: "Hockey",
      },
      {
        id: "30.23",
        genre: "Hula Hoop",
      },
      {
        id: "30.24",
        genre: "Jump Rope",
      },
      {
        id: "30.25",
        genre: "Lacrosse",
      },
      {
        id: "30.26",
        genre: "Martial Arts",
      },
      {
        id: "30.27",
        genre: "Olympian",
      },
      {
        id: "30.28",
        genre: "Parkour",
      },
      {
        id: "30.29",
        genre: "Pickleball",
      },
      {
        id: "30.30",
        genre: "Ping Pong",
      },
      {
        id: "30.31",
        genre: "Pogo Stick",
      },
      {
        id: "30.32",
        genre: "Pool Sports",
      },
      {
        id: "30.33",
        genre: "Professional Athlete",
      },
      {
        id: "30.34",
        genre: "Racquetball",
      },
      {
        id: "30.35",
        genre: "Professional Athlete",
      },
      {
        id: "30.36",
        genre: "Rugby",
      },
      {
        id: "30.37",
        genre: "Running",
      },
      {
        id: "30.38",
        genre: "Shooting",
        subGenre: [
          {
            id: "30.38.1",
            genre: "Air soft",
          },
          {
            id: "30.38.2",
            genre: "Archery",
          },
          {
            id: "30.38.3",
            genre: "Paintball",
          },
          {
            id: "30.38.4",
            genre: "Firearms",
          },
        ],
      },
      {
        id: "30.39",
        genre: "Skating",
        subGenre: [
          {
            id: "30.39.1",
            genre: "Figure/Ice Skating",
          },
          {
            id: "30.39.2",
            genre: "Roller Blading/Skating",
          },
          {
            id: "30.39.3",
            genre: "Skateboarding",
          },
        ],
      },
      {
        id: "30.40",
        genre: "Soccer/Futbol",
      },
      {
        id: "30.41",
        genre: "Sports Commentator",
      },
      {
        id: "30.42",
        genre: "Squash",
      },
      {
        id: "30.43",
        genre: "Tennis",
      },
      {
        id: "30.44",
        genre: "Track & Field",
      },
      {
        id: "30.45",
        genre: "Trampoline",
      },
      {
        id: "30.46",
        genre: "Volleyball",
      },
      {
        id: "30.47",
        genre: "Watersports",
        subGenre: [
          {
            id: "30.47.1",
            genre: "Kayaking",
          },
          {
            id: "30.47.2",
            genre: "Kiteboarding",
          },
          {
            id: "30.47.3",
            genre: "SCUBA",
          },
          {
            id: "30.47.4",
            genre: "Snorkel",
          },
          {
            id: "30.47.5",
            genre: "Surfing",
          },
          {
            id: "30.47.6",
            genre: "Tubing",
          },
          {
            id: "30.47.7",
            genre: "Wakeboarding",
          },
          {
            id: "30.47.8",
            genre: "Waterskiing",
          },
          {
            id: "30.47.9",
            genre: "White Water Rafting",
          },
        ],
      },
      {
        id: "30.48",
        genre: "Winter Sports",
        subGenre: [
          {
            id: "30.48.1",
            genre: "Boarding",
          },
          {
            id: "30.48.2",
            genre: "Skiing",
          },
        ],
      },
    ],
  },
  {
    id: "31",
    genre: "Adventure/Outdoors",
  },
  {
    id: "32",
    genre: "Performance Skills",
    subGenre: [
      {
        id: "32.1",
        genre: "Dance",
        subGenre: [
          {
            id: "32.1.1",
            genre: "Ballet",
          },
          {
            id: "32.1.2",
            genre: "Ballroom",
          },
          {
            id: "32.1.3",
            genre: "Cultural",
          },
          {
            id: "32.1.4",
            genre: "Hip Hop",
          },
          {
            id: "32.1.5",
            genre: "Pole/Acro",
          },
        ],
      },
      {
        id: "32.2",
        genre: "Music",
        subGenre: [
          {
            id: "32.2.1",
            genre: "DJ",
          },
          {
            id: "32.2.2",
            genre: "Instrumentalist",
          },
          {
            id: "32.2.3",
            genre: "Rapper/Singer/Songwriter",
          },
        ],
      },
      {
        id: "32.3",
        genre: "TV/Film",
        subGenre: [
          {
            id: "32.3.1",
            genre: "Actor/Actress",
          },
          {
            id: "32.3.2",
            genre: "Adult Film",
          },
          {
            id: "32.3.3",
            genre: "Cartoons",
            subGenre: [
              {
                id: "32.3.3.1",
                genre: "Adult Cartoons",
              },
              {
                id: "32.3.3.2",
                genre: "Anime",
              },
              {
                id: "32.3.3.3",
                genre: "Kids Cartoons",
              },
            ],
          },
          {
            id: "32.3.4",
            genre: "Comedy",
            subGenre: [
              {
                id: "32.3.4.1",
                genre: "Sketch Comedy",
              },
              {
                id: "32.3.4.2",
                genre: "Standup",
              },
            ],
          },
          {
            id: "32.3.5",
            genre: "Editing",
          },
          {
            id: "32.3.6",
            genre: "Filmmaker",
          },
          {
            id: "32.3.7",
            genre: "Journalist",
          },
          {
            id: "32.3.8",
            genre: "Niche Film/TV",
          },
          {
            id: "32.3.9",
            genre: "Podcast",
          },
          {
            id: "32.3.10",
            genre: "Theatre",
          },
          {
            id: "32.3.11",
            genre: "Voiceover",
          },
        ],
      },
    ],
  },
  {
    id: "33",
    genre: "Content",
    subGenre: [
      {
        id: "33.1",
        genre: "Alternative",
        subGenre: [
          {
            id: "33.1.1",
            genre: "Drinking",
          },
          {
            id: "33.1.2",
            genre: "Drugs",
          },
          {
            id: "33.1.3",
            genre: "E-Boy/E-Girl",
          },
          {
            id: "33.1.4",
            genre: "Goth",
          },
          {
            id: "33.1.5",
            genre: "Piercings",
          },
          {
            id: "33.1.6",
            genre: "Tattoos",
          },
        ],
      },
      {
        id: "33.2",
        genre: "Animals",
        subGenre: [
          {
            id: "33.2.1",
            genre: "Exotic Animals",
          },
          {
            id: "33.2.2",
            genre: "Pets",
            subGenre: [
              {
                id: "33.2.2.1",
                genre: "Bird",
              },
              {
                id: "33.2.2.2",
                genre: "Cat",
              },
              {
                id: "33.2.2.3",
                genre: "Dog",
              },
              {
                id: "33.2.2.4",
                genre: "Farm",
              },
              {
                id: "33.2.2.5",
                genre: "Fish",
              },
              {
                id: "33.2.2.6",
                genre: "Reptile",
              },
            ],
          },
        ],
      },
      {
        id: "33.3",
        genre: "Art",
        subGenre: [
          {
            id: "33.3.1",
            genre: "Digital Art",
          },
          {
            id: "33.3.2",
            genre: "Film",
          },
          {
            id: "33.3.3",
            genre: "Physical Art",
          },
          {
            id: "33.3.4",
            genre: "Photography",
          },
        ],
      },
      {
        id: "33.4",
        genre: "ASMR/Satisfying",
      },
      {
        id: "33.5",
        genre: "Astrology",
      },
      {
        id: "33.6",
        genre: "Automotive",
      },
      {
        id: "33.7",
        genre: "Beauty",
        subGenre: [
          {
            id: "33.7.1",
            genre: "Body Positivity",
          },
          {
            id: "33.7.2",
            genre: "Hair",
          },
          {
            id: "33.7.3",
            genre: "Makeup",
          },
          {
            id: "33.7.4",
            genre: "Nails/Nail Technician",
          },
          {
            id: "33.7.5",
            genre: "Skincare/Esthetician",
          },
        ],
      },
      {
        id: "33.8",
        genre: "Books/Literature",
      },
      {
        id: "33.9",
        genre: "DIY",
      },
      {
        id: "33.10",
        genre: "Educational/How-to",
        subGenre: [
          {
            id: "33.10.1",
            genre: "Career Advice",
          },
          {
            id: "33.10.2",
            genre: "Clean/Organize",
          },
          {
            id: "33.10.3",
            genre: "Hacks",
          },
          {
            id: "33.10.4",
            genre: "Home Improvement",
          },
          {
            id: "33.10.5",
            genre: "Life Advice",
          },
          {
            id: "33.10.6",
            genre: "Money",
          },
          {
            id: "33.10.7",
            genre: "Relationship",
          },
        ],
      },
      {
        id: "33.11",
        genre: "Electronics",
      },
      {
        id: "33.12",
        genre: "Family",
        subGenre: [
          {
            id: "33.12.1",
            genre: "Couples/Relationship",
            subGenre: [
              { id: "33.12.1.1", genre: "Opposite Sex Couple" },
              { id: "33.12.1.2", genre: "Same Sex Couple" },
            ],
          },
          { id: "33.12.2", genre: "Dad" },
          { id: "33.12.3", genre: "Mom" },
          {
            id: "33.12.4",
            genre: "Parents of Kids",
            subGenre: [
              { id: "33.12.4.1", genre: "Aged 0-2" },
              { id: "33.12.4.2", genre: "Aged 2-6" },
              { id: "33.12.4.3", genre: "Aged 7-12" },
              { id: "33.12.4.4", genre: "Aged 13-16" },
              { id: "33.12.4.5", genre: "Aged 17-20" },
            ],
          },
          { id: "33.12.5", genre: "Pregnancy" },
          { id: "33.12.6", genre: "Single Parent" },
          { id: "33.12.7", genre: "Triplets +" },
          { id: "33.12.8", genre: "Twins" },
        ],
      },
      {
        id: "33.13",
        genre: "Fashion",
        subGenre: [
          {
            id: "33.13.1",
            genre: "Bargain/Thrift",
          },
          { id: "33.13.2", genre: "Cosplay" },
          { id: "33.13.3", genre: "Design" },
          {
            id: "33.13.4",
            genre: "Hauls",
          },
          { id: "33.13.5", genre: "Luxury" },
          { id: "33.13.6", genre: "Model" },
          { id: "33.13.7", genre: "Shoes" },
        ],
      },
      {
        id: "33.14",
        genre: "Festival",
      },
      {
        id: "33.15",
        genre: "Food/Cooking",
        subGenre: [
          {
            id: "33.15.1",
            genre: "Baking",
          },
          {
            id: "33.15.2",
            genre: "Cooking",
            subGenre: [{ id: "33.14.2.1", genre: "Healthy Cooking" }],
          },
          { id: "33.15.3", genre: "Dietary Restrictions" },
          {
            id: "33.15.4",
            genre: "Eating",
          },
          { id: "33.15.5", genre: "Restaurant Recommendations" },
        ],
      },
      {
        id: "33.16",
        genre: "Home and Garden",
        subGenre: [
          {
            id: "33.16.1",
            genre: "Décor/Design",
          },
          {
            id: "33.16.2",
            genre: "Gardening",
          },
          { id: "33.16.3", genre: "Interior Design" },
          {
            id: "33.16.4",
            genre: "Remodel/Architecture",
          },
        ],
      },
      {
        id: "33.17",
        genre: "LGBTQ+",
      },
      {
        id: "33.18",
        genre: "Lifestyle",
        subGenre: [
          {
            id: "33.18.1",
            genre: "City",
          },
          {
            id: "33.18.2",
            genre: "Rural",
          },
          { id: "33.18.3", genre: "Small Town" },
        ],
      },
      {
        id: "33.19",
        genre: "Mystery/Paranormal",
      },
      {
        id: "33.20",
        genre: "News/Gossip",
        subGenre: [
          {
            id: "33.20.1",
            genre: "Gaming News",
          },
          {
            id: "33.20.2",
            genre: "Local News",
          },
          { id: "33.20.3", genre: "Pop Culture News" },
          {
            id: "33.20.4",
            genre: "World News",
          },
        ],
      },
      {
        id: "33.21",
        genre: "Politics",
        subGenre: [
          {
            id: "33.21.1",
            genre: "Democrat",
          },
          {
            id: "33.21.2",
            genre: "Republican",
          },
          { id: "33.21.3", genre: "Other" },
          {
            id: "33.21.4",
            genre: "Social Justice",
          },
        ],
      },
      {
        id: "33.22",
        genre: "POV",
      },
      {
        id: "33.23",
        genre: "Romance",
        subGenre: [
          {
            id: "33.23.1",
            genre: "Dating",
          },
          {
            id: "33.23.2",
            genre: "Marriage",
          },
        ],
      },
      {
        id: "33.24",
        genre: "Sewing/Textiles",
      },
      {
        id: "33.25",
        genre: "Spirituality/Religion",
      },
      {
        id: "33.26",
        genre: "Street Interviews",
      },
      {
        id: "33.27",
        genre: "Talking Creator",
        subGenre: [
          {
            id: "33.27.1",
            genre: "Narrated",
          },
          {
            id: "33.27.2",
            genre: "Talking Head",
          },
        ],
      },
      {
        id: "33.28",
        genre: "Travel",
        subGenre: [
          {
            id: "33.28.1",
            genre: "Domestic",
          },
          {
            id: "33.28.2",
            genre: "International",
          },
        ],
      },
      {
        id: "33.29",
        genre: "Trends",
        subGenre: [
          {
            id: "33.29.1",
            genre: "Dance Trends",
          },
        ],
      },
      {
        id: "33.30",
        genre: "Reaction Videos",
        subGenre: [
          {
            id: "33.30.1",
            genre: "Duet",
          },
          {
            id: "33.30.2",
            genre: "Stitch",
          },
        ],
      },
      {
        id: "33.31",
        genre: "Recommendations/Reviews",
        subGenre: [
          {
            id: "33.31.1",
            genre: "Ecommerce",
          },
          {
            id: "33.31.2",
            genre: "Unboxings",
          },
        ],
      },
      {
        id: "33.32",
        genre: "UGC",
      },
    ],
  },
];

export default list;
