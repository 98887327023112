/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { InputAdornment, useTheme } from "@mui/material";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";

interface AutoCompleteCategoryProps {
  correctedCategory: string[];
  id: string;
  onAdd: (...args: any) => void;
  placeholder?: string;
  icon?: JSX.Element;
  customStyle?: any;
  options: any[];
}

const AutoCompleteCategory = ({
  correctedCategory,
  id,
  onAdd,
  placeholder,
  icon,
  customStyle,
  options,
}: AutoCompleteCategoryProps) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const [value, setValue] = React.useState<string[]>([]);
  useEffect(() => {
    if (!!correctedCategory && correctedCategory.length > 0) {
      setValue(correctedCategory);
    }
  }, [correctedCategory]);

  const textColor = getColorByTheme(theme, allColors.dark1, allColors.light1);
  const deleteIconColor = getColorByTheme(theme, "auto", allColors.light9);
  const styles = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: textColor,
            borderColor: "transparent",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "& .MuiSvgIcon-root": { color: textColor },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: textColor,
            "& .MuiChip-deleteIcon": {
              color: deleteIconColor,
            },
          },
        },
      },
    },
  });
  return (
    <>
      <Autocomplete
        style={customStyle}
        classes={classes}
        ListboxProps={{
          style: {
            background: getColorByTheme(
              theme,
              allColors.light1,
              allColors.dark3
            ),
          },
        }}
        multiple
        id="tags-outlined"
        options={options}
        getOptionLabel={(option) => option}
        value={value}
        onChange={(_, value) => {
          setValue(value as string[]);
          if (!!value) {
            onAdd(id, value);
          }
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <ThemeProvider theme={styles}>
            <TextField
              variant="outlined"
              placeholder={placeholder ? placeholder : "Search Categories"}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <span>{icon}</span>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              label=""
            />
          </ThemeProvider>
        )}
      />
    </>
  );
};
export default AutoCompleteCategory;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "red",
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    overflow: "hidden",
    background: getColorByTheme(theme, allColors.light3, allColors.dark11),
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-tag": {
      background: getColorByTheme(theme, allColors.light2, allColors.dark10),
      fontSize: ".9rem",
      cursor: "pointer",
    },
  },
}));
