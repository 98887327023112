import { gql } from "@apollo/client";

const ADMIN_LOGIN = gql`
  query onAdminLog($email: String, $name: String) {
    adminLogin(email: $email, name: $name) {
      success
      message
    }
  }
`;

const ADMIN_VERIFY_OTP = gql`
  mutation MyMutation($email: String!, $otp: String!, $password: String) {
    adminOtpVerify(email: $email, otp: $otp, password: $password) {
      data {
        email
        accessToken
        idToken
        name
        phoneNumber
      }
      success
      message
      status
      errorMessage
    }
  }
`;

export { ADMIN_LOGIN, ADMIN_VERIFY_OTP };
