import { gql } from "@apollo/client";
const GET_CATEGORY_CHART = gql`
  query ongetCategoriesChart($input: CategoryChartInput!) {
    getCategoriesChart(input: $input) {
      monthlySpentByCategory {
        dateId
        category
        totalSpent
      }
      totalViewsByCategory {
        dateId
        category
        totalViewsPurchased
      }
      totalSpentByMonth {
        dateId
        category
        totalSpent
      }
      totalSonseredPostByMonth {
        dateId
        category
        totalSponseredPost
      }
      totalCreatorByCategory {
        category
        totalCreators
      }
      totalBrandsByCategory {
        category
        totalBrands
      }
    }
  }
`;
export { GET_CATEGORY_CHART };
