import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React, { FunctionComponent } from "react";
import IndividualBrandTab from "@modules/Organisms/Tab/SimpleTab";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";
import { IndividualBrandCard } from "@modules/Brands/Brand/Individual/card/IndividualBrandCard";
import IndividualBrandChart from "@modules/Brands/Brand/Individual/chart/IndividualBrandChart";
import BrandCreatorDetailsTable from "@modules/Brands/Brand/Individual/grid/BrandCreatorDetailsTable";
import BrandPostDetailsTable from "@modules/Brands/Brand/Individual/grid/BrandPostDetailsTable";

const IndividualBrandPagePresentational: FunctionComponent = () => {
  const theme: Theme = useTheme();
  return (
    <Box
      width="100%"
      position="relative"
      className="mt-10"
      color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
    >
      <div style={{ display: "flex" }}>
        <IndividualBrandCard />
        <IndividualBrandTab
          totalTabs={3}
          tabs={[
            {
              label: "Overview",
              component: <IndividualBrandChart />,
            },
            {
              label: "Creator",
              component: <BrandCreatorDetailsTable />,
            },
            {
              label: "Posts",
              component: <BrandPostDetailsTable />,
            },
          ]}
        />
      </div>
    </Box>
  );
};

export default IndividualBrandPagePresentational;
