import * as React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import Brightness from "@assets/brightness.svg";
import HalfMoon from "@assets/halfMoon.svg";
import { RootState } from "@src/redux/rootReducer";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 30,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 2,
    color: "#EFE7FC",
    "&.Mui-checked": {
      transform: "translateX(29px)",
      color: "#3A6A80 !important",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1B3845 !important",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 26,
    height: 26,
    boxShadow: "none !important",
  },
  "& .MuiSwitch-track": {
    borderRadius: 100,
    opacity: 1,
    backgroundColor: "#D6C5F1",
    boxShadow: "none",
  },
}));

const ThemeSwitch = () => {
  const darkMode = useSelector(
    (state: RootState) => state.modeReducer.darkMode
  );
  const dispatch = useDispatch();
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <img src={Brightness} alt="lightmode" />
        </Grid>
        <Grid item>
          <AntSwitch
            checked={darkMode}
            onChange={(e) =>
              dispatch({
                type: "SWITCH_MODE",
                payload: e.target.checked,
              })
            }
          />
        </Grid>
        <Grid item>
          <img src={HalfMoon} alt="darkmode" />
        </Grid>
      </Grid>
    </Typography>
  );
};

export default ThemeSwitch;
