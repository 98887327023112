import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import SponsoredCreatorPresentational from "./SponsoredCreator.presentational";
import "@pages/media.css";
import Sidebar from "@modules/SideBar/Sidebar";
import { Header } from "@modules/Header/Header";

export const SponsoredCreatorPage = () => {
  const theme: Theme = useTheme();


  const [creatorChartData, setCreatorChartData] = useState([]);
  const [creatorChartTotal, setCreatorChartTotal] = useState({
    totalPaidCreators: 0,
    totalEarning: 0,
    totalViewsPurchased: 0,
    totalSponsoredPost: 0,
  });
  const [dateState, setDateState] = React.useState({
    startDate: new Date("01/01/2021"),
    endDate: new Date(),
    key: "selection",
  });

  // PLS DO NOT DELETE

  // useEffect(() => {
  //   if (
  //     data &&
  //     data.getTopCreatorsChart &&
  //     data.getTopCreatorsChart.chartValue
  //   ) {
  //     const charts = data.getTopCreatorsChart.chartValue.map((c: any) => {
  //       return {
  //         ...c,
  //         totalViews: parseInt(c.totalViews),
  //         totalCpm: parseInt(c.totalCpm),
  //       };
  //     });
  //     setCreatorChartData(charts);
  //     setCreatorChartTotal(data.getTopCreatorsChart?.creatorTotal);
  //   }
  // }, [loading, data]);
  // useEffect(() => {
  //   let sMonth = new Date(dateState.startDate).getMonth() + 1;
  //   let eMonth = new Date(dateState.endDate).getMonth() + 1;
  //   let startMon = "";
  //   let endMon = "";
  //   if (sMonth < 10) {
  //     startMon = `0${sMonth}`;
  //   } else {
  //     startMon = sMonth.toString();
  //   }
  //   if (eMonth < 10) {
  //     endMon = `0${eMonth}`;
  //   } else {
  //     endMon = eMonth.toString();
  //   }
  //   const startMonth = `${new Date(
  //     dateState.startDate
  //   ).getFullYear()}/${startMon}`;
  //   const endMonth = `${new Date(dateState.endDate).getFullYear()}/${endMon}`;
  //   if (startMonth && endMonth) {
  //     getCreatorChart({
  //       variables: {
  //         firstMonth: startMonth,
  //         lastMonth: endMonth,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dateState.startDate, dateState.endDate]);

  const datePickerHandler = (start?: Date, end?: Date) => {
    if (start) {
      setDateState((prev: any) => ({ ...prev, startDate: start }));
    }
    if (end) {
      setDateState((prev: any) => ({ ...prev, endDate: end }));
    }
  };
  return (
    <Sidebar>
      <Container id="Container">
        <Header />
        <Box
          width="100%"
          position="relative"
          color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
        >
          <SponsoredCreatorPresentational
            chartData={creatorChartData}
            chartLoading={false}
            chartTotal={creatorChartTotal}
            datePickerHandler={datePickerHandler}
            dateState={dateState}
          />
        </Box>
      </Container>
    </Sidebar>
  );
};
