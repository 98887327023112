const allColors = {
  purple: "#8941FF",
  purple1: "rgba(51, 47, 103, 0.643218)",
  purple2: "rgba(51, 47, 103, 0.443218)",
  purple3: "rgba(137,65,255, 0.3)",

  light1: "#ffffff",
  light2: "#e5e5e5",
  light3: "#F9F5FF",
  light4: "#E3D9F3",
  light5: "#d8c6f5",
  light6: "#EFE7FC",
  light7: "rgb(132, 144, 149)",
  light8: "rgba(193,193,193,1)",
  light9: "rgba(255, 255, 255, 0.26)",

  dark1: "#00161f",
  dark2: "#012433",
  dark3: "#001E2A",
  dark4: "#00374f",
  dark5: "#000000",
  dark6: "#03354B",
  dark7: "#0D516F",
  dark8: "#022636",
  dark9: "#011E2A",
  dark10: "#02354B",
  dark11: "#031117",
  dark12: "#33454C",
};

export default allColors;
