import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import React, { FunctionComponent } from "react";
import { IndividualCreatorCard } from "@modules/Brands/Creator/Individual/card/IndividualCreatorCard";
import IndividualCreatorTab from "@modules/Organisms/Tab/SimpleTab";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";
import IndividualCreatorChart from "@modules/Brands/Creator/Individual/chart/IndividualCreatorChart";
import CreatorBrandDetailsTable from "@modules/Brands/Creator/Individual/grid/CreatorBrandDetailsTable";
import CreatorPostDetailsTable from "@modules/Brands/Creator/Individual/grid/CreatorPostDetailsTable";

const IndividualCreatorPagePresentational: FunctionComponent = () => {
  const theme: Theme = useTheme();
  return (
    <Box
      width="100%"
      position="relative"
      className="mt-10"
      color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
    >
      <div style={{ display: "flex" }}>
        <div>
          <IndividualCreatorCard />
        </div>
        <IndividualCreatorTab
          totalTabs={3}
          tabs={[
            {
              label: "Overview",
              component: <IndividualCreatorChart />,
            },
            {
              label: "Brands",
              component: <CreatorBrandDetailsTable />,
            },
            {
              label: "Posts",
              component: <CreatorPostDetailsTable />,
            },
          ]}
        />
      </div>
    </Box>
  );
};

export default IndividualCreatorPagePresentational;
