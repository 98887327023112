import { FunctionComponent } from "react";
import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import Pagination from "@modules/Pagination/Pagination";
import RowHeader from "@modules/Organisms/Header/RowHeader";
import { Categories, HeadRowType } from "@Models/Categories";
import PaginationDropDownPresentational from "@modules/Organisms/PaginationDropDown/PaginationDropDown.Presentational";

export interface CategoriesTablePresentationalProps {
  tableData: Categories[];
  categories: Categories[];
  headRows: HeadRowType[];
  handleRequestSort3: (property: any) => void;
  order: "asc" | "desc" | undefined;
  orderBy: string;
  count: number;
  page: number;
  setPage: any;
  open: boolean;
  loading?: boolean;
  position: any;
  setLoaderPosition: any;
  setPageRecord: React.Dispatch<React.SetStateAction<number>>;
  startRecordNumber: number;
  endRecordNumber: number;
  totalPage: number;
  handleChange: (event: any, newValue: any) => void;
  options: number[];
  pageDropDown: number;
  setPageDropDown: React.Dispatch<React.SetStateAction<number>>;
  onKeyDown: (ev: any) => void;
}

const CategoriesTablePresentational: FunctionComponent<
  CategoriesTablePresentationalProps
> = ({
  tableData,
  categories,
  headRows,
  order,
  orderBy,
  handleRequestSort3,
  count,
  page,
  setPage,
  open,
  loading,
  position,
  setLoaderPosition,
  setPageRecord,
  startRecordNumber,
  endRecordNumber,
  totalPage,
  handleChange,
  options,
  pageDropDown,
  setPageDropDown,
  onKeyDown,
}) => {
  const sx = styles(useTheme());
  const theme: Theme = useTheme();
  return (
    <Box sx={sx.parent}>
      <>
        <Paper sx={sx.paper} elevation={1}>
          <Box sx={sx.tableWrapper}>
            {loading || !categories || !tableData ? (
              <Backdrop sx={sx.backdropStyle} open={open}>
                <CircularProgress
                  color="inherit"
                  sx={sx.svgStyle}
                  style={position}
                />
              </Backdrop>
            ) : (
              ""
            )}
            <Box sx={sx.tableWrapper2}>
              <Table sx={sx.table}>
                <RowHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort3}
                  headRows={headRows}
                />
                <TableBody>
                  {categories &&
                  categories.length === 0 &&
                  loading === false ? (
                    <StyledTableRow>
                      <StyledTableBodyCell>
                        <Box maxWidth="400px" width="56vw">
                          Couldn't find any category
                        </Box>
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  ) : (
                    categories &&
                    categories.map((row: Categories, i: number) => (
                      <StyledTableRow key={i}>
                        <StyledTableBodyCell
                          sx={sx.stickyColumn}
                          style={{
                            backgroundColor:
                              i % 2
                                ? getColorByTheme(
                                    theme,
                                    allColors.light3,
                                    allColors.dark2
                                  )
                                : getColorByTheme(
                                    theme,
                                    allColors.light1,
                                    allColors.dark3
                                  ),
                          }}
                        >
                          {row?.name}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.brandListLength}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.creatorListLength}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.totalSpent}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.totalViews}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          {row?.totalPosts}
                        </StyledTableBodyCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
        <Box mt={6} display="flex" justifyContent="center" width="100%">
          <Box sx={sx.PaginationBoxStyle}>
            <Pagination
              count={count === 0 ? 1 : count}
              page={page}
              onChange={(e: any, page: number) => {
                setLoaderPosition(true);
                setPageRecord(page);
                setPage(page);
                setPageDropDown(page);
              }}
            />
            <PaginationDropDownPresentational
              handleChange={handleChange}
              options={options}
              pageDropDown={pageDropDown}
              onKeyDown={onKeyDown}
            />
          </Box>
          <Box sx={sx.boxStyle}>
            {categories.length === 0 || loading || !categories || !tableData
              ? "0-0 of 0 items"
              : `${startRecordNumber} - ${endRecordNumber} of ${totalPage} items`}
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default CategoriesTablePresentational;

const styles = (theme: any) => {
  return {
    backdropStyle: {
      display: "flex",
      alignItems: "flex-end",
      color: "#7026FF",
      backgroundColor: "rgb(240,230,0,0.5)",
      position: "absolute",
      backgroundPosition: "right top",
      zIndex: theme.zIndex.drawer - 1,
      opacity: 0.5,
      backdropFilter: "blur(2px)",
    },
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 800,
    },
    boxStyle: {
      marginLeft: "auto",
      alignSelf: "center",
      fontFamily: "SF Pro Text",
      fontSize: "1rem",
      fontWeight: 700,
      display: "flex",
      width: "15%",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    PaginationBoxStyle: {
      marginLeft: "auto",
      display: "flex",
      justifyContent: "center",
    },
    svgStyle: {
      position: "absolute",
    },
    parent: { marginTop: theme.spacing(6), width: "99%" },
    link: {
      marginLeft: theme.spacing(1),
      width: "25px",
      height: "25px",
    },
    avtar: {
      marginRight: theme.spacing(2),
      width: "80px",
      height: "80px",
    },
    paper: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      borderRadius: "21px",
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      width: "100%",
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: "auto",
      // It's 180 degree rotated that's why it's paddingTop instead of bottom
      paddingTop: theme.spacing(6),
      transform: "rotatex(180deg)",

      /* height */
      "&::-webkit-scrollbar": {
        height: "7px",
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        background: getColorByTheme(theme, allColors.light3, allColors.dark6),
        borderRadius: "50px",
        margin: theme.spacing(0, 2),
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: getColorByTheme(theme, allColors.light4, allColors.dark7),
        borderRadius: "50px",
      },

      /* Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: getColorByTheme(theme, allColors.light5, allColors.dark8),
      },
    },
    tableWrapper2: {
      transform: "rotatex(180deg)",
      minHeight: "30vh",
    },
    tableSortLabel: {
      "& .MuiTableSortLabel-icon": {
        position: "absolute",
        left: "-30px",
      },
    },
  };
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  "&:nth-of-type(even)": {
    backgroundColor: getColorByTheme(theme, allColors.light3, allColors.dark2),
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: getColorByTheme(theme, allColors.dark1, allColors.light1),
  fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
  fontSize: "1.23rem",
  fontWeight: "400",
  padding: theme.spacing("18px", 3),
  whiteSpace: "nowrap",
  "&:first-of-type": {
    paddingLeft: theme.spacing(3),
  },
  "&:last-child": {
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up("sm")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(6),
    },
    "&:last-child": {
      paddingRight: theme.spacing(6),
    },
  },
  [theme.breakpoints.up("md")]: {
    "&:first-of-type": {
      paddingLeft: theme.spacing(8),
    },
    "&:last-child": {
      paddingRight: theme.spacing(8),
    },
  },
}));
