import React, { FunctionComponent } from "react";
import { DataFormatter, dateFormatter } from "@utils/digitFormatter";

interface ToolTipProps {
  payload?: any;
  xLabel?: string;
  yLabel?: string;
  xkey?: string;
  ykey?: string;
  secondYKey?: string;
  secondYLabel?: string;
}
const CustomToolTip: FunctionComponent<ToolTipProps> = ({
  payload,
  xLabel,
  yLabel,
  xkey,
  ykey,
  secondYKey,
  secondYLabel,
}) => {
  return (
    <table>
      {(payload || []).map(
        (entry: any, index: number) =>
          index < 1 && (
            <tbody key={index}>
              <tr className="d-flex justify-content-between">
                <td className="mr-2">{xLabel}</td>
                <td>
                  <strong style={{ fontWeight: 900 }}>
                    {entry.payload[`${xkey}`]
                      ? dateFormatter(entry.payload[`${xkey}`].toLocaleString())
                      : 0}
                  </strong>
                </td>
              </tr>
              {secondYKey && (
                <tr className="d-flex justify-content-between">
                  <td className="mr-2">{secondYLabel}</td>
                  <td>
                    <strong style={{ fontWeight: 900 }}>
                      {entry.payload[`${secondYKey}`]
                        ? DataFormatter(
                            entry.payload[`${secondYKey}`]
                          ).toLocaleString()
                        : 0}
                    </strong>
                  </td>
                </tr>
              )}
              <tr className="d-flex justify-content-between">
                <td className="mr-2">{yLabel}</td>
                <td>
                  <strong style={{ fontWeight: 900 }}>
                    {(yLabel === "Total Earnings" ||
                      yLabel === "Total Spend" ||
                      yLabel === "Total Spent") &&
                      "$"}
                    {entry.payload[`${ykey}`]
                      ? DataFormatter(entry.payload[`${ykey}`]).toLocaleString()
                      : 0}
                  </strong>
                </td>
              </tr>
            </tbody>
          )
      )}
    </table>
  );
};

export default CustomToolTip;
