import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_INDIVIDUAL_CREATOR_CARD_DATA } from "@src/graphql/queries/getCreatorList";
import { formatNumber } from "@modules/utils/utils";
import IndividualCreatorCardPresentational from "@modules/Organisms/IndividualDetailsCard/IndividualCreatorDetailsCard.Presentational";
import {
  formatNumberToPercentage,
  formatString,
  formatPhoneNumber,
} from "@modules/Organisms/Formatter/Formatter";

export function IndividualCreatorCard() {
  const [creatorCardData, setCreatorCardData] = useState<any>({
    __message__: "Loading...",
  });
  const params: any = useParams();

  const [getCreatorsDetails, { data: creatorData, loading }] = useLazyQuery(
    GET_INDIVIDUAL_CREATOR_CARD_DATA
  );

  useEffect(() => {
    getCreatorsDetails({
      variables: {
        tiktokUsername: params.creator,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const IndividualCreatorCardData = creatorData?.getCreatorsDetails;
    if (IndividualCreatorCardData) {
      setCreatorCardData({
        tiktokUsername: IndividualCreatorCardData.tiktokUsername,
        tiktokProfileLink: IndividualCreatorCardData.tiktokProfileLink,
        tiktokFollowers: IndividualCreatorCardData.tiktokFollowers,
        tiktokSponsoredPosts: IndividualCreatorCardData.tiktokSponsoredPosts,
        tiktokBrands: IndividualCreatorCardData.tiktokBrands,
        tiktokProfileImage: IndividualCreatorCardData.tiktokProfileImage,
        tiktokTotalBrandPostViews:
          IndividualCreatorCardData.tiktokTotalBrandPostViews,
        isNetworkCreator: IndividualCreatorCardData.isNetworkCreator,
        tiktokAvgBrandPostViews:
          IndividualCreatorCardData.tiktokAvgBrandPostViews,
        tiktokAvgOrganicPostViews:
          IndividualCreatorCardData.tiktokAvgOrganicPostViews,
        tiktokAvgViews: IndividualCreatorCardData.tiktokAvgViews,
        tiktokBrandPostEngagementRate:
          IndividualCreatorCardData.tiktokBrandPostEngagementRate,
        tiktokOrganicPostEngagementRate:
          IndividualCreatorCardData.tiktokOrganicPostEngagementRate,
        tiktokBlendedEngagement:
          IndividualCreatorCardData.tiktokBlendedEngagement,
        categoryList: IndividualCreatorCardData.categoryList,
        phoneNumberSecondary: IndividualCreatorCardData.phoneNumberSecondary,
        emailSecondary: IndividualCreatorCardData.emailSecondary,
        contacts: IndividualCreatorCardData.contacts,
      });
    }
  }, [creatorData, loading]);

  const CategoryNames = creatorData?.getCreatorsDetails?.categoryList
    ?.map((x: string) => x)
    .join(", ");

  const contactData = creatorCardData.contacts;

  const cardData = [
    {
      name: "Creator Name",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatString(creatorCardData.tiktokUsername),
      id: 1,
    },
    {
      name: "Tiktok Profile Link",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : creatorCardData.tiktokProfileLink,

      id: 2,
    },
    {
      name: "TOTAL FOLLOWERS",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumber(creatorCardData.tiktokFollowers),
      id: 3,
    },
    {
      name: "TOTAL SPONSORED POSTS",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumber(creatorCardData.tiktokSponsoredPosts?.length),
      id: 4,
    },
    {
      name: "TOTAL BRANDS",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumber(creatorCardData.tiktokBrands?.length),
      id: 5,
    },
    {
      name: "Tiktok Profile Image",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : creatorCardData.tiktokProfileImage,
      id: 6,
    },
    {
      name: "Total Sponsored Views",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumber(creatorCardData.tiktokTotalBrandPostViews),
      id: 7,
    },
    {
      name: "Avg Views - Paid Posts",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumber(creatorCardData.tiktokAvgBrandPostViews),
      id: 8,
    },
    {
      name: "Avg Views - Organic Posts",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumber(creatorCardData.tiktokAvgOrganicPostViews),
      id: 9,
    },
    {
      name: "Avg Views - Blended",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumber(creatorCardData.tiktokAvgViews),
      id: 10,
    },
    {
      name: "Paid Post Engagement Rate",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumberToPercentage(
            creatorCardData.tiktokBrandPostEngagementRate
          ),
      id: 11,
    },
    {
      name: "Organic Post Engagement Rate",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumberToPercentage(
            creatorCardData.tiktokOrganicPostEngagementRate
          ),
      id: 12,
    },
    {
      name: "Blended Engagement Rate",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatNumberToPercentage(creatorCardData.tiktokBlendedEngagement),
      id: 13,
    },
    {
      name: "Categories",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : CategoryNames,
      id: 14,
    },
    {
      name: "Is In Network",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : creatorCardData.isNetworkCreator
        ? "Yes"
        : "No",
      id: 15,
    },
    {
      name: "Phone",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatPhoneNumber(creatorCardData.phoneNumberSecondary),
      id: 16,
    },
    {
      name: "Email",
      value: creatorCardData.__message__
        ? creatorCardData.__message__
        : formatString(creatorCardData.emailSecondary),
      id: 17,
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "center", minWidth: 300 }}>
      <IndividualCreatorCardPresentational
        cardData={cardData}
        contactData={contactData}
      />
    </div>
  );
}
