import { Container } from "@mui/material";
import React from "react";
import { CreatorsChart } from "@modules/CreatorsChart/CreatorsChart";
import { useQuery } from "@modules/utils/useQuery";
import "./media.css";

export const CreatorsChartPage = () => {
  let query = useQuery();
  let creatorId = query.get("creatorId");

  return (
    <Container id="Container">
      <CreatorsChart creatorId={creatorId} />
    </Container>
  );
};
