import React from "react";
import { Container } from "@mui/material";
import "@pages/media.css";

import IndividualCreatorPagePresentational from "./IndividualCreatorPage.presentational";

export const IndividualCreatorPage = () => {
  return (
    <Container id="Container" className="mt-10">
      <IndividualCreatorPagePresentational />
    </Container>
  );
};
