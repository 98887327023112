import React from "react";
import BrandTable from "@modules/BrandTable/BrandTable";
import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import "@pages/media.css";

export const BrandsManagementPage = () => {
  const theme: Theme = useTheme();
  return (
    <Container id="Container">
      <Box
        width="100%"
        justifyContent="center"
        position="relative"
        color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
      >
        <BrandTable />
      </Box>
    </Container>
  );
};
