import React from "react";
import { Surface, Symbols } from "recharts";

const CustomLegend = ({ payload }: any) => {
  return (
    <div className="customized-legend pl-14 mt-4">
      {payload.reverse().map((entry: any) => {
        const { value, color } = entry;
        const style = {
          marginRight: 10,
          fontFamily:
            "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
        };

        return (
          <span className="legend-item" style={style} key={value}>
            <Surface
              width={10}
              height={10}
              viewBox={{
                x: 0,
                y: 0,
                width: 10,
                height: 10,
              }}
            >
              <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
            </Surface>
            <span className="pl-2">{value}</span>
          </span>
        );
      })}
    </div>
  );
};

export default CustomLegend;
