import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { useLazyQuery } from "@apollo/client";
import IndividualCreatorChartPresentational from "./IndividualCreatorChart.presentational";
import { GET_CREATOR_CHART_DETAILS } from "@src/graphql/queries/getChartData";
import { useParams } from "react-router-dom";
import "@pages/media.css";
import { Loaders } from "@modules/Common/Loader/Loader";

const IndividualCreatorChart: FunctionComponent = () => {
  const theme: Theme = useTheme();
  const classes = useStyles();
  const [creatorChartData, setCreatorChartData] = useState([]);
  const [creatorChartTotal, setCreatorChartTotal] = useState({
    totalEarningAcross: 0,
    totalViewsAcross: 0,
    totalPostsAcross: 0,
    avgViewsAcross: 0,
    totalBrandsAcross: 0,
  });
  const params: any = useParams();
  const [getCreatorsOverview, { data: chartData, loading, error }] =
    useLazyQuery(GET_CREATOR_CHART_DETAILS);

  useEffect(() => {
    getCreatorsOverview({
      variables: {
        tiktokUsername: params.creator,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartData && chartData.getCreatorsOverview) {
      setCreatorChartData(chartData.getCreatorsOverview.chartDataValue);
      setCreatorChartTotal(chartData.getCreatorsOverview.creatorHeaderData);
    }
  }, [chartData, loading]);

  if (!creatorChartData || loading === true) {
    return (
      <div id="load" className={classes.loader}>
        <Loaders />
      </div>
    );
  }

  return (
    <Box color={getColorByTheme(theme, allColors.dark1, allColors.light1)}>
      <IndividualCreatorChartPresentational
        chartData={creatorChartData}
        chartLoading={loading}
        chartError={error}
        chartTotal={creatorChartTotal}
      />
    </Box>
  );
};

export default IndividualCreatorChart;

export const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    position: "absolute",
    top: "49%",
    left: "47%",
  },
}));
