import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Link } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "@modules/Logo/Logo";
import HorizontalDivision from "@modules/Layouts/HorizontalDivision";
import { getPageConfig, ROUTES } from "@src/constants";
import { Message, Stats, TotalStats } from "@Models/Header";
import { GenreList } from "@Models/Genre";
import { GET_HEADERS_DATA } from "@src/graphql/queries/getTableData";
import GList from "@modules/GenreSelection/genreList";
import ThemeSwitch from "@modules/ThemeSwitch/ThemeSwitch";

export const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const page = location.pathname ? location.pathname : "";

  const PAGE_CONFIG = getPageConfig(location.pathname);
  const [totalStats, setTotalStats] = useState<TotalStats | Message>({
    __message__: "Loading...",
  });

  const [getHeadersData, { data: creatorsStats }] =
    useLazyQuery(GET_HEADERS_DATA);

  useEffect(() => {
    if (PAGE_CONFIG?.SHOW_STATS) {
      getHeadersData();
      const res = ["All"];
      res.push(...[...createGenreList(GList)].sort());
      const genreList = res.map((g) => {
        return { title: g, value: g };
      });
      dispatch({
        type: "FILTER_OPTIONS",
        payload: {
          genreOptions: genreList,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PAGE_CONFIG]);

  useEffect(() => {
    const stats = creatorsStats?.getCreatorsStats;
    if (stats) {
      setTotalStats({
        creators: stats.totalCreators,
        followers: stats.totalFollowers,
        totalAvgVideoViews: stats.totalAvgViews,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorsStats]);

  const createGenreList = (genreList: GenreList[]) => {
    const genres: string[] = [];
    genreList.forEach((g) => {
      genres.push(g.genre);
      if (g.subGenre) {
        const result = createGenreList(g.subGenre);
        if (result) {
          genres.push(...result);
        }
      }
    });
    return genres;
  };
  return (
    <>
      {PAGE_CONFIG?.SHOW_STATS ? (
        <LogoAndStats
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          minWidth="600px"
        >
          <Box mr={2}>
            <HorizontalDivision
              left={getLogoValue(page)}
              right={
                <Typography
                  variant="h5"
                  sx={{
                    "&": { textTransform: "uppercase", fontWeight: "bold" },
                  }}
                >
                  {getHeaderValue(page, PAGE_CONFIG?.HEADER)}
                </Typography>
              }
            />
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            className="stats"
          >
            <Box>
              <Stat
                title="Total Creators"
                totalStats={totalStats}
                statKey="creators"
              />
            </Box>
            <Box>
              <Stat
                title="Total Followers"
                totalStats={totalStats}
                statKey="followers"
              />
            </Box>
            <Box>
              <Stat
                title="Total Avg Views Per Post"
                totalStats={totalStats}
                statKey="totalAvgVideoViews"
              />
            </Box>
          </Box>
        </LogoAndStats>
      ) : null}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {!PAGE_CONFIG?.SHOW_STATS && (
          <Box mr={4}>
            <HorizontalDivision
              left={getLogoValue(page)}
              right={
                <Typography
                  variant="h5"
                  sx={{
                    "&": { textTransform: "uppercase", fontWeight: "bold" },
                  }}
                >
                  {getHeaderValue(page, PAGE_CONFIG?.HEADER)}
                </Typography>
              }
            />
          </Box>
        )}
        {location.pathname !== "/" && (
          <HeadSection
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems="center"
            mx={1}
            mt={4}
          >
            <div />
            <Box display="block" className="right">
              <Box mr={2}>
                <ThemeSwitch />
              </Box>
            </Box>
          </HeadSection>
        )}
      </Box>
    </>
  );
};

const Stat = ({ title, totalStats, statKey }: Stats) => {
  return (
    <Box>
      <Typography
        variant="h6"
        component="span"
        display="block"
        sx={{
          "&": {
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "1.1rem",
          },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        component="span"
        sx={{ "&": { fontWeight: "bold", fontSize: "1.5rem" } }}
      >
        {totalStats.__message__ ? totalStats.__message__ : totalStats[statKey]}
      </Typography>
    </Box>
  );
};

const LogoAndStats = styled(Box)(({ theme }) => ({
  gap: theme.spacing(4),
  "& .stats": {
    justifyContent: "flex-start",
    paddingLeft: "2%",
    gap: "5vw",
    marginBottom: "2vw",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "justify-content",
    gap: theme.spacing(2),
    "& .stats": {
      justifyContent: "flex-end",
      paddingLeft: "0%",
    },
  },
}));

const HeadSection = styled(Box)(({ theme }) => ({
  marginTop: 0,
  gap: theme.spacing(2),
  "& .left": {
    width: "100%",
  },
  "& .right": {
    alignSelf: "flex-end",
    float: "right",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    "& .left": {
      width: "auto",
    },
  },
}));

const getHeaderValue = (page: string, HEADER?: string) => {
  if (
    page.includes(ROUTES.SPONSORED_CREATORS) &&
    page.trim().split("/").length === 4
  ) {
    return "CREATOR DETAILS";
  }
  if (
    page.includes(ROUTES.BRAND_DATA) &&
    page.trim().split("/").length === 3 &&
    !page.includes("management")
  ) {
    return "BRAND DETAILS";
  } else return HEADER;
};

const getLogoValue = (page: string) => {
  if (
    page.includes(ROUTES.SPONSORED_CREATORS) &&
    page.trim().split("/").length === 4
  ) {
    return (
      <Link href={`${ROUTES.SPONSORED_CREATORS}`} rel="noreferrer">
        <Logo />
      </Link>
    );
  }
  if (
    page.includes(ROUTES.BRAND_DATA) &&
    page.trim().split("/").length === 3 &&
    !page.includes("management")
  )
    return (
      <Link href={`${ROUTES.BRAND_DATA}`} rel="noreferrer">
        <Logo />
      </Link>
    );
  else return <Logo />;
};
