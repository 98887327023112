import React, { FunctionComponent, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import BrandPagePresentational from "./BrandPage.presentational";
import "@pages/media.css";
import Sidebar from "@modules/SideBar/Sidebar";
import { Header } from "@modules/Header/Header";

export const BrandPage: FunctionComponent = () => {
  const theme: Theme = useTheme();
  const [brandchartData, setBrandChartData] = useState([]);
  const [brandChartTotal, setBrandChartTotal] = useState({
    totalBrands: 0,
    totalEarning: 0,
    totalViewsPurchased: 0,
    totalSponsoredPost: 0,
  });
  const [dateState, setDateState] = React.useState({
    startDate: new Date("01/01/2021"),
    endDate: new Date(),
    key: "selection",
  });

  // useEffect(() => {
  //   let sMonth = new Date(dateState.startDate).getMonth() + 1;
  //   let eMonth = new Date(dateState.endDate).getMonth() + 1;
  //   let startMon = "";
  //   let endMon = "";
  //   if (sMonth < 10) {
  //     startMon = `0${sMonth}`;
  //   } else {
  //     startMon = sMonth.toString();
  //   }
  //   if (eMonth < 10) {
  //     endMon = `0${eMonth}`;
  //   } else {
  //     endMon = eMonth.toString();
  //   }
  //   const startMonth = `${new Date(
  //     dateState.startDate
  //   ).getFullYear()}/${startMon}`;
  //   const endMonth = `${new Date(dateState.endDate).getFullYear()}/${endMon}`;
  //   if (startMonth && endMonth)
  //     getBrandChart({
  //       variables: {
  //         firstMonth: startMonth,
  //         lastMonth: endMonth,
  //       },
  //     });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dateState.endDate]);

  // useEffect(() => {
  //   if (data && data.getBrandsChart && data.getBrandsChart.chartValue) {
  //     setBrandChartData(data.getBrandsChart.chartValue);
  //     const charts = data.getBrandsChart.chartValue.map((c: any) => {
  //       return {
  //         ...c,
  //         totalViews: parseInt(c.totalViews),
  //         totalCpm: parseInt(c.totalCpm),
  //       };
  //     });
  //     setBrandChartData(charts);
  //     setBrandChartTotal(data.getBrandsChart.brandsTotal);
  //   }
  // }, [loading, data]);
  const datePickerHandler = (start?: Date, end?: Date) => {
    if (start) {
      setDateState((prev: any) => ({ ...prev, startDate: start }));
    }
    if (end) {
      setDateState((prev: any) => ({ ...prev, endDate: end }));
    }
  };

  return (
    <Sidebar>
      <Container id="Container">
        <Header />
        <Box
          position="relative"
          color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
        >
          <BrandPagePresentational
            chartData={brandchartData}
            chartLoading={false}
            chartTotal={brandChartTotal}
            datePickerHandler={datePickerHandler}
            dateState={dateState}
          />
        </Box>
      </Container>
    </Sidebar>
  );
};
