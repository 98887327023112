export const categories = [
  "Art & Crafts",
  "Automotive & Transportation",
  "Beauty",
  "Brand",
  "Clothing & Accessories",
  "Education",
  "Electronics",
  "Energy, Power, Chemicals",
  "Entertainment",
  "Fashion",
  "Finance & Investing",
  "Food & Beverage",
  "Gaming",
  "Government",
  "Health & Wellness",
  "Home, Furniture & Appliances",
  "Media & Entertainment",
  "Medical/Health",
  "Music/Dance",
  "Pets",
  "Public Figure",
  "Recreational Drugs/Tobacco",
  "Restaurants & Bars",
  "Shopping & Retail",
  "Software & Apps",
  "Sports, Fitness & Outdoors",
  "Travel & Tourism",
  "UGC Creators",
];
