import React, { FunctionComponent } from "react";
import { Box, Paper, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { Loaders } from "@modules/Common/Loader/Loader";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

const FallBackChart: FunctionComponent<any> = ({ classname, chartError }) => {
  const sx = fallbackchart(useTheme());
  const classes = fallbackError();
  return (
    <Paper sx={sx.paper} className={`${classname}`} elevation={0}>
      <Box sx={sx.flex}>
        <ErrorIcon />
        {chartError && <span className={classes.error}>Server Error</span>}
        {!chartError && <span className={classes.error}>No Data Found</span>}
      </Box>
    </Paper>
  );
};
export const FallBackLoading: FunctionComponent<any> = ({
  classname,
  chartError,
}) => {
  const sx = fallbackchart(useTheme());
  return (
    <Paper sx={sx.paper} className={`${classname}`} elevation={0}>
      <Box sx={sx.flex}>
        <Loaders style={{ position: "initial" }} />
      </Box>
    </Paper>
  );
};

export default FallBackChart;

export const fallbackchart = (theme: any) => {
  return {
    paper: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      minHeight: "250px",
      borderRadius: "10px",
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      paddingLeft: 0,
      height: "40vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0 0 0 rgba(0,0,0,0)",
    },
    flex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    error: {
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "17px",
      letterSpacing: "0px",
      textAlign: "left",
      marginTop: "13px",
    },
  };
};

export const fallbackError = makeStyles((theme: Theme) => ({
  error: {
    fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "17px",
    letterSpacing: "0px",
    textAlign: "left",
    marginTop: "13px",
  },
}));
