import React, { FunctionComponent } from "react";
import { CategoryChartProps } from "./CategoriesChart.types";
import FallBackChart, {
  FallBackLoading,
} from "@modules/Organisms/Charts/helper/FallBackChart";
import ChartCard from "@modules/Organisms/Charts/LineCharts/ChartCard";
import BarChart from "@modules/Organisms/Charts/BarCharts/barChart";
const CategoryChartPresentational: FunctionComponent<CategoryChartProps> = ({
  chartData,
  chartError,
  chartLoading,
  chartTotal,
  filters,
  setFilters,
  categories,
  monthsfilter,
}) => {
  return (
    <div id="container" style={{ display: "flex", width: "99%" }}>
      <div className="mt-8" style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", width: "100%" }}>
            <>
              {chartData &&
              chartData.monthlySpentByCategory &&
              chartData.monthlySpentByCategory.length > 0 ? (
                <ChartCard
                  header={"Money Spend by Month"}
                  data={chartData.monthlySpentByCategory}
                  classname="mt-0"
                  xkey="dateId"
                  ykey="totalSpent"
                  xLabel="Month"
                  yLabel="Total Spent"
                  dropdownOption={categories}
                  setDropdownOptions={setFilters}
                  selectedOption={filters.filterByMonthlySpentByCategory}
                  filterKey={"filterByMonthlySpentByCategory"}
                />
              ) : ((chartData &&
                  chartData.monthlySpentByCategory &&
                  chartData.monthlySpentByCategory.length === 0) ||
                  chartError) &&
                !chartLoading ? (
                <FallBackChart error={chartError} classname=" mt-0 mr-4" />
              ) : (
                <FallBackLoading classname="mr-4" />
              )}
            </>

            <>
              {chartData &&
              chartData.totalViewsByCategory &&
              chartData.totalViewsByCategory.length > 0 ? (
                <ChartCard
                  header={"Total Views by Month"}
                  data={chartData.totalViewsByCategory}
                  xkey="dateId"
                  classname="mt-0 ml-6"
                  ykey="totalViewsPurchased"
                  xLabel="Month"
                  yLabel="Total Views"
                  dropdownOption={categories}
                  setDropdownOptions={setFilters}
                  selectedOption={filters.filterByTotalViewsByCategory}
                  filterKey={"filterByTotalViewsByCategory"}
                />
              ) : ((chartData &&
                  chartData.totalViewsByCategory &&
                  chartData.totalViewsByCategory.length === 0) ||
                  chartError) &&
                !chartLoading ? (
                <FallBackChart error={chartError} classname=" mt-0" />
              ) : (
                <FallBackLoading classname="ml-6" />
              )}
            </>
          </div>
          <div>
            {chartLoading && chartData?.totalSpentByMonth.length === 0 ? (
              <FallBackLoading />
            ) : (
              <>
                {!chartError ? (
                  <BarChart
                    header={"Total Spend by Category"}
                    data={chartData.totalSpentByMonth}
                    classname="mr-4"
                    xkey="category"
                    ykey="totalSpent"
                    xLabel="Category"
                    yLabel="Total Spend"
                    dropdownOption={monthsfilter}
                    setDropdownOptions={setFilters}
                    selectedOption={filters.filterByTotalSpentByMonth}
                    filterKey={"filterByTotalSpentByMonth"}
                  />
                ) : (
                  <FallBackChart />
                )}
              </>
            )}
            {chartLoading &&
            chartData?.totalSonseredPostByMonth.length === 0 ? (
              <FallBackLoading classname="mr-4" />
            ) : (
              <>
                {!chartError ? (
                  <BarChart
                    header={"Total Sponsored Posts by Category"}
                    data={chartData.totalSonseredPostByMonth}
                    classname="mr-4"
                    xkey="category"
                    ykey="totalSponseredPost"
                    xLabel="Category"
                    yLabel="Sponsored Post"
                    dropdownOption={monthsfilter}
                    setDropdownOptions={setFilters}
                    selectedOption={filters.filterByTotalSonseredPostByMonth}
                    filterKey={"filterByTotalSonseredPostByMonth"}
                  />
                ) : (
                  <FallBackChart />
                )}
              </>
            )}
          </div>
          <div>
            {chartLoading && chartData?.totalCreatorByCategory.length === 0 ? (
              <FallBackLoading />
            ) : (
              <>
                {chartData &&
                chartData.totalCreatorByCategory &&
                chartData.totalCreatorByCategory.length > 0 ? (
                  <BarChart
                    header={"Total Creators  by Category"}
                    data={chartData.totalCreatorByCategory}
                    classname="mr-4"
                    xkey="category"
                    ykey="totalCreators"
                    xLabel="Category"
                    yLabel="Total Creators"
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
            {chartLoading && chartData?.totalCreatorByCategory.length === 0 ? (
              <FallBackLoading />
            ) : (
              <>
                {chartData &&
                chartData.totalBrandsByCategory &&
                chartData.totalBrandsByCategory.length > 0 ? (
                  <BarChart
                    header={"Total Brands  by Category"}
                    data={chartData.totalBrandsByCategory}
                    classname="mr-4"
                    xkey="category"
                    ykey="totalBrands"
                    xLabel="Category"
                    yLabel="Total Brands"
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryChartPresentational;
