import React, { useEffect, useRef } from "react";
import { TailSpin } from "react-loader-spinner";
import Autosuggest from "react-autosuggest";
import { useTheme, makeStyles, StyleRules } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";

interface Props {
  value: string;
  setValue: (...args: any) => void;
  suggestions: Array<any>;
  setSuggestions: (...args: any) => void;
  loading?: boolean;
  array?: any;
  fetchSuggestion: (...args: any) => void;
  onSuggestionSelected: (...args: any) => void;
  onEnter: (...args: any) => void;
  placeholder?: string;
  setClear?: any;
  onOptionSelect: boolean;
}

export const AutoSuggestCreator = ({
  suggestions,
  setSuggestions,
  onSuggestionSelected,
  setValue,
  loading,
  array,
  value,
  fetchSuggestion,
  onEnter,
  placeholder,
  setClear,
  onOptionSelect,
}: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);
  const timeout = useRef<any>(null);
  useEffect(() => {
    if (value && value.length !== 0) {
      fetchSuggestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (event: any, { newValue }: { newValue: string }) => {
    setValue(newValue);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (newValue.length > 0) {
      timeout.current = setTimeout(() => {
        fetchSuggestion(newValue);
      }, 500);
    } else {
      setSuggestions([]);
    }
    onEnter(event?.keyCode);
  };

  const onSuggestionsFetchRequested = ({ value, reason }: any) => {
    if (reason === "suggestion-selected") {
      setSuggestions([]);
    }
  };
  const getSuggestionValue = (suggestion: any) => {
    const tiktokUsername = suggestion.tiktokUserName
      ? suggestion.tiktokUserName
      : suggestion.tiktokUsername;
    return suggestion.legalName
      ? `${suggestion.legalName} (${tiktokUsername})`
      : tiktokUsername;
  };

  const renderSuggestion = (suggestion: any) => {
    const tiktokUsername = suggestion.tiktokUserName
      ? suggestion.tiktokUserName
      : suggestion.tiktokUsername;
    return (
      <p className={classes.name}>
        {suggestion.legalName
          ? `${suggestion.legalName} (${tiktokUsername})`
          : tiktokUsername}
      </p>
    );
  };

  const renderInputComponent = (inputProps: any) => (
    <div>
      <input {...inputProps} />
    </div>
  );

  const clearSuggestions = () => {
    setValue("");
    setSuggestions([]);
    setClear(true);
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }: any) => {
    return (
      <div {...containerProps}>
        {!onOptionSelect && <strong>{children}</strong>}
      </div>
    );
  };

  const inputProps = {
    placeholder: placeholder,
    value,
    onChange: onChange,
    onBlur: () => setSuggestions([]),
    onKeyDown: onEnter,
  };

  return (
    <div className={classes.outer}>
      <Autosuggest
        theme={{
          input: classes.input,
          inputFocused: classes.inputFocused,
          suggestionsContainer: classes.suggestionsContainer,
          suggestion: classes.suggestion,
          suggestionHighlighted: classes.suggestionHighlighted,
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        alwaysRenderSuggestions={false}
        onSuggestionsClearRequested={() => {}}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
      <div className={classes.search}>
        <SearchIcon />
      </div>
      {loading ? (
        <div className={classes.close}>
          <TailSpin color="#7026FF" height={20} width={20} />
        </div>
      ) : (
        value !== "" && (
          <div className={classes.close} onClick={clearSuggestions}>
            <i
              className="far fa-times-circle fa-lg"
              style={{ color: "#a494cd" }}
            ></i>
          </div>
        )
      )}
    </div>
  );
};

const styles = (theme: Theme) => {
  const style: StyleRules = {
    outer: {
      position: "relative",
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
    },
    search: {
      position: "absolute",
      zIndex: 999,
      top: 16,
      left: 14,
    },
    close: {
      position: "absolute",
      zIndex: 100,
      top: 16,
      left: 280,
      fontSize: "14px",
      cursor: "pointer",
    },
    input: {
      zIndex: 1,
      top: 0,
      border: "none",
      borderRadius: "6px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: getColorByTheme(theme, allColors.light3, allColors.dark11),
      padding: "17px",
      paddingRight: "41px",
      paddingLeft: "40px",
      width: "320px",
    },
    inputFocused: {
      outlineStyle: "none",
    },
    suggestionsContainer: {
      position: "absolute",
      background: getColorByTheme(theme, allColors.light3, allColors.dark11),
      boxSizing: "border-box",
      borderRadius: "8px",
      width: "320px",
      zIndex: 1000,
      overflow: "auto",
      maxHeight: 400,
    },
    suggestion: {
      padding: "10px 150px 10px 18px",
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      listStyleType: "none",
      marginLeft: "-40px",
      cursor: "pointer",
    },
    suggestionHighlighted: {
      color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      background: "rgba(137,65,255, 0.3)",
      borderRadius: "8px",
      mixBlendMode: "normal",
    },
    name: {
      margin: "auto",
    },
  };
  return style;
};

const useStyles = makeStyles(styles);
