import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_INDIVIDUAL_BRANDS_CARD_DATA } from "@src/graphql/queries/getBrandsList";
import { formatNumber } from "@modules/utils/utils";
import IndividualBrandCardPresentational from "@modules/Organisms/IndividualDetailsCard/IndividualBrandDetailsCard.Presentational";
import { formatString } from "@modules/Organisms/Formatter/Formatter";

export function IndividualBrandCard() {
  const [brandCardData, setBrandCardData] = useState<any>({
    __message__: "Loading...",
  });
  const params: any = useParams();

  const [getBrandsProfile, { data: brandData, loading }] = useLazyQuery(
    GET_INDIVIDUAL_BRANDS_CARD_DATA
  );

  useEffect(() => {
    getBrandsProfile({
      variables: {
        tiktokusername: params.brand,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const IndividualBrandCardData = brandData?.getBrandsProfile?.account;
    if (IndividualBrandCardData) {
      setBrandCardData({
        tiktokUserName: IndividualBrandCardData.tiktokUserName,
        tiktokProfileLink: IndividualBrandCardData.tiktokProfileLink,
        followers: IndividualBrandCardData.followers,
        totalPosts: IndividualBrandCardData.totalPosts,
        creators: IndividualBrandCardData.creators,
        tiktokProfileImage: IndividualBrandCardData.tiktokProfileImage,
        bioLink: IndividualBrandCardData.bioLink,
        correctedCategories: IndividualBrandCardData.correctedCategories,
      });
    }
  }, [brandData, loading]);

  const CategoryNames = brandCardData?.correctedCategories
    ?.map((x: string) => x)
    .join(", ");

  const cardData = [
    {
      name: "Brand Name",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : formatString(brandCardData.tiktokUserName),
      id: 1,
    },
    {
      name: "Tiktok Profile Link",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : brandCardData.tiktokProfileLink,

      id: 2,
    },
    {
      name: "TOTAL FOLLOWERS",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : formatNumber(brandCardData.followers),
      id: 3,
    },
    {
      name: "TOTAL POSTS",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : formatNumber(brandCardData.totalPosts),
      id: 4,
    },
    {
      name: "TOTAL CREATORS",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : formatNumber(brandCardData.creators.length),
      id: 5,
    },
    {
      name: "Tiktok Profile Image",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : brandCardData.tiktokProfileImage,
      id: 6,
    },
    {
      name: "Bio Link",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : brandCardData.bioLink,
      id: 7,
    },
    {
      name: "Categories",
      value: brandCardData.__message__
        ? brandCardData.__message__
        : CategoryNames,
      id: 8,
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "center", minWidth: 300 }}>
      <IndividualBrandCardPresentational cardData={cardData} />
    </div>
  );
}
