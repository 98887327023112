import React, { FunctionComponent } from "react";
import { BrandsPageProps } from "./brandPage.types";
import BrandDataTable from "@modules/Brands/Brand/BrandDataTable";

const BrandPagePresentational: FunctionComponent<BrandsPageProps> = ({
  chartData,
  chartLoading,
  chartTotal,
  dateState,
  datePickerHandler,
}) => {
  return (
    <div>
      {/* <BrandListHeader />
      <DateRangePicker dateState={dateState} setDateState={datePickerHandler} />
      <div style={{ width: "100%" }}>
        {chartLoading ? (
          <FallBackLoading classname="mr-4" />
        ) : (
          <>
            {chartData && chartData.length > 0 ? (
              <ChartCard
                header={"Views by Month"}
                data={chartData}
                xkey="dateId"
                ykey="totalViews"
                xLabel="Month"
                subHeader={`Total Views: ${chartTotal.totalViewsPurchased.toLocaleString()}`}
                yLabel="Total Views"
              />
            ) : (
              <FallBackChart classname="mr-4" />
            )}
          </>
        )}
        {chartLoading ? (
          <FallBackLoading classname="mr-4" />
        ) : (
          <>
            {chartData && chartData.length > 0 ? (
              <BarChart
                header={"Brands by Month"}
                data={chartData}
                xkey="dateId"
                ykey="accounts"
                xLabel="Month"
                yLabel="Total Brands"
                subHeader={`Total Brands: ${chartTotal.totalBrands.toLocaleString()}`}
              />
            ) : (
              <FallBackChart classname="mr-4" />
            )}
          </>
        )}
        {chartLoading ? (
          <FallBackLoading classname="mr-4" />
        ) : (
          <>
            {chartData && chartData.length > 0 ? (
              <BarChart
                header={"Posts by Month"}
                data={chartData}
                xkey="dateId"
                ykey="sponsoredPosts"
                xLabel="Month"
                yLabel="Sponsored Posts"
                subHeader={`Total Posts: ${chartTotal.totalSponsoredPost.toLocaleString()}`}
              />
            ) : (
              <FallBackChart classname="mr-4" />
            )}
          </>
        )}
      </div> */}
      <BrandDataTable />
    </div>
  );
};

export default BrandPagePresentational;
