import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

export const barchartCardStyles = (theme: any) => {
  return {
    paper: {
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
      minHeight: "300px",
      borderRadius: "10px",
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      paddingLeft: 0,
      height: "fit-content",
      paddingBottom: "5rem",
      width: "100%",
    },

    heading: {
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "17px",
      letterSpacing: "0px",
      textAlign: "left",
      marginTop: "13px",
    },
    headingStart: {
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      color: getColorByTheme(
        theme,
        allColors.dark1 + "80",
        allColors.light1 + "80"
      ),
    },
    cardHeading: {
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      fontWeight: 900,
      paddingLeft: "0.2rem",
    },
    headingContainer: {
      display: "flex",
      alignItems: "flex-start",
      padding: "0 0 0 3rem",
    },
    topBarText: {
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      fill: `${getColorByTheme(
        theme,
        allColors.dark1 + "80",
        allColors.light1 + "80"
      )} !important`,
      fontWeight: 600,
      fontSize: "0.9rem",
    },
  };
};

export const barchartTopBarTextStyles = makeStyles((theme: Theme) => ({
  topBarText: {
    fontFamily: "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
    fill: `${getColorByTheme(
      theme,
      allColors.dark1 + "80",
      allColors.light1 + "80"
    )} !important`,
    fontWeight: 600,
    fontSize: "0.9rem",
  },
}));
