export const ROUTES = {
  CHART_SCREEN: "/audience-data",
  HOME: "/",
  GENRE_SCREEN: "/genre-selection",
  BRAND_LIST: "/brands/management",
  SPONSORED_CREATORS: "/sponsored/creators",
  BRAND_DATA: "/brands",
  LOGIN: "/login",
  CATEGORIES: "/categories",
  CREATOR_DETAILS: "/sponsored/creators/:creator",
  BRAND_DETAILS: "/brands/:brand",
  STRIPE_SUCCESS: "/onboarding-successful",
  STRIPE_FAILED: "/onboarding-failed",
  STRIPE_ONBOARD_REDIRECT: "/stripe-onboarding",
  MOBILE_TOS: "/creators/mobile/tos",
};

export const totalCount = 30;
export const totalBrandPageCount = 100;

export const SCREEN_CONFIG = {
  HOME: {
    HEADER: "Creator List",
    SHOW_STATS: true,
  },
  CHART_SCREEN: {
    HEADER: "Creator Analytics",
    SHOW_STATS: false,
  },
  GENRE_SCREEN: {
    HEADER: "Genre Selection",
    SHOW_STATS: false,
  },
  BRAND_LIST: {
    HEADER: "Brand Management",
    SHOW_STATS: false,
  },
  SPONSORED_CREATORS: {
    HEADER: "Sponsored Creators",
    SHOW_STATS: false,
  },
  BRAND_DATA: {
    HEADER: "Brand Data",
    SHOW_STATS: false,
  },
  CATEGORIES: {
    HEADER: "Categories",
    SHOW_STATS: false,
  },
  CREATOR_DETAILS: {
    HEADER: "Creator Details",
    SHOW_STATS: false,
  },
  BRAND_DETAILS: {
    HEADER: "Brand Details",
    SHOW_STATS: false,
  },
  LOGIN: {
    HEADER: "Login",
    SHOW_STATS: false,
  },
  STRIPE_SUCCESS: {
    HEADER: "Onboarding Complete",
    SHOW_STATS: false,
  },
  STRIPE_FAILED: {
    HEADER: "Onboarding Failed",
    SHOW_STATS: false,
  },
};

export const getPageConfig = (pathname: string) => {
  switch (pathname) {
    case ROUTES.CHART_SCREEN:
      return SCREEN_CONFIG.CHART_SCREEN;
    case ROUTES.HOME:
      return SCREEN_CONFIG.HOME;
    case ROUTES.GENRE_SCREEN:
      return SCREEN_CONFIG.GENRE_SCREEN;
    case ROUTES.BRAND_LIST:
      return SCREEN_CONFIG.BRAND_LIST;
    case ROUTES.SPONSORED_CREATORS:
      return SCREEN_CONFIG.SPONSORED_CREATORS;
    case ROUTES.BRAND_DATA:
      return SCREEN_CONFIG.BRAND_DATA;
    case ROUTES.CATEGORIES:
      return SCREEN_CONFIG.CATEGORIES;
    case ROUTES.CREATOR_DETAILS:
      return SCREEN_CONFIG.CREATOR_DETAILS;
    case ROUTES.BRAND_DETAILS:
      return SCREEN_CONFIG.BRAND_DETAILS;
    case ROUTES.LOGIN:
      return SCREEN_CONFIG.LOGIN;
    case ROUTES.STRIPE_SUCCESS:
      return SCREEN_CONFIG.STRIPE_SUCCESS;
    case ROUTES.STRIPE_FAILED:
      return SCREEN_CONFIG.STRIPE_FAILED;
    default:
      return null;
  }
};

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const displaySidebar = (path: string) => {
  let url = path;
  const checkpath = path.endsWith("/");
  if (checkpath) {
    url = url.slice(0, -1);
  }
  switch (url) {
    case ROUTES.BRAND_DATA:
      return true;
    case ROUTES.CATEGORIES:
      return true;
    case ROUTES.SPONSORED_CREATORS:
      return true;
    case ROUTES.CREATOR_DETAILS:
      return false;
    case ROUTES.BRAND_DETAILS:
      return false;
    default:
      return false;
  }
};

export const monthFilter = () => {
  const startYear = 2020;
  const endYear = new Date().getFullYear();
  const endMonth = new Date().getMonth() + 1;
  const yearDiff = endYear - startYear;
  let months = [];
  let defaultYear = startYear;
  let defaultMonth = 0;
  for (let i = 1; i <= yearDiff * 12 + endMonth; i++) {
    if (defaultMonth > 1 && (i - 1) % 12 === 0) {
      defaultYear = defaultYear + 1;
      defaultMonth = 1;
    } else {
      defaultMonth += 1;
    }
    months.push({
      title: `${monthNames[defaultMonth - 1]} ${defaultYear}`,
      value: `${defaultYear}/${
        defaultMonth < 10 ? `0${defaultMonth}` : defaultMonth
      }`,
    });
  }
  months.unshift({ title: "All", value: "All" });
  return months;
};

export const chartDropdownOption = [
  { title: "Jan 2021 - April 2022", value: "2021/01-2022/04" },
  { title: "All", value: "All" },
];

export const STRIPE_REDIRECT_PASS = "UBQ_12345678";

export const OTP_RESEND_TIME = 120;

export const ERR_MSG = {
  INVALIDEMAIL: "Please enter a valid email.",
  NOTFOUNDEMAIL: "Email is not recognized.  Please try again.",
  ERROTP: "The code you entered is incorrect. Please try again.",
};
