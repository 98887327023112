import React from "react";
import { Box, Typography } from "@mui/material";
import SelectDropdown from "@modules/Dropdowns/SelectDropdown";

interface Props {
  title?: string;
  options?: any;
  selected?: string | string[];
  onChange?: (e: any) => void;
  multiple?: any;
  customClassname?: string;
}

const SelectDropDownWithLabel = ({
  title,
  options,
  selected,
  onChange,
  multiple,
  customClassname,
}: Props) => {
  return (
    <>
      <Box mb="13px">
        <Typography variant="subtitle2" component="span">
          {title}
        </Typography>
        <SelectDropdown
          customClassname={customClassname}
          multiple={multiple}
          options={options}
          selected={selected}
          onChange={onChange}
        />
      </Box>
    </>
  );
};

export default SelectDropDownWithLabel;
