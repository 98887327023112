import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { BsFillCaretRightFill } from "react-icons/bs";
import { withRouter } from "@modules/Organisms/WithRouter/WithRouter";
import { useLocation, useNavigate } from "react-router";
import { itemProp } from "@Models/SidebarItem";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { ROUTES } from "@src/constants";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  color: getColorByTheme(theme, allColors.dark1, allColors.light1),
  background: "transparent",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Sidebar: React.FC<any> = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [select, setSelected] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const itemsList = [
    {
      text: "CREATORS",
      icon: <BsFillCaretRightFill />,
      onClick: (i: number) => {
        setSelected(i);
        navigate(ROUTES.SPONSORED_CREATORS);
      },
    },
    {
      text: "BRANDS",
      icon: <BsFillCaretRightFill />,
      onClick: (i: number) => {
        setSelected(i);
        navigate(ROUTES.BRAND_DATA);
      },
    },
    {
      text: "CATEGORIES",
      icon: <BsFillCaretRightFill />,
      onClick: (i: number) => {
        setSelected(i);
        navigate(ROUTES.CATEGORIES);
      },
    },
  ];
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (location?.pathname?.includes(ROUTES.SPONSORED_CREATORS)) {
      setSelected(0);
    } else if (location?.pathname?.includes(ROUTES.BRAND_DATA)) {
      setSelected(1);
    } else if (location?.pathname?.includes(ROUTES.CATEGORIES)) {
      setSelected(2);
    }
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar elevation={0} position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={{
              background: "transparent",
            }}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon sx={{ width: "1.5em", height: "2em" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            border: "none",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Divider />
        <List>
          {itemsList.map((item: itemProp, index: number) => (
            <ListItem
              button
              key={item.text}
              onClick={() => {
                item.onClick(index);
              }}
              selected={select === index ? true : false}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontWeight: "bold" }}
                primary={item.text}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>{children}</Main>
    </Box>
  );
};

export default withRouter(Sidebar);
