import React, { FunctionComponent } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Paper, Link, Toolbar } from "@mui/material";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { IndividualCreatorCardProps } from "./IndividualCardProps";
import { toAbsoluteUrl } from "@src/_metronic/_helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bullet: {
      display: "inline-block",
      width: "15px",
      height: "15px",
      marginRight: "0.25rem",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    infoText: {
      color: "#8e6bfd",
    },
    spanTextStyle: {
      textTransform: "capitalize",
    },
  })
);

const styles = (theme: any) => {
  return {
    root: {
      maxWidth: 300,
      minWidth: 300,
      padding: "0",
      Left: 0,
      height: "fit-content",
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
    },

    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      width: "90px",
      height: "90px",
      margin: "8px",
    },
    cardHeader: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      fontSize: "1.5rem",
      margin: 0,
      "& .MuiCardHeader-avatar": {
        marginRight: "0 !important",
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      flex: "1 0 auto",
      margin: theme.spacing(1),
      fontSize: "12px",
      fontWeight: "bold",
      justifyContent: "center",
      background: getColorByTheme(theme, allColors.light1, allColors.dark3),
    },
    typography: {
      fontSize: "12px",
      marginBottom: "1rem",
      fontFamily: "SF Pro Text",
      width: "92%",
      overflowWrap: "break-word",
    },
    details: {
      fontWeight: "bold",
      fontSize: "16px",
      paddingLeft: "2rem",
    },
    colorHyperLink: {
      textDecoration: "none",
      color: "inherit",
      fontWeight: 700,
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
      "&:hover": {
        color: "inherit",
      },
      "&:hover::after": {
        width: "100%",
      },
    },
    hyperLink: {
      textDecoration: "none",
      color: "#8e6bfd",
      position: "relative",
      "&::after": {
        content: "''",
        height: "1px",
        width: 0,
        background: getColorByTheme(theme, allColors.dark1, allColors.light1),
        display: "block",
        position: "absolute",
        transition: ".2s ease-in-out",
      },
    },
    logo: {
      marginLeft: theme.spacing(1),
      width: "15px",
      height: "15px",
    },
    extraContainer: {
      margin: "0 1.7rem",
    },
  };
};

const IndividualCreatorCardPresentational: FunctionComponent<
  IndividualCreatorCardProps
> = ({ cardData, contactData }) => {
  const classes = useStyles();
  const sx = styles(useTheme());
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card sx={sx.root}>
      <CardHeader
        sx={sx.cardHeader}
        avatar={
          <Avatar
            src={cardData[5].value ? cardData[5].value : ""}
            sx={sx.avatar}
          />
        }
        titleTypographyProps={{ variant: "h2" }}
        title={
          <Toolbar>
            <Link
              sx={sx.colorHyperLink}
              href={cardData[1].value}
              target="_blank"
              rel="noreferrer"
            >
              {cardData[0].value}
            </Link>
            {cardData[14].value === "Yes" ? (
              <Avatar
                src={toAbsoluteUrl("/media/svg/ubq/ubq.svg")}
                sx={sx.logo}
              />
            ) : (
              ""
            )}
          </Toolbar>
        }
      />
      <CardContent>
        <div className={classes.container}>
          <Paper sx={sx.paper}>
            <div>{cardData[2].name}</div>
            <div className={classes.infoText}>{cardData[2].value}</div>
          </Paper>
          <Paper sx={sx.paper}>
            <div>{cardData[3].name}</div>
            <div className={classes.infoText}>{cardData[3].value}</div>
          </Paper>
          <Paper sx={sx.paper}>
            <div>{cardData[4].name}</div>
            <div className={classes.infoText}>{cardData[4].value}</div>
          </Paper>
        </div>
      </CardContent>
      <CardActions>
        <Typography sx={sx.details}>Details:</Typography>
      </CardActions>
      <CardContent sx={sx.extraContainer}>
        {cardData[6].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[6].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[6].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[7].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[7].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[7].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[8].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[8].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[8].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[9].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[9].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[9].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[10].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[10].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[10].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[11].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[11].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[11].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[12].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[12].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[12].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[13].value && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[13].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[13].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[15].value !== "-" && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[15].name}: &nbsp;
              <strong className={classes.infoText}>{cardData[15].value}</strong>
            </Typography>
          </div>
        )}
        {cardData[16].value !== "-" && (
          <div style={{ display: "flex", alignItems: "alignItems" }}>
            {bull}
            <Typography sx={sx.typography}>
              {cardData[16].name}: &nbsp;
              <a
                href={`mailto:${cardData[16].value}`}
                className={classes.infoText}
              >
                {cardData[16].value}
              </a>
            </Typography>
          </div>
        )}
        {contactData?.map(
          (data: { type: string; value: string }) =>
            data.type && (
              <div style={{ display: "flex", alignItems: "alignItems" }}>
                {bull}
                <Typography sx={sx.typography}>
                  <span className={classes.spanTextStyle}>
                    {data.type}: &nbsp;
                  </span>
                  <Link
                    sx={sx.hyperLink}
                    href={data.value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.value}
                  </Link>
                </Typography>
              </div>
            )
        )}
      </CardContent>
    </Card>
  );
};

export default IndividualCreatorCardPresentational;
