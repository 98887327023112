import { gql } from "@apollo/client";
const STRIPE_URL_CONNECT = gql`
  mutation stripeOnboard($email: String!) {
    stripeOnboard(email: $email) {
      success
      status
      url
    }
  }
`;
export { STRIPE_URL_CONNECT };
