import React, { FunctionComponent } from "react";
import { Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getColorByTheme } from "@modules/utils/utils";
import { PaginationDropDownProps } from "./PaginationDropDownProp";
import allColors from "@modules/Colors/Colors";

const styles = (theme: any) => {
  return {
    paginationDropDownBox: {
      display: "flex",
      alignItems: "center",
      marginLeft: "1rem",
      fontFamily: "SF Pro Text",
      fontSize: "1rem",
      fontWeight: 700,
    },
    paginationDropDown: {
      borderRadius: 2,
      display: "flex",
      alignItems: "center",
      border: "1px solid rgba(137, 65, 255, 1)",
      background: getColorByTheme(theme, allColors.light6, allColors.dark3),
      height: 32,
      "& .atlassian-autocomplete .suggestions": {
        height: "30px",
      },
    },
    textStyle: {
      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
        fontFamily: "SF Pro Text",
        fontSize: "1rem",
        fontWeight: 700,
        minWidth: "30px",
        marginRight: "6px",
        marginLeft: "6px",
      },
      "& .MuiAutocomplete-input": {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
        },
      },
    },
  };
};

const CustomPaper = (props: any) => {
  return <Paper elevation={0} {...props} />;
};

const PaginationDropDownPresentational: FunctionComponent<
  PaginationDropDownProps
> = ({ handleChange, pageDropDown, options, onKeyDown }) => {
  const sx = styles(useTheme());
  const theme = useTheme();
  const value = pageDropDown.toString();
  const pageNumber = options.map((num) => {
    return String(num);
  });

  const [inputValue, setInputValue] = React.useState("");
  return (
    <Box sx={sx.paginationDropDownBox}>
      Go to:
      <FormControl className="ml-2">
        <Autocomplete
          disableClearable
          sx={sx.paginationDropDown}
          style={{ width: 55, height: 32 }}
          value={value}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          PaperComponent={CustomPaper}
          ListboxProps={{
            style: {
              maxHeight: "32vh",
              background: getColorByTheme(
                theme,
                allColors.light1,
                allColors.dark3
              ),
            },
          }}
          id="controllable-states-demo"
          options={pageNumber}
          noOptionsText={"Page Not Found"}
          renderInput={(params) => (
            <TextField
              variant="standard"
              type="number"
              onKeyDown={onKeyDown}
              sx={sx.textStyle}
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default PaginationDropDownPresentational;
