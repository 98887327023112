import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import allColors from "@modules/Colors/Colors";
import { useSelector } from "react-redux";

export const globalTheme = {
  typography: {
    fontFamily: ["Epilogue", "Plus Jakarta Sans", "SF Pro Text"].join(","),
    h1: {
      fontSize: "1.53rem",
      fontWeight: 700,
      letterSpacing: ".1em",
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "0.92rem",
      fontWeight: 700,
      letterSpacing: ".1em",
      textTransform: "uppercase",
    },
    subtitle1: {
      color: allColors.purple,
      fontSize: "1.53rem",
      fontWeight: 700,
      letterSpacing: "0",
    },
    subtitle2: {
      fontSize: "0.92rem",
      fontWeight: 700,
      letterSpacing: ".1em",
      textTransform: "uppercase",
      opacity: ".5",
    },
  },

  palette: {
    mode: "dark",
    primary: {
      main: allColors.dark1,
    },
    background: {
      paper: allColors.dark3,
    },
  },
  spacing: 10,
  shadows: ["none", "none"],
  props: {
    MuiPopover: {
      elevation: 1,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        transition: ".4s ease-in-out",
      },
    },
    MuiTypography: {
      root: {
        transition: ".4s ease-in-out",
      },
    },
    MuiTableBody: {
      root: {
        transition: ".4s ease-in-out",
      },
    },
    MuiTableCell: {
      root: {
        transition: ".4s ease-in-out, padding 0s",
      },
    },
    MuiTableRow: {
      root: {
        transition: ".4s ease-in-out",
      },
    },
  },
};

export const materialTheme = createTheme(globalTheme);

export function MaterialThemeProvider(props) {
  const { children } = props;
  const darkMode = useSelector((state) => state.modeReducer.darkMode);
  if (darkMode) {
    globalTheme.palette.mode = "dark";
    globalTheme.shadows = Array(25).fill("none");
    globalTheme.palette.background.paper = allColors.dark3;
  } else {
    globalTheme.palette.mode = "light";
    globalTheme.palette.background.paper = allColors.light1;
    globalTheme.shadows = Array(25).fill("none");
  }

  const theme = createTheme(globalTheme);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
