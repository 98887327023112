import { AxiosStatic } from "axios";
import { Store } from "redux";

export default function setupAxios(axios: AxiosStatic, store: Store) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err: Error) => Promise.reject(err)
  );
}
