import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import allColors from "@modules/Colors/Colors";
import RaisedHand from "@assets/RaisedHand.png";

export const StripeOnboardingSuccess = () => {
  const classes = useStyles();
  const sx = styles(useTheme());
  return (
    <div className={classes.divStyle}>
      <Container className={classes.containerStyle}>
        <Paper sx={sx.paper} elevation={1}>
          <span className={classes.spanStyle}>
            <Typography sx={sx.heading}>Success!</Typography>
            <img
              src={RaisedHand}
              alt="Raised Hand"
              width="20%"
              height="20%"
              className={classes.imgStyle}
            />
          </span>
          <p className={classes.textStyle}>
            Your Stripe account has been created and you are now
            <br /> able to accept payments from Ubiquitous.
          </p>
          <h3 className={classes.closingTextStyle}>Please Close the Window</h3>
        </Paper>
      </Container>
    </div>
  );
};

const styles = (theme: any) => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: {
        xs: "10vh",
        md: "26vh",
      },
      margin: "0 auto",
      padding: "2rem",
      width: {
        xs: "80%",
        md: "45%",
      },
      position: "relative",
      fontFamily: "Plus Jakarta Sans",
      borderRadius: { xs: "10px", md: "30px" },
    },
    heading: {
      color: allColors.dark1,
      fontStyle: "strong",
      fontSize: "32px",
      fontWeight: "550",
      textAlign: "center",
      display: "inline-block",
      lineHeight: "130%",
      textShadow: "0.5px 0 currentColor",
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  textStyle: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "150%",
    color: allColors.dark12,
  },
  divStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },
  containerStyle: { flexGrow: 1 },
  closingTextStyle: {
    color: allColors.purple,
    fontWeight: "700",
    marginTop: "7%",
    textShadow: "0.5px 0 currentColor",
  },
  spanStyle: { whiteSpace: "nowrap", maxHeight: "70px" },
  imgStyle: {
    display: "inline-block",
    marginBottom: "15%",
    marginLeft: "5px",
  },
}));
