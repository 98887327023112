import React, { FunctionComponent } from "react";
import { useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingLeft: "30px" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = (theme: any) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: "transparent",
      border: "none",
    },
    appbar: {
      paddingLeft: "30px",
      color: "red",
      background: "transparent",
    },
    tabs: {
      borderRight: "none",
      "& .MuiTab-root": {
        fontWeight: "bold",
        justifyContent: "center",
        fontSize: "16px",
        color: getColorByTheme(theme, allColors.dark1, allColors.light2),
      },
      "& .MuiTab-root.Mui-selected": {
        color: getColorByTheme(theme, allColors.dark1, allColors.light2),
      },
    },
  };
};

export interface SimpleTabsProps {
  totalTabs: number;
  tabs: Array<{
    label: string;
    component?: any;
  }>;
}
const SimpleTabs: FunctionComponent<SimpleTabsProps> = ({
  totalTabs,
  tabs,
}) => {
  const theme = useTheme();
  const sx = styles(useTheme());
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const tabColour = getColorByTheme(theme, allColors.dark1, allColors.light2);
  return (
    <Box sx={sx.root}>
      <AppBar position="static" sx={sx.appbar} elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: tabColour } }}
          aria-label="tabs"
          sx={sx.tabs}
        >
          {totalTabs > 0 &&
            tabs.map((t, i) => (
              <Tab key={t.label} label={t.label} {...a11yProps(i)} />
            ))}
        </Tabs>
      </AppBar>
      {tabs.map((t, i) => (
        <TabPanel key={t.label} value={value} index={i}>
          {t.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default SimpleTabs;
