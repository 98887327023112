import React, { useEffect, useState } from "react";
import { Box, useTheme, Paper, Checkbox } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import { Theme } from "@mui/material/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, Alert, Container } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { AutoSuggestBox } from "@modules/AutoSuggestBox/AutoSuggestBox";
import { Loaders } from "@modules/Common/Loader/Loader";
import { RootState } from "@src/redux/rootReducer";
import { useQuery as getCreatorId } from "@modules/utils/useQuery";
import GList from "./genreList";
import "./GenreSelection.scss";
import {
  GET_CREATOR_GENRE,
  UPDATE_CREATOR_DATA,
} from "@src/graphql/queries/creatorGenre";
import { ButtonPill } from "@modules/Atoms/Buttons";
import { AutoSuggestGenre } from "@modules/AutoSuggestBox/AutoSuggestGenre";
import { GenreList } from "@Models/Genre";

export const GenreSelection = () => {
  let query = getCreatorId();
  let creatorId = query.get("creatorId");
  const theme: Theme = useTheme();
  const sx = styles(useTheme());
  const [getCreatorGenre, { data: creatorGenre }] =
    useLazyQuery(GET_CREATOR_GENRE);
  const [updateGenre] = useMutation(UPDATE_CREATOR_DATA);
  const alertBackground = getColorByTheme(
    theme,
    allColors.light3,
    allColors.dark11
  );
  const alertText = getColorByTheme(theme, allColors.dark1, allColors.light1);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [genreList, setGenreList] = useState<GenreList[]>([]);
  const [creatorGenreList, setCreatorGenreList] = useState<string[]>([]);
  const [listOpen, setListOpen] = useState<string[]>([]);
  const [showMessage, setShowMessage] = useState("");

  const selectedCreator = useSelector(
    (state: RootState) => state.creators.selectedCreator
  );

  const accordionFlushBackground1 = getColorByTheme(
    theme,
    allColors.light4,
    allColors.dark2
  );
  const accordionFlushBackground2 = getColorByTheme(
    theme,
    allColors.light3,
    allColors.dark1
  );
  const Buttoncolor = getColorByTheme(
    theme,
    allColors.dark11,
    allColors.light1
  );

  useEffect(() => {
    dispatch({ type: "SELECT_CREATOR", payload: creatorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCreator && selectedCreator !== "") {
      setLoading(true);
      getCreatorGenre({
        variables: {
          creatorId: selectedCreator,
        },
      });
    } else {
      setGenreList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreator]);

  useEffect(() => {
    const creatorData = creatorGenre?.getCreatorsDetails;
    if (creatorData?.newGenreList?.length > 0) {
      const list: GenreList[] = creatorData?.newGenreList.map(
        (item: GenreList) => {
          return { id: item.id, genre: item.genre };
        }
      );
      setGenreList(list);
    } else if (creatorData?.newGenre) {
      const genres: string[] = creatorData?.newGenre;
      // find Genres in list
      const array = [...genreList];
      genres.map((genre) => {
        const result = findGenreInList(genre, GList, []);
        if (result) {
          result.parent.map((p: GenreList) => {
            !listIncludes(p, array) && array.push(p);
            return true;
          });
          !listIncludes(result, array) &&
            array.push({ id: result.id, genre: result.genre });
        }
        return true;
      });
      setGenreList(array);
    }
    if (creatorData?.creatorEnteredGenre?.length) {
      const creatorsGenre = creatorData.creatorEnteredGenre.split(",");
      setCreatorGenreList(creatorsGenre);
    }
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorGenre]);

  const handleRemove = (genre: GenreList) => {
    const toRemove = [genre];
    // remove child categories
    const removeChild = genreList.filter(
      (e) => e.id !== genre.id && isParent(genre.id, e.id)
    );
    removeChild.map((e) => toRemove.push({ id: e.id, genre: e.genre }));
    const parents = genreList.filter(
      (item) => item.id !== genre.id && isParent(item.id, genre.id)
    );

    parents
      .sort()
      .reverse()
      .map((item) => {
        const children = genreList.filter(
          (e) =>
            e.id !== item.id &&
            isParent(item.id, e.id) &&
            !listIncludes(e, toRemove)
        );
        children.length === 0 &&
          toRemove.push({ id: item.id, genre: item.genre });
        return true;
      });
    const list = genreList.filter((item) => !listIncludes(item, toRemove));
    setGenreList(list);
  };

  const handleListClick = (parents: GenreList[], item: GenreList) => {
    if (listOpen.includes(item.id)) {
      const newList = listOpen.filter((genre) => genre !== item.id);
      setListOpen(newList);
    } else {
      const idList = [...parents, item].map((genre) => genre.id);
      setListOpen(idList);
    }
  };

  const findGenreInList = (
    genre: string,
    listNode: GenreList[],
    parent: GenreList[]
  ): any => {
    let result = null;
    for (let i = 0; i < listNode.length; i++) {
      if (genre.toLowerCase() === listNode[i].genre.toLowerCase()) {
        result = {
          id: listNode[i].id,
          genre: listNode[i].genre,
          parent,
        };
        break;
      } else if (listNode[i].subGenre) {
        for (let j = 0; j < listNode[i].subGenre.length; j++) {
          result = findGenreInList(genre, listNode[i].subGenre, [
            ...parent,
            { id: listNode[i].id, genre: listNode[i].genre },
          ]);
        }
        if (result) {
          break;
        }
      }
    }
    return result;
  };

  const isParent = (parent: string, child: string) => {
    const sliceChild = child.slice(0, parent.length);
    if (sliceChild === parent) {
      return child.split(".")[0] === parent.split(".")[0] ? true : false;
    } else {
      return false;
    }
  };

  const handleSave = () => {
    setSaving(true);
    updateGenre({
      variables: {
        creatorId: selectedCreator,
        genreList,
      },
    }).then((res) => {
      setSaving(false);
      if (res?.data?.updateGenre?.success) {
        setShowMessage("Genres Updated Successfully");
      } else {
        setShowMessage("Something went wrong");
      }
      setTimeout(() => {
        setShowMessage("");
      }, 3000);
    });
  };

  const handleCheckBox = (genre: GenreList, parents: GenreList[]): void => {
    if (!listIncludes(genre, genreList)) {
      const array = [...genreList];
      parents.map((p) => {
        !listIncludes(p, array) && array.push(p);
        return true;
      });
      !listIncludes(genre, array) && array.push(genre);
      setGenreList(array);
    } else {
      const toRemove = [genre];
      // remove child categories
      const removeChild = genreList.filter(
        (e) => e.id !== genre.id && isParent(genre.id, e.id)
      );
      removeChild.map((e) => toRemove.push({ id: e.id, genre: e.genre }));
      parents.reverse().map((item) => {
        const children = genreList.filter(
          (e) =>
            e.id !== item.id &&
            isParent(item.id, e.id) &&
            !listIncludes(e, toRemove)
        );
        children.length === 0 &&
          toRemove.push({ id: item.id, genre: item.genre });
        return true;
      });
      const list = genreList.filter((item) => !listIncludes(item, toRemove));
      setGenreList(list);
    }
  };

  const listIncludes = (item: GenreList, list: GenreList[]) => {
    return list.filter((e) => e.id === item.id).length > 0;
  };

  const RenderList = ({
    list,
    level,
    parentList,
  }: {
    list: GenreList[];
    level: number;
    parentList: GenreList[];
  }) => {
    const openKey = list.filter((l) => listOpen.includes(l.id))[0]?.id;
    return (
      <Accordion
        flush
        defaultActiveKey={openKey}
        style={{
          marginLeft: `${level !== 1 && "15px"}`,
          marginRight: `${level !== 1 && "15px"}`,
          background: `${
            level % 2 === 0
              ? accordionFlushBackground1
              : accordionFlushBackground2
          }`,
        }}
      >
        {list.map((genre: GenreList) => (
          <Accordion.Item
            key={genre.id}
            eventKey={genre.id}
            style={{
              borderBottom: `1px solid ${getColorByTheme(
                theme,
                allColors.light5,
                allColors.dark4
              )}`,
            }}
          >
            <Accordion.Button
              onClick={() => handleListClick(parentList, genre)}
              className={genre.subGenre && "chevron-btn"}
              style={{ color: Buttoncolor }}
            >
              <Checkbox
                disableRipple
                sx={sx.checkBox}
                checked={listIncludes(genre, genreList)}
                onChange={(e) =>
                  handleCheckBox(
                    { id: genre.id, genre: genre.genre },
                    parentList
                  )
                }
                name="checked"
                color="primary"
              />
              {genre.genre}
            </Accordion.Button>
            {genre.subGenre?.length > 0 && listOpen.includes(genre.id) && (
              <Accordion.Body>
                <RenderList
                  list={genre.subGenre}
                  level={level + 1}
                  parentList={[
                    ...parentList,
                    {
                      id: genre.id,
                      genre: genre.genre,
                    },
                  ]}
                />
              </Accordion.Body>
            )}
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };

  return (
    <Container>
      <>
        <Box
          position="relative"
          sx={sx.bg_Colors}
          color={getColorByTheme(theme, allColors.dark1, allColors.light1)}
        >
          <Paper sx={sx.paper} elevation={1}>
            <Hidden>
              <Box sx={sx.search} display="flex" alignItems="center">
                <Box mr={2}>
                  <AutoSuggestBox creatorId={creatorId} />
                </Box>

                <Box>
                  <ButtonPill
                    disabled={saving}
                    onClick={handleSave}
                    sx={sx.save}
                  >
                    {saving ? (
                      <TailSpin color="#7026FF" height={20} width={20} />
                    ) : (
                      "Save"
                    )}
                  </ButtonPill>
                </Box>
                {showMessage !== "" && (
                  <Box width="100%">
                    <Alert
                      variant="dark"
                      style={{
                        width: "fit-content",
                        background: alertBackground,
                        color: alertText,
                        border: "none",
                      }}
                    >
                      {showMessage}
                    </Alert>
                  </Box>
                )}
              </Box>
              <Box mr={1} sx={sx.search}>
                <AutoSuggestGenre
                  selectedList={genreList}
                  updateList={setGenreList}
                />
              </Box>
            </Hidden>
            {creatorGenreList.length > 0 && (
              <>
                <label>Creator Entered Genre</label>
                <Box sx={sx.genre}>
                  {creatorGenreList.map((g) => (
                    <ButtonPill key={g} sx={sx.button1}>
                      {g}
                    </ButtonPill>
                  ))}
                </Box>
              </>
            )}
            {genreList.length > 0 && (
              <>
                <label>Selected Genre</label>
                <Box sx={sx.genre}>
                  {genreList.map((g) => (
                    <ButtonPill key={g.id} sx={sx.button}>
                      {g.genre}&nbsp;
                      <i
                        className="far fa-times-circle fa-lg close-btn"
                        onClick={() => handleRemove(g)}
                      ></i>
                    </ButtonPill>
                  ))}
                </Box>
              </>
            )}
            {loading ? (
              <div
                id="load"
                style={{ position: "absolute", top: "49%", left: "47%" }}
              >
                <Loaders />
              </div>
            ) : (
              <RenderList list={GList} level={1} parentList={[]} />
            )}
          </Paper>
        </Box>
      </>
    </Container>
  );
};

const styles = (theme: any) => {
  return {
    bg_Colors: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark2
      ),
    },
    accordion_item: {
      borderBottom: `1px solid ${getColorByTheme(
        theme,
        allColors.light5,
        allColors.dark4
      )}`,
    },
    loader: {
      position: "absolute",
      top: "49%",
      left: "47%",
    },
    search: {
      marginBottom: "10px",
    },
    paper: {
      minHeight: "500px",
      minWidth: "1000px",
      borderRadius: "2px",
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      width: "100%",
      position: "relative",
      fontFamily:
        "'Plus Jakarta Sans', 'Epilogue', Arial, Helvetica, sans-serif",
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark2
      ),
    },
    button1: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark9
      ),
      borderRadius: "100px",
      boxShadow: getColorByTheme(theme, theme.shadows[1], "none"),
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "10px 15px 6px 15px",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      margin: "5px",
    },
    button: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light1,
        allColors.dark9
      ),
      borderRadius: "100px",
      boxShadow: getColorByTheme(theme, theme.shadows[1], "none"),
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "10px 15px 6px 25px",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      margin: "5px",
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light1,
          allColors.dark4
        ),
        cursor: "default",
      },
    },
    genre: {
      marginBottom: "20px",
      padding: "15px",
      backgroundColor: getColorByTheme(
        theme,
        allColors.light3,
        allColors.dark11
      ),
      borderRadius: "5px",
    },
    checkBox: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.MuiCheckbox-colorPrimary": {
        color: getColorByTheme(theme, allColors.dark1, allColors.light1),
      },
    },
    save: {
      backgroundColor: getColorByTheme(
        theme,
        allColors.light3,
        allColors.dark11
      ),
      borderRadius: "100px",
      boxShadow: getColorByTheme(theme, theme.shadows[1], "none"),
      color: getColorByTheme(theme, allColors.dark3, allColors.light1),
      padding: "10px 25px 6px 25px",
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      margin: "5px",
      "&:hover": {
        backgroundColor: getColorByTheme(
          theme,
          allColors.light1,
          allColors.dark4
        ),
      },
    },
  };
};
