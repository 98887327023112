import React, { FunctionComponent } from "react";
import { useTheme } from "@mui/material";
import { Skeleton } from "@mui/lab";
import ChartCard from "@modules/Organisms/Charts/LineCharts/ChartCard";
import BarChart from "@modules/Organisms/Charts/BarCharts/barChart";
import { IndividualCreatorChartStyles } from "./IndividualCreatorChart.styles";
import { IndividualCreatorChartProps } from "./IndividualCreatorChart.types";
import FallBackChart from "@modules/Organisms/Charts/helper/FallBackChart";
import { formatNumber } from "@modules/utils/utils";
const IndividualCreatorChartPresentational: FunctionComponent<
  IndividualCreatorChartProps
> = ({ chartData, chartError, chartLoading, chartTotal }) => {
  const sx = IndividualCreatorChartStyles(useTheme());
  return (
    <div id="container" style={{ display: "flex" }}>
      <div className="row" style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            {chartLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={sx.skeleton}
              />
            ) : (
              <>
                {chartData && chartData.length > 0 ? (
                  <ChartCard
                    header={"Views by Month"}
                    data={chartData}
                    classname=""
                    xkey="dateId"
                    ykey="totalSponsoredViews"
                    secondYKey="totalViews"
                    xLabel="Month"
                    yLabel="Total Sponsored Views"
                    secondYLabel="Total Views"
                    subHeader={`Total Sponsored Views: ${
                      chartTotal && chartTotal.totalViewsAcross
                        ? formatNumber(chartTotal.totalViewsAcross)
                        : ""
                    }`}
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
          </div>
          <div>
            {chartLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={sx.skeleton}
              />
            ) : (
              <>
                {chartData && chartData.length > 0 ? (
                  <BarChart
                    header={"Brands by Month"}
                    data={chartData}
                    xkey="dateId"
                    ykey="totalBrands"
                    xLabel="Month"
                    yLabel="Total Brands"
                    subHeader={`Total Brands: ${
                      chartTotal && chartTotal.totalBrandsAcross
                        ? formatNumber(chartTotal.totalBrandsAcross)
                        : ""
                    }`}
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
            {chartLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={sx.skeleton}
              />
            ) : (
              <>
                {chartData && chartData.length > 0 ? (
                  <BarChart
                    header={"Posts by Month"}
                    data={chartData}
                    xkey="dateId"
                    ykey="sponsoredPosts"
                    secondYKey="totalPosts"
                    xLabel="Month"
                    yLabel="Sponsored Posts"
                    secondYLabel="Total Posts"
                    subHeader={`Total Sponsored Post: ${
                      chartTotal && chartTotal.totalPostsAcross
                        ? formatNumber(chartTotal.totalPostsAcross)
                        : ""
                    }`}
                  />
                ) : (
                  <FallBackChart classname="mr-4" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualCreatorChartPresentational;
