import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import allColors from "@modules/Colors/Colors";
import { getColorByTheme } from "@modules/utils/utils";

const StyledBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(3),
}));

const HorizontalDivision = ({ left, right }: { left: any; right: any }) => {
  const theme: Theme = useTheme();
  const sx = styles(useTheme());
  return (
    <StyledBox display="flex" alignItems="center">
      <Box sx={sx.boxStyle}>{left}</Box>
      <Box
        borderColor={getColorByTheme(
          theme,
          allColors.dark1 + "33",
          allColors.light1 + "3d"
        )}
        height={72}
        borderLeft={1}
      ></Box>
      <Box>{right}</Box>
    </StyledBox>
  );
};

export default HorizontalDivision;

const styles = (theme: any) => {
  return {
    boxStyle: {
      zIndex: "1000",
    },
  };
};
