import React, { FunctionComponent, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Container, useTheme, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import allColors from "@src/app/modules/Colors/Colors";
import { STRIPE_URL_CONNECT } from "@src/graphql/queries/stripe";
import { useMutation } from "@apollo/client";
import { ButtonPill } from "@src/app/modules/Atoms/Buttons";
import { StripeOnboardingFailedPresentational } from "./StripeOnboardingFailed.presentational";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const StripeRedirect: FunctionComponent = () => {
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  let query = useQuery();
  const email = query.get("email") as string;

  const [getStripeUrl] = useMutation(STRIPE_URL_CONNECT);

  const onClick = async () => {
    if (email !== "") {
      setLoader(true);
      await getStripeUrl({
        variables: {
          email
        },
      }).then(
        (res: any) => {
          if (res?.data?.stripeOnboard?.success) {
            setError(false);
            window.location.href = res?.data?.stripeOnboard?.url;
          } else {
            setError(true);
            setMessage("Something went wrong!");
          }
          setLoader(false);
        },
        (error: any) => {
          setLoader(false);
        }
      );
    }
  };

  const theme = useTheme();
  const classes = useStyles(theme);
  const sx = styles(useTheme());
  const continueButtonStyle = loader ? allColors.purple1 : allColors.purple;
  const failureMessage =
    message === "" ? "Sorry your Email verification has failed." : message;
  return (
    <div className={classes.divStyle}>
      <Container className={classes.containerStyle}>
        {!error && (
          <Paper sx={sx.paper}>
            <Box sx={sx.boxStyle}>
              <Typography sx={sx.headerTextStyle}>
                Let's set up your Stripe payment profile
              </Typography>
            </Box>
            <Typography sx={sx.textStyle}>
              We use Stripe to make sure you get paid on time and to
              <br /> keep your personal bank and details secure. Click the
              <br /> link below to set up your payments on Stripe.
            </Typography>
            <Box sx={sx.continueButtonBox}>
              <ButtonPill
                style={{ backgroundColor: continueButtonStyle }}
                sx={sx.continueButton}
                onClick={onClick}
                disabled={loader}
              >
                {loader === true ? (
                  <span className={classes.continueButtonText}>
                    Redirecting{" "}
                    <span style={{ textTransform: "lowercase" }}>
                      you to stripe
                    </span>
                  </span>
                ) : (
                  <span className={classes.continueButtonText}>
                    Continue{" "}
                    <span style={{ textTransform: "lowercase" }}>
                      to
                      <span className={classes.buttonTextStyle}>stripe</span>
                    </span>
                  </span>
                )}
              </ButtonPill>
            </Box>
          </Paper>
        )}
        {error && (
          <StripeOnboardingFailedPresentational
            message={failureMessage}
            id="EmailVerificationFailed"
          />
        )}
      </Container>
    </div>
  );
};

export default StripeRedirect;
const styles = (theme: any) => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: {
        xs: "20vh",
        sm: "35.7vh",
        md: "35.7vh",
      },
      margin: "0 auto",
      padding: "2rem",
      width: {
        xs: "85%",
        sm: "55%",
        md: "55%",
      },
      position: "relative",
      fontFamily: "Plus Jakarta Sans",
      borderRadius: { xs: "10px", sm: "30px", md: "30px" },
    },
    boxStyle: {
      width: {
        xs: "92%",
        md: "72%",
      },
    },
    headerTextStyle: {
      fontSize: {
        xs: "15px",
        sm: "25px",
        md: "32px",
      },
      color: allColors.dark1,
      fontWeight: "700",
      textAlign: "center",
      lineHeight: {
        xs: "20px",
        sm: "30px",
        md: "41.6px",
      },
    },
    textStyle: {
      fontSize: {
        xs: "8px",
        sm: "13px",
        md: "15px",
      },
      textAlign: "center",
      color: allColors.dark12,
      marginTop: "2%",
      fontWeight: "400",
      lineHeight: "150%",
    },
    continueButton: {
      textTransform: "capitalize",
      borderRadius: "10px",
      boxShadow: "none",
      color: allColors.dark3,
      padding: "1rem",
      fontSize: {
        xs: ".50rem",
        sm: ".92rem",
        md: ".92rem",
      },
      letterSpacing: ".1em",
      fontWeight: 700,
      margin: "5px",
      width: {
        xs: "60vw",
        sm: "40vw",
        md: "22vw",
      },
      "&:hover": {
        backgroundColor: allColors.purple1,
      },
    },
    continueButtonBox: {
      marginTop: "3%",
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonTextStyle: {
    fontFamily: "'Camphor', 'Open Sans', 'Segoe UI', 'sans-serif'",
    textRendering: "optimizeLegibility",
    marginLeft: "5px",
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
    fontSize: "18px",
    lineHeight: "22.5px",
    textShadow: "0.5px 0 currentColor",
  },
  continueButtonText: {
    fontFamily: "Plus Jakarta Sans",
    lineHeight: "22.5px",
    color: allColors.light1,
    fontSize: "15px",
    fontWeight: "700",
    textShadow: "0.5px 0 currentColor",
  },
  divStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },
  containerStyle: { flexGrow: 1 },
}));
