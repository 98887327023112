import React from "react";
import { StripeOnboardingFailedPresentational } from "./StripeOnboardingFailed.presentational";

export const StripeOnboardingFailed = () => {
  return (
    <>
      <StripeOnboardingFailedPresentational
        message="There was a problem setting up your stripe account."
        id="StripeOnboardingFailed"
      />
    </>
  );
};
