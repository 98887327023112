import React from "react";
import { Box, useTheme, Popover, Button } from "@mui/material";
import CheckboxWithTitle from "@modules/Checkboxes/CheckboxWithTitle";
import { getColorByTheme } from "@modules/utils/utils";
import allColors from "@modules/Colors/Colors";
import { ButtonPill } from "@modules/Atoms/Buttons";
import { BrandFilterValues } from "@Models/Filters";

const styles = (theme: any) => {
  return {
    clearButton: {
      color: getColorByTheme(theme, allColors.dark5, allColors.light1),
      fontSize: ".92rem",
      letterSpacing: ".1em",
      fontWeight: 700,
      padding: 0,
      marginLeft: "auto",
      minWidth: 0,
    },
    popOver: {
      "& .MuiPopover-paper": {
        borderRadius: "13px",
        color: getColorByTheme(theme, allColors.dark5, allColors.light1),
        background: getColorByTheme(theme, "auto", allColors.dark2),
        marginTop: theme.spacing(1),
        width: "400px",
      },
    },
  };
};
interface Props {
  filterValues: BrandFilterValues;
  handleChange: (obj: any) => void;
}

const BrandFilter = ({ filterValues, handleChange }: Props) => {
  const sx = styles(useTheme());
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <ButtonPill aria-describedby={id} onClick={handleClick}>
        Filter
      </ButtonPill>
      <Popover
        sx={sx.popOver}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box display="flex" flexDirection="column" p={2} pb={3}>
          <Button
            sx={sx.clearButton}
            onClick={() => {
              handleChange({
                accountNeverUpdated: false,
                accountWithoutCorrectedCategories: false,
                brandAccounts: false,
                nonBrandedAccounts: false,
                markedAsbrand: false,
              });
            }}
          >
            clear
          </Button>
          <Box mb={2}>
            <CheckboxWithTitle
              title="Only include accounts never updated"
              checked={filterValues.accountNeverUpdated}
              onChange={(value: any) => {
                handleChange({
                  ...filterValues,
                  accountNeverUpdated: value,
                });
              }}
            />
          </Box>

          <Box mb={2}>
            <CheckboxWithTitle
              title="Only include accounts without corrected categories"
              checked={filterValues.accountWithoutCorrectedCategories}
              onChange={(value: any) => {
                handleChange({
                  ...filterValues,
                  accountWithoutCorrectedCategories: value,
                });
              }}
            />
          </Box>
          <Box mb={2}>
            <CheckboxWithTitle
              title="Only include Brand accounts"
              checked={filterValues.brandAccounts}
              onChange={(value: any) => {
                handleChange({
                  ...filterValues,
                  brandAccounts: value,
                });
              }}
            />
          </Box>
          <Box mb={2}>
            <CheckboxWithTitle
              title="Only include non-Brand accounts"
              checked={filterValues.nonBrandedAccounts}
              onChange={(value: any) => {
                handleChange({
                  ...filterValues,
                  nonBrandedAccounts: value,
                });
              }}
            />
          </Box>
          <Box mb={2}>
            <CheckboxWithTitle
              title="Marked as Brand (TikTok API Non Brand)"
              checked={filterValues.markedAsbrand}
              onChange={(value: any) => {
                handleChange({
                  ...filterValues,
                  markedAsbrand: value,
                });
              }}
            />
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default BrandFilter;
